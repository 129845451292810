import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/app.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { LayoutParserService } from 'src/app/services/layout-parser.service';
import { CustomCard } from '../../models/custom-card';
import { CargoActionModalComponent } from '../ActionDialog/cargo-action-dialog-component';
// import { CreateOrderService } from 'src/app/services/create-order.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: [
    '../../../vendor/libs/ngx-sweetalert2/ngx-sweetalert2.scss',
    './card-view.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CardViewComponent implements OnInit {

  private   _data: CustomCard[] = [];
  private   _selected!: CustomCard;
  public    isIE10 = false;

  @Input()
  set data(data: CustomCard[]) {
    this._data = data;
  }
  get data(): CustomCard[] { return this._data; }

  @Input()
  set selected(data: CustomCard) {
    this._selected = data;
  }
  get selected(): CustomCard { return this._selected; }

  @Output() itemClicked: EventEmitter<any> = new EventEmitter();
  @Output() preformAction: EventEmitter<any> = new EventEmitter();

  constructor(
    private appService: AppService,
    public  connectionService: ConnectionService,
    private parserService: LayoutParserService,
    private modalService: NgbModal,
    private translate:TranslateService
  ) { 
    this.isIE10 = this.appService.isIE10;
  }

  ngOnInit(): void {
  
  
  }

  openModal(layoutData, action, paid) {
    const modalRef = this.modalService.open(CargoActionModalComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      title:"Cargo Action:" + action._attributes.name ,
      layoutData:layoutData,
      paid:paid
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);

      if (result.resultCode == "ok") {
        let form = new FormData();
        form.append("paid", result.paid);
        result.result.forEach( r => {
          form.append(r.name, r.value);
        });

        // execute action
        console.log("execute action");
        this.connectionService.cargoExcuteAction(form, paid).subscribe(
            executeData => {
              // do something
              console.log(executeData);
              var notificationList = executeData['cargoResponse'].notificationList;
              var messages = this.parserService.parseNotificationList(notificationList);
        });  
      } else {
        console.log("cancel action");
        this.connectionService.cargoCancelAction(result.paid).subscribe(
          cancelData => {
            console.log(cancelData);
            var notificationList = cancelData['cargoResponse'].notificationList;
            var messages = this.parserService.parseNotificationList(notificationList);
      });  
      }
    }, (reason) => {
      console.log(reason);
    });
  }


  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  //
  // ngx-sweetalert2
  //

  checkboxValidation(result) {
    return new Promise<void>((resolve, reject) => {
      if (result) {
        resolve();
      } else {
        reject('You need to agree with T&C');
      }
    });
  }
  


  preformCargoAction(item, action) {

    // console.log(action)
    this.connectionService.cargoPrepareAction(action._attributes.id, item.id).subscribe(
      configData => {
        console.log(configData);
        var prepareData = configData['cargoResponse']['cargoResponseBody']['actionPrepareSetup'];
        var paid = prepareData.action._attributes.paid;
        // this.currentPAID = paid;
        var notificationList = configData['cargoResponse'].notificationList;
        // console.log(notificationList);
        var messages = this.parserService.parseNotificationList(notificationList);
        if (messages.length > 0) {
          this.connectionService.cargoCancelAction(paid).subscribe(
            configData => {
         });
        } else {
          if (prepareData.userDialog) {
            var data = prepareData.userDialog.cargoActionsetinput.layout;
            var layoutData = this.parserService.parseActionLayout(data);
            // TODO parse action data
            // TODO show dialog
            this.openModal(layoutData, action, paid);
            // open('defaultModal', "{ windowClass: 'modal-lg animate'}");
            
          } else {
            console.log(paid);
            let form = new FormData();
            // execute action
            console.log("execute action");
            this.connectionService.cargoExcuteAction(form, paid).subscribe(
              executeData => {
                // do something
                var notificationList = executeData['cargoResponse'].notificationList;
                var messages = this.parserService.parseNotificationList(notificationList);
            });
          }
      }
    });
  }

  /*downloadFile(data: Response) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }
  */

  downLoadCargoFile(job:any) {

    console.log("download file");
    console.log("download file");
    console.log(job);
    var filename = job.downloadURI;
    var needle = "downloadFilename=";
    var pos = filename.indexOf(needle);
    if (pos != -1) {
      filename = filename.substring(pos + needle.length,)
    } else {
      filename = job.id + ".pdf"; 
    }
    
    this.connectionService.export(job.downloadURI).subscribe(
      data => saveAs(data, job.id + ".pdf"));
  }

  showCargoInfoDialog(job:any) {
    console.log(job);
  }

  showCargoMoreDialog(job:any) {
    console.log(job);
  }

  showCargoFile(item: any){

    window.open(item.downloadURI, "_blank");
  }

  handleUploadCargoFile(files: FileList, job:any) {

    // this.filesToUpload = files;
    console.log(files);
    console.log(job);
    // const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      let file2 = files.item(i);
      // formData.append('file', file2);
      this.connectionService.cargoAddJobFile(job, file2).subscribe(res => 
      {
        console.log(res);
        var notificationList = res['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);
      },(err) => {  
          console.log(err);
      }
      );
    }
  }

  uploadCargoFile(job:any) {
    console.log(job);
  }


  showCargoFilesDialog(item:any) {

    var layoutData = {};
    this.openFileModal(layoutData);

    /*this.connectionService.cargoConnectSubsearch(item.id).subscribe(
      subSearchData => {
        console.log(subSearchData);
        // do something
        var notificationList = subSearchData['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);
        if (messages.length == 0) {
          
        }
    });
    */

  }

  _itemClicked(data: CustomCard): void {
    if (data) {
      this.itemClicked.emit(data);
    }
  }

  openFileModal(layoutData) {
    const modalRef = this.modalService.open(CargoActionModalComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      title:this.translate.instant("files_dialog.title"),
      layoutData:layoutData,
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);

      if (result.resultCode == "ok") {
        let form = new FormData();
        form.append("paid", result.paid);

      } else {
        console.log("cancel action");
      }
    }, (reason) => {
      console.log(reason);
    });
  }
}


function defaultModal(defaultModal: any, arg1: { windowClass: string; }) {
  throw new Error('Function not implemented.');
}


import { ElementRef, Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { CargoJob } from '../models/CargoJob';
import { CustomCard } from '../models/custom-card';
import { ConnectionService } from '../services/connection.service';
import { LayoutParserService } from '../services/layout-parser.service';
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { isConstructorDeclaration } from 'typescript';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Observable, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CargoFilesModalDialogComponent } from '../subviews/cargo-files-dialog/cargo-files-dialog-component';
import { v4 as uuidv4 } from 'uuid';
import { ValueTransformer } from '@angular/compiler/src/util';
import { CargoDetailsModalDialogComponent } from '../subviews/cargo-details-dialog/cargo-details-dialog-component';
import { ViewEncapsulation } from '@angular/core';
import { CargoActionModalComponent } from '../subviews/ActionDialog/cargo-action-dialog-component';
import Swal from 'sweetalert2';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { MessageService } from '../services/message.service';
import { FormControl } from '@angular/forms';
import { DateAdapter, MatPaginator, MAT_DATE_FORMATS, MAT_DATE_LOCALE, PageEvent } from '@angular/material';
//import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { value } from 'numeral';
import { DispatchActionsButtonViewComponent } from './dispatch-actions-button-component';
import { Ng2FileDropAcceptedFile, Ng2FileDropRejectedFile } from 'ng2-file-drop';
import { runInThisContext } from 'vm';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DispatchSelectCheckboxViewComponent } from './dispatch-select-checkbox-component';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { CargoFormsModalDialogComponent } from '../subviews/CargoFormDialog/cargo-form-dialog-component';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
// import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { saveAs } from 'file-saver';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

// import { FileUploader } from 'ng2-file-upload';

/*const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;
const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;
const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;
*/


/*const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
  'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
  'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
  'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
  'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
  'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
*/

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};


@Component({
  selector: 'dispatch-app-home',
  templateUrl: './dispatch-home.component.html',
  styleUrls: [
    './dispatch-file-upload.scss',
    './dispatch-home.component.css',
    './dispatch-floating_labels.css',
    '../../vendor/styles/pages/contacts.scss',
    '../../../node_modules/@swimlane/ngx-datatable/index.scss',
    '../../vendor/libs/ngx-toastr/ngx-toastr.scss',
    '../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css'],
})

/*
'../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss',
    '../../../vendor/libs/ngb-timepicker/ngb-timepicker.scss',
    '../../../vendor/libs/ngx-color-picker/ngx-color-picker.scss'
*/


export class DispatchHomeComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild(MatPaginator, { static: true }) paginatorTop: MatPaginator;
  // @ViewChild(MatPaginator, { static: true }) paginatorBottom: MatPaginator;
  @ViewChild('uploadFileButton', { static: true }) uploadFileButton: ElementRef;
  @ViewChild('uploadFileControl', { static: true }) uploadFileControl: ElementRef;
  @ViewChild('uploadFileControl1', { static: true }) uploadFileControl1: ElementRef;
  @ViewChild('uploadFileControl2', { static: true }) uploadFileControl2: ElementRef;
  @ViewChild('uploadFileControl2Form', { static: true }) uploadFileControl2Form: ElementRef;

  // public sanitizer: DomSanitizer

  isLoadingData: boolean = false;
  cargoJobList: any[] = [];
  cargoTableList: any = [];
  cargoTableListSource: LocalDataSource;

  activeJob: CustomCard;

  pageSize = 100;
  pageIndex = 0;
  numbersPerPage: number = 100;

  // pageSizeOptionsLine: number[] = [10, 20, 30, 40 ,50, 100];
  pageSizeOptionsGrid: number[] = [9, 15, 30, 50, 100, 200];
  paginatorOfString: string = "";
  displayCargoJobList: any[] = [];
  pageEvent: PageEvent;


  clickedUploadJob: any[] = [];
  detailControlList: any[] = [];
  // resultControlList:any[] = [];
  searchControlList: any[] = [];
  columnList: any[];

  selectedListJob:any = null;

  noDataFoundMessage: string = "";

  message: string = "";
  isRTL: boolean;
  uploader = new FileUploader({});
  multiUploader = new FileUploader({});
  multiUploaderForm = new FileUploader({});

  imageFile = undefined;
  originalFileData = undefined;

  loadingIndicator = true;
  rows = [];
  temp = [];
  selected = [];

  selectAll: boolean = false;
  result: string = "";

  grabber = false;
  name = 'Angular 4';
  height = 150;
  width=400;

  y = 100;
  oldY = 0;
  oldX = 400;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (! this.grabber) {
        return;
    }
    this.resizerY(event.clientY - this.oldY);
    this.resizerX(event.clientX - this.oldX);
    this.oldY = event.clientY;
    this.oldX = event.clientX;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false;
  }

  resizerY(offsetY: number) {
    this.height += offsetY;
  }
  resizerX(offsetX: number) {
    this.width += offsetX;
  }


  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.clientX > this.width) {
      this.grabber = true;
      this.oldY = event.clientY;
      this.oldX = event.clientX;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  /*
  filter: {
      type: 'list',
      config: {
      selectText: 'Select...',
      list: [
        { value: 'male', title: 'Male' },
        { value: 'female', title: 'Female' },
      ]
          }
        },
        filterFunction(value, filter) {
          return filter === value;
        }
         selectMode: 'multi',
  */
  /*
  */
  newSettings: any;
  encryptSecretKey = "SuperSecretEncryptionKey";

  settings = {
    noDataMessage: this.noDataFoundMessage,
    filter: false,
    edit: null,
    actions: null,
    add: null,
    columns: {},
    pager: {
      display: true,
      perPage: this.numbersPerPage,
    },
    attr: {
      class: 'table table-bordered'
    },
    rowClassFunction: (row) => {
      if (row.data.select) {
        return 'ng2-file-upload-file-over';
      } else {
        return ''
      }
    }
  };


  /*
  template = '<div (click)="context.handleTextClick()" class="aaaa">' + 
  '<div id="test" class="sortorder text-muted small">Click Test</div>caroline.landin@oenoforos.se</div>'
  testValue:string = "AAAAAAZ";
  */
  dropdownSettings: any;


  public userPreferences = {
    savedCargoSearchList: [],
    sortOrder: {
      column: 0,
      decending: false,
      currentSortOrder: "",
      icon: "fa fa-chevron-circle-up"
    },
    viewMode: "col"
  }

  // hasBaseDropZoneOver = false;
  isUploadingFile: boolean = false;

  showEditSearchView: boolean = false;
  selectedSavedSearch: any = null;
  searchNameToEdit: string = "";

  resultData: any;
  anchors: any[];
  currentJob: CargoJob;
  currentJobId: number = -1;
  toppings = new FormControl();

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  images: GalleryItem[] = [];
  Cimages: any[] = [];

  enableFileUpload:boolean = true
  fileUploadFormats:string = "*";
  enableCargoFormUploads:boolean = true;
  formUploadFormats:string = "*";
  enableFileDownload:boolean = true;
  
  testHtml = ''; // <button style="color:blue" onclick="Window.MyComponent.test()">testin</button>';

  constructor(
    public sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>,
    private appService: AppService,
    private connectionService: ConnectionService,
    private router: Router,
    private calendar: NgbCalendar,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private service: MessageService,
    private parserService: LayoutParserService) 
  {

    Window['MyComponent'] = this;

    this.isRTL = appService.isRTL;
    this.appService.pageTitle = 'Home';
    this.dateAdapter.setLocale('sv-SE');
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: this.translate.instant('main.select_all'),
      unSelectAllText: this.translate.instant('main.deselect_all'),
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    this.noDataFoundMessage = this.translate.instant("main.no_data_message");
    this.pageTitle();
    // this.paginatorOfString = this.translate.instant("main.paginator.index_str")

    this.multiUploader.onAfterAddingFile = (fileItem: FileItem) => {

      var jobIdList = "";
      var jobIdListNew = "";
      this.displayCargoJobList.forEach(job => {
        if (job.select) {
          if (jobIdList != "")
            jobIdList = jobIdList + ",";
          jobIdList = jobIdList + job.id;
          job.isUploadingFile = true;
          jobIdListNew = jobIdListNew + "&jobId=" + job.id;
        }
      });

      this.connectionService.cargoMultiAddJobFile(jobIdList, fileItem._file).subscribe(res => {

        this.displayCargoJobList.forEach(j => {
          j.isUploadingFile = false;
        });

        this.isLoadingData = false;
        var fileUploadOk = this.translate.instant("main.file_multijob_upload_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {
          this.doCargoSearch();
          Swal.fire(
            'Cargo',
            fileUploadOk
          ).then((result) => {
            if (this.uploadFileControl1)
              this.uploadFileControl1.nativeElement.value = '';
            if (this.uploadFileControl2)
              this.uploadFileControl2.nativeElement.value = '';
          })
        }
      });
    };

    this.multiUploaderForm.onAfterAddingFile = (fileItem: FileItem) => {

      var jobIdList = "";
      var jobIdListNew = "";
      var firstJob = null;
      var result = false;
      this.displayCargoJobList.forEach(job => {
        if (job.select) {
          if (job.openFormButton)
            result = true;

          if (jobIdList != "")
            jobIdList = jobIdList + ",";
          jobIdList = jobIdList + job.id;
          job.isUploadingFile = true;
          jobIdListNew = jobIdListNew + "&jobId=" + job.id;
          firstJob = job;
        }
      });

      if (result) {
        console.log(firstJob);
        this.showCargoFormsDialog(firstJob);
      } else {
        this.displayCargoJobList.forEach(job => {
          if (job.select) {
            job.isUploadingFile = false;
          }
        });
      }

      /*this.connectionService.cargoMultiAddJobFile(jobIdListNew, fileItem._file).subscribe(res => {
        
        this.displayCargoJobList.forEach(j => {
          j.isUploadingFile = false;
        });

        this.isLoadingData = false;
        var fileUploadOk = this.translate.instant("main.file_multijob_upload_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {
          this.doCargoSearch();
          Swal.fire(
            'Cargo',
            fileUploadOk
            ).then((result) => {

              this.uploadFileControl1.nativeElement.value = ''; 
              this.uploadFileControl2.nativeElement.value = ''; 
            }) 
        }
      });
      */
    };



    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {

      var fileReader: FileReader = new FileReader();
      fileReader.onloadend = (e) => {
        this.imageFile = fileReader.result;
        this.originalFileData = fileReader.result;
      }
      fileReader.readAsDataURL(fileItem._file);
      var job = this.currentJob;
      // this.currentJobId = job.jobId;
      job.isUploadingFile = true;

      console.log(this.currentJob);
      console.log(fileItem);
      console.log(job);

      this.isUploadingFile = true;
      this.connectionService.cargoAddJobFile(job, fileItem._file).subscribe(res => {
        // console.log(res);
        job.isUploadingFile = false;
        this.currentJobId = -1;
        this.doCargoSearchSingle(job['id']);

        var notificationList = res['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);
        var fileUploadOk = this.translate.instant("main.file_upload_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {
          Swal.fire(
            'Cargo',
            fileUploadOk
          ).then((result) => {
            // this.doCargoSearchSingle(job['id']);
          })
        }
      }, (err) => {
        this.isUploadingFile = false;
        console.log(err);
      }
      );
    };

  }

  // public model: any;
  public states: string[] = [];
  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  sendMessage(): void {
    // send message to subscribers via observable subject
    this.service.sendMessage('Message from app Component to message Component!');
  }


  openCargoFilesDialog(id) {

    console.log(id);
    var job = this.cargoJobList.find(f => f.id == id);
    console.log(job);
    if (job) {
      // job.isUploadingFile = true;
      this.showCargoFilesDialog(job);
    }
  }


  clearMessage(): void {
    this.service.clearMessage();
  }


  replaceContext(data: string): string {

    return data.replace("context.", "");
  }

  test() {
    console.log("testing");
  }

  pageTitle() {

    // var title = this.translate.instant("main.title")
    // this.titleService.setTitle(title); // `${value} - Angular Starter`);
  }


  toggleView() {
    /*console.log(this.viewMode);
    if (this.viewMode == "col") {
      this.pageIndex = 0;
      this.pageSize = 10;
      this.pageSizeOptionsGrid = [10, 20, 30, 40 ,50, 100];
    } else {
      this.pageIndex = 0;
      this.pageSize = 9;
      this.pageSizeOptionsGrid = [9, 18, 27, 36 , 45, 90];
    }
    this.displayCargoJobList = this.cargoJobList.slice(this.pageIndex * this.pageSize, 
      (this.pageIndex + 1) * this.pageSize);
      */
  }

  public screenWidth: any;
  public screenHeight: any;

  ngOnInit() {
    // console.log("---------- INIT ----------------------")
    this.getConfiguration();
    this.pageTitle();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    /*this.result = this.result + "<div (click)=\"context.handleTextClick('YffffffY')\" class=\"\">DIV"; 
    var sortstr = '<span (click)="context.toggleSortDirection()" class="mr-1 ion-ios-arrow-dropdown">Toggle GGGGGGG';
    sortstr = sortstr + '</span>';
    sortstr = sortstr + '<span (click)="context.toggleSortDirection()" class="mr-1 ion-ios-arrow-dropup">TOGGLE KKKKKK</span>';
    this.result = this.result + sortstr; // "<div id=\"test\" class=\"sortorder text-muted small\">VfffffV</div>";
    console.log(this.result);
    console.log(this.paginatorTop);
    */
    this.enableFileUpload = environment.enableFileUpload == "true";
    this.fileUploadFormats = environment.fileUploadFormats;
    this.enableCargoFormUploads = environment.enableCargoFormUploads == "true";
    this.formUploadFormats = environment.fileUploadFormats;
    this.enableFileDownload = environment.enableFileDownload == "true";

    this.paginatorTop._intl.itemsPerPageLabel = ""; // this.translate.instant("main.paginator.items_per_page");
    this.paginatorTop._intl.firstPageLabel = ""; this.translate.instant("main.paginator.firstPageLabel");
    this.paginatorTop._intl.lastPageLabel = this.translate.instant("main.paginator.lastPageLabel");
    this.paginatorTop._intl.nextPageLabel = this.translate.instant("main.paginator.nextPageLabel");
    this.paginatorTop._intl.previousPageLabel = this.translate.instant("main.paginator.previousPageLabel");
    // this.paginatorBottom._intl.itemsPerPageLabel = this.translate.instant("main.paginator.items_per_page");
    this.paginatorTop._intl.getRangeLabel = function (page, pageSize, len): string {
      var last = (page + 1) * pageSize;
      if (last > len)
        last = len;
      //
      return ((page * pageSize) + 1).toString() + " - " + last + " av " + len.toString(); // "page av page";
    }
    /*this.paginatorBottom._intl.getRangeLabel = function(page, pageSize, len):string {
      return (pageSize*page).toString() + " av " + len.toString(); // "page av page";
    }
    */
    // var card = new CargoJob();
    // this.cargoJobList.push(card);
    // this.applyQueryParameters();
    const id = this.route.snapshot.paramMap.get('id');
    // console.log("get config");
    this.route.queryParams.subscribe((params) => {
      // console.log(params);
      this.cargoGetUserProperies(params.id);
      // this.loadChartsforView(params.id);
    });
    if (id) {
      // console.log(id);
      this.cargoGetUserProperies(id);
    }

    this.galleryOptions = [
      {
        width: '100%',
        height: '200px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 200,
        width: '100%',
        height: '200px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      }/*,
      // max-width 400
      {
          breakpoint: 400,
          preview: false
      }*/
    ];
    /*var enc = this.encryptData("This is my Data");
    console.log(enc);
    console.log(this.decryptData(enc))
    */

    document.addEventListener("wheel", function(e) {
      // e.preventDefault(); // does nothing since the listener is passive
    }, {
      passive: true
    });

    // this.bindingFunction();
  }


  /*applyQueryParameters() {

    console.log("applyQueryParameters")
    var savedSearch = {
      cargoSearch:{}
    };
    const obj = this.activatedRoute.snapshot.queryParams;
    console.log(obj);
    if (Object.keys(obj).length > 0) {
      Object.keys(obj).forEach(key => {
        // var value = obj[key];
        savedSearch.cargoSearch[key] = obj[key];
        console.log(key + "=>" + obj[key]);
      });
      this.applySavedSearch(savedSearch, true);
    }
  }
  */

  applyQueryParameters2() {

    try {
      var str = localStorage.getItem("DirectLinkSerchParams");
      if (str.length > 0) {
        var queryParam = JSON.parse(str);
        // console.log(queryParam);
        // console.log("-------------------- get direct link parameters(" + str + ")");
        // console.log("applyQueryParameters2");
        var savedSearch = {
          cargoSearch: queryParam
        };
        // console.log("--- apply saved search 1");
        // console.log(savedSearch);

        setTimeout(() => {
          console.log("------------------------ searchOrders 6")
          this.applySavedSearch(savedSearch, true);
          localStorage.setItem("DirectLinkSerchParams", "");
        }, 1500);
        //console.log("-------------------- clear direct link parameters: " + str);

      }
    } catch (err) {

    }
  }


  changePaginationMethod() {

    // console.log(this.numbersPerPage);
    // var numberOfPages = this.numbersPerPage;
    /*var pager = {
        display: true,
        perPage: numberOfPages
    }
    */
    // this.newSettings.pager = pager;
    this.cargoTableListSource.setPaging(1, this.numbersPerPage, true);
    this.newSettings = Object.assign({}, this.settings);
    // this.cargoTableListSource.setPaging(0, numberOfPages, true)
    // this.cargoTableListSource.refresh();
    console.log(this.newSettings);
  }

  getPaginatorData($event) {

    console.log($event);
    this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex
    this.pageEvent = $event;

    this.numbersPerPage = $event.pageSize;
    if (this.cargoJobList.length > 0) {
      this.cargoTableListSource.setPaging(this.pageIndex + 1, $event.pageSize, true);
    }
    this.newSettings = Object.assign({}, this.settings);
    if (this.cargoJobList.length > 0) {
      this.displayCargoJobList = this.cargoJobList.slice(this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize);
      }
    // console.log($event.pageIndex);
    // console.log(this.displayCargoJobList);
    // console.log(event);
  }

  public handleAnchorClick = (event: Event) => {
    // Prevent opening anchors the default way
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    alert(`You are trying to navigate to ${anchor.href}`);
  }


  navigateToChart(chart) {
    this.router.navigate(['displaychart'], { queryParams: { id: chart.chart_id } });
  }

  /*bindingFunction(){
    document.getElementById('test').onclick = function() {
      console.log("resort the list");
  }
  }
  */

  viewMode = 'col';

  private dragFileOverStart() {
    console.log("dragFileOverStart");
  }

  // File being dragged has moved out of the drop region
  private dragFileOverEnd() {
    console.log("dragFileOverEnd");
  }

  // File being dragged has been dropped and is valid
  private dragFileAccepted(acceptedFile: Ng2FileDropAcceptedFile) {
    console.log(acceptedFile);
  }


  // File being dragged has been dropped and has been rejected
  private dragFileRejected(rejectedFile: Ng2FileDropRejectedFile) {
    console.log("dragFileRejected");
  }

  // Files being dragged have been dropped.
  // private dragFilesDropped(droppedFile: Ng2FileDropFilesDropped) {
  // }

  fileOver(e: any, job: any): void {

    // this.hasBaseDropZoneOver = e;
    if (e == true) {
      this.currentJob = job;
      this.currentJobId = job.id;
      // console.log(" 1set currentJobId: " + this.currentJobId)
    } else {
      this.currentJob = null;
      this.currentJobId = -1;
      // console.log("2 set currentJobId: " + this.currentJobId)
    }
    // console.log(job);
    // console.log(e);
  }

  changeSortOrder(column) {

    this.userPreferences.sortOrder.currentSortOrder = column.ngValue;

    console.log(this.userPreferences.sortOrder.currentSortOrder);

    this.resortCargoJobList();
  }


  toggleSortDirection() {

    this.userPreferences.sortOrder.decending = !this.userPreferences.sortOrder.decending;
    this.reSortList();
  }


  sendMailToCustomer(job) {

    console.log(job);
    var addresses = "";

    job.dataList.forEach(d => {
      if (d && d.indexOf("@") >= 0) {
        if (addresses.length > 0)
          addresses = addresses + ",";
        addresses = addresses + d;
      }
    });
    if (addresses) {
      var mail = 'mailto:' + addresses + '?subject=' + '&body=';
      window.open(mail);
    } else {
      var fileUploadOk = this.translate.instant("main.no_email_address_error");
      Swal.fire('Cargo', fileUploadOk);
    }
  }


  resortCargoJobList() {

    // console.log("resort cargo job list");
    var count = 0;
    this.resultData.headerList.forEach(column => {
      if (column == this.userPreferences.sortOrder.currentSortOrder) {
        this.userPreferences.sortOrder.column = count;
        // console.log("column to sort by:" + count)
      }
      count = count + 1;
    });
    this.cargoSetUserProperies();
    // console.log(this.userPreferences.sortOrder);
    this.reSortList();

  }

  handleTextClick(compName) {

    this.userPreferences.sortOrder.currentSortOrder = compName;
    // console.log("klick in text:" + compName);
    this.resortCargoJobList();
  }


  sortCargoList(a: any, b: any, index: number, decending: boolean): number {

    // console.log("here");
    var res = 0;
    /*console.log(this.userPreferences);
    console.log(a);
    console.log(b);
    */

    // var index = this.userPreferences.sortOrder.column;
    var v1 = a.dataList[index];
    var v2 = b.dataList[index];

    if (v1 && v2) {
      v1.toLowerCase();
      v2.toLowerCase();
      if (isNaN(v1)) {
        // console.log($scope.sort.column + " " + a + " - " + b);
        // console.log(a);

        if (decending == true) {
          if (v1 < v2)
            res = -1;
          else if (v1 > v2)
            res = 1;
          else
            res = 0;
        } else {
          if (v1 < v2)
            res = 1;
          else if (v1 > v2)
            res = -1;
          else
            res = 0;
        }
      } else {
        v1 = parseInt(v1);
        v2 = parseInt(v2);

        if (decending == true) {
          if (v1 < v2)
            res = -1;
          else if (v1 > v2)
            res = 1;
          else
            res = 0;
        } else {

        }
      }
    }
    return res;
  }


  reSortList() {
    // console.log("resort list");
    this.cargoJobList.sort((a, b) => {
      // bconsole.log(a);
      // console.log(b);
      var res = 0;
      var index = this.userPreferences.sortOrder.column;
      var decending = this.userPreferences.sortOrder.decending;
      var v1 = a.dataList[index];
      var v2 = b.dataList[index];

      // console.log(index + " " + v1 + " " + v2);

      if (v1 == null)
        v1 = "";
      if (v2 == null)
        v2 = "";

      if (v1 && v2) {
        v1.toLowerCase();
        v2.toLowerCase();
        if (isNaN(v1)) {
          // console.log("a " + v1)                    
          if (decending == true) {
            if (v1 < v2)
              res = -1;
            else if (v1 > v2)
              res = 1;
            else
              res = 0;
          } else {
            if (v1 < v2)
              res = 1;
            else if (v1 > v2)
              res = -1;
            else
              res = 0;
          }
        } else {
          v1 = parseInt(v1);
          v2 = parseInt(v2);
          // console.log("b " + v1)
          if (decending == true) {
            if (v1 < v2)
              res = -1;
            else if (v1 > v2)
              res = 1;
            else
              res = 0;
          } else {

          }
        }
      } else {
        // console.log(a)
        // console.log(b)
      }
      return res;
    });
    this.displayCargoJobList = this.cargoJobList.slice(this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize);

    console.log(this.displayCargoJobList);
  }



  getSuggestionValues($event, control) {

    var textStart = $event;
    if (textStart.length >= 3) {
      // console.log($event);
      // console.log(control);
      if (control.suggestionMetaDataID) {
        // console.log($event);
        var metaId = control.suggestionMetaDataID;
        this.connectionService.cargoGetTypeaheadValues(metaId, textStart).subscribe(
          configData => {
            this.states = configData;
            // console.log(this.states);
          });
      }
    } else {
      control.suggestionList = [];
    }
  }


  findControlForKey(key: string): any {

    var res = null;
    // console.log(this.searchControlList);
    this.searchControlList.forEach(c => {
      if (c.name == key) {
        res = c;
      }
    })
    return res;
  }

  findOption(control: any, valueToFind: string) {

    control.dropdownList.forEach(o => {
      // console.log("test: " + o.id + " " + valueToFind);
      // console.log(o);
      if (o.id == valueToFind) {
        control.selectedItems.push(o);
        // console.log("found item")
      }
    });
  }


  applySavedSearch(savedSearchIn: any, doSeach: boolean) {

    var savedSearch = null;
    if (savedSearchIn == null) {
      savedSearch = this.selectedSavedSearch;
    } else {
      savedSearch = savedSearchIn;
    }
    this.searchNameToEdit = savedSearch.name;
    this.doClearFields(true);

    // console.log(savedSearchIn);
    Object.keys(savedSearch.cargoSearch).forEach(key => {

      console.log(key);
      var control = this.findControlForKey(key);
      var value = savedSearch.cargoSearch[key];
      // console.log(control);

      if (control != null) {
        // console.log(control);
        if (control.type == "dateInput") {
          // console.log(control);
          control.model = this.parserService.parseDate2(value);
        } else {
          console.log(key + "=>" + value);
          control.ngValue = value;
        }
        if (control.type == "multiSelect") {
          control.selectedItems = [];
          var selectedItems = value.split("|");
          selectedItems.forEach(valueToFind => {
            // console.log("find value:" + valueToFind);
            this.findOption(control, valueToFind);
          })
          // console.log(control);
        }
      } else {
        console.log("control not found");

      }
    });
    if (doSeach) {
      this.doCargoSearch();
    }

  }

  cargoSetUserProperies() {

    this.userPreferences.viewMode = this.viewMode;
    this.connectionService.cargoSetUserProperies("Preferences", JSON.stringify(this.userPreferences)).subscribe(
      configData => {
        // console.log(configData);
        // console.log("send message");
        this.sendMessage();
      });
  }

  cargoGetUserProperies(id: string) {

    this.isLoadingData = true;
    this.connectionService.cargoGetUserProperies("Preferences").subscribe(
      userPropertiesReply => {
        // console.log(userPropertiesReply);

        if (userPropertiesReply != null) {
          this.isLoadingData = false;
          // var propertiesStr = userPropertiesReply['cargoResponse']['cargoResponseBody']['propertyList']['property'];
          this.userPreferences = userPropertiesReply; // JSON.parse(propertiesStr);

          if (this.userPreferences.viewMode)
            this.viewMode = this.userPreferences.viewMode;

          if (this.userPreferences.savedCargoSearchList) {
            this.userPreferences.savedCargoSearchList.forEach(s => {
              if (s.search_uid == id) {
                // console.log(s);
                // this.applySavedSearch(s, true);
              }
            });
          }
        }
        /*if (this.userPreferences.sortOrder) {
          this.userPreferences.sortOrder = this.userPreferences.sortOrder;
        }
        */
        // console.log(this.userPreferences);
      }
    );
  }




  doCargoSearchMoreResults(srid: string, fetchedJobs: number, totalJobCount: number) {

    var count = fetchedJobs;

    do {
      let form = new FormData();
      form.append("srid", srid);
      form.append("offset", fetchedJobs.toString());
      // console.log(totalJobCount + " search from offset:" + fetchedJobs);
      // console.log("search from offset:" + totalJobCount);
      // console.log("SRID:" + srid);
      this.isLoadingData = true;

      this.connectionService.cargoWebViewSearchMoreResults(form).subscribe(
        searchReply => {
          // console.log(searchReply);
          var searchResult = searchReply['cargoResponse']['cargoResponseBody']['webViewSearchResult'];
          searchResult.job.forEach(job => {
            var jobData = this.parserService.parseCargoJobNew(job, false);
            jobData.select = false;
            jobData.showFileRows = false;

            var previewHtml = `<div style="overflow:auto">
                <img width="90px" src="${jobData.thumbnailURI}"
                style="max-width:100px; max-height:80px; width:90px; height:auto;">
                </div>`;
            var elem = {
              preview: previewHtml,
              job: jobData,
              parent: this,
              select: false
            };
            jobData.jobInfoData.forEach(val => {
              elem[val.title] = val.value;
            })
            this.cargoTableList.push(elem);
            this.cargoJobList.push(jobData);
          });

          if (this.cargoJobList.length == 1)
            this.message = this.cargoJobList.length.toString() + " " + this.translate.instant("main.number_of_hits_one");

          if (this.cargoJobList.length > 1)
            this.message = this.cargoJobList.length.toString() + " " + this.translate.instant("main.number_of_hits_many");

          if (count >= totalJobCount) {
            this.isLoadingData = false;
            this.displayCargoJobList = this.cargoJobList.slice(this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize);
          }
        }
      ), error => {
        console.log("error occured");
        this.isLoadingData = false;
      };
      this.cargoTableListSource = new LocalDataSource(this.cargoTableList);
      count = count + fetchedJobs;
      // console.log(count + " jobs of total:" + totalJobCount);
    } while (count < totalJobCount);
  }


  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  getCargoSearchValues(): any {

    var result = {
      error: "",
      cargoSearch: {},
      form: new FormData()
    }

    this.searchControlList.forEach(control => {

      if (control.type == "checkbox") {
        // console.log(control);
        if (control.ngValue) {
          result.form.append(control.name, control.valueIfSelected);
          result.cargoSearch[control.name] = control.valueIfSelected;
        } else {
          result.form.append(control.name, control.valueIfNotSelected);
          result.cargoSearch[control.name] = control.valueIfNotSelected;
        }
      } else if (control.type == "dateInput") {
        // console.log(control);

        if (typeof control.model == 'string') {
          var test = control.model;

          console.log("we have a string")
          if (test.length < 10) {
            var res = this.connectionService.replaceSimpleMacros(test, "");
            console.log("we have a string:" + res);
            result.form.append(control.name, res);

            result.cargoSearch[control.name] = test;
          } else {
            result.form.append(control.name, test);
            result.cargoSearch[control.name] = test;
          }
        } else {
          if (control.model != null) {
            // console.log(control.model.getMonth());
            // console.log(control.model.year.toString());
            console.log(control);
            console.log(typeof control.model);
            if (control.model.year && control.model.year.toString() == "NaN") {

            } else {
              if (control.model == "") {

              } else {
                if (control.model.getFullYear().toString() == "NaN") {

                } else {
                  var datestr = "" + control.model.getFullYear() + "-";
                  if (control.model.getMonth() + 1 < 10) {
                    datestr = datestr + "0";
                  }
                  datestr = datestr + (control.model.getMonth() + 1);

                  datestr = datestr + "-";
                  if (control.model.getDate() < 10) {
                    datestr = datestr + "0";
                  }
                  datestr = datestr + control.model.getDate()
                  // console.log(datestr);

                  result.form.append(control.name, datestr);
                  result.cargoSearch[control.name] = datestr;
                }
              }
            }
          }
        }
        // console.log(control.name + " => " + datestr);
      } else if (control.type == "select") {
        console.log(control);

        if (!this.isEmptyObject(control.ngValue)) {
          // console.log(control);
          if (control.ngValue != '') {
            result.form.append(control.name, control.ngValue);
            result.cargoSearch[control.name] = control.ngValue;
          }
        }
      } else if (control.type == "multiSelect") {
        var values = "";
        // console.log(this.toppings);
        // console.log(control);

        // console.log(control);
        if (control.selectedItems == "") {

        } else {
          control.selectedItems.forEach(val => {
            if (values.length > 0) {
              values = values + "|";
            }
            values = values + val.id;
            // console.log(control.name + " => " + datestr);
          })
          result.form.append(control.name, values);
          result.cargoSearch[control.name] = values;
        }
        // console.log(control);
      } else if (control.type == "radioGroup") {
        result.form.append(control.group, control.ngValue);
        result.cargoSearch[control.group] = control.ngValue;
        // console.log(control.name + " => " + control.ngValue);

      } else if (control.ngValue != "") {
        result.form.append(control.name, control.ngValue);
        result.cargoSearch[control.name] = control.ngValue;

        // console.log(control.name + " => " + control.ngValue);
        // console.log("append data:" + control.name + " data:" + control.ngValue);
      }
      if (control.requiredField && control.requiredField == "true" && control.ngValue == "") {
        // console.log(control);
        var message = this.translate.instant("main.error.field_must_contain_value");
        message = message + control.placeholder;
        Swal.fire('Cargo', message);
        result.error = message;
      }
    });
    return result;
  }


  getCargoDetails(job: any) {

    var form = new FormData();
    form.append("fileID", "-1");
    form.append("jobID", job.id);
    this.connectionService.cargoWebViewSearch(form).subscribe(
      searchReply => {
        console.log(searchReply);

      });
  }

  doCargoSearchSingle(id: string) {

    this.isLoadingData = true;
    let form = new FormData();
    form.append("jobID", id);

    console.log("do single search");
    this.connectionService.cargoWebViewSearch(form).subscribe(
      searchReply => {
        console.log(searchResult);

        this.isLoadingData = false;
        var searchResult = searchReply['cargoResponse']['cargoResponseBody']['webViewSearchResult'];

        if (!Array.isArray(searchResult.job))
          searchResult.job = [searchResult.job];

        searchResult.job.forEach(job => {
          var jobData = this.parserService.parseCargoJobNew(job, false);
          console.log(jobData);
          let itemIndex = this.displayCargoJobList.findIndex(item => item.id == id);
          console.log(itemIndex);
          this.displayCargoJobList[itemIndex] = jobData;
        });
      }, error => {
        this.isLoadingData = false;
      }
    );
  }


  doesJobHavePUID(): boolean {

    var result = false;
    this.displayCargoJobList.forEach(job => {
      if (job.select) {
        if (job.openFormButton)
          result = true;
      }
    });
    return result;
  }


  handleOpenFormUpload() {

    var cc = null;
    this.displayCargoJobList.forEach(job => {
      if (job.select) {
        if (job.openFormButton)
          cc = job;
      }
    });
    if (cc != null) {
      this.openFormsModal(cc, []);
    }
  }

  /*doOpenFormButton(control:any) {

    const modalRef = this.modalService.open(CargoActionModalComponent,
      {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });

      var userUUID = "";
      var jobIdListNew = "";
      this.displayCargoJobList.forEach( job => {
        if (job.select) {
          job.isUploadingFile = true;
          jobIdListNew = jobIdListNew + "&jobId=" + job.id;
          console.log(job);
        }
      });
      

      var url = this.connectionService.url + "cargoServlet=openProfile";
      url = url + "&UUID=" + this.connectionService.userUuid;
      url = url + "&PAID=" + control.PAID;
      url = url + "&jobIDList=" + jobIdListNew;
      let data = {
        iFrameUrl:url,
        title:"Cargo Preflight",
      }
      
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
        console.log(result);

      }, (reason) => {
        console.log(reason);
      });
  }*/



  doCargoSearch() {
    // console.log("do cargo search");  

    // let form = new FormData();
    var search = this.getCargoSearchValues();
    console.log(search);
    if (search.error != "")
      return;

    this.isLoadingData = true;
    this.message = "";
    this.cargoJobList = [];
    this.cargoTableList = [];
    this.displayCargoJobList = [];

    console.log("start search");
    this.connectionService.cargoWebViewSearch(search.form).subscribe(
      searchReply => {
        console.log("got reply");
        console.log(searchReply);
        this.isLoadingData = false;
        var searchResult = searchReply['cargoResponse']['cargoResponseBody']['webViewSearchResult'];
        console.log(searchResult);

        if (searchResult) {
          if (searchResult['_attributes'].jobCount == 0) {
            this.message = this.translate.instant("main.no_jobs_found");
            // "Inga job hittades";

          } else {
            var srid = searchResult['_attributes'].SRID;
            var totalJobCount = parseInt(searchResult['_attributes'].totalResultJobCount);
            var fetchedJobs = parseInt(searchResult['_attributes'].jobCount);
            // console.log(srid);

            this.cargoJobList = [];
            this.cargoTableList = [];
            this.displayCargoJobList = [];

            if (!Array.isArray(searchResult.job))
              searchResult.job = [searchResult.job];
            // console.log(searchReply);
            searchResult.job.forEach(job => {
              var jobData = this.parserService.parseCargoJobNew(job, false);
              // console.log(job);
              jobData.select = false;
              this.cargoJobList.push(jobData);

              var previewHtml = `<img width="30px" src="${jobData.thumbnailURI}"
                  style="max-width:40px; max-height:40px; width:auto;">`;
              var elem = {
                preview: previewHtml,
                // actions:actionsHtml,
                job: jobData,
                parent: this,
                select: false,
                showFileRows:false
              };
              jobData.selectHtml = 'XXXXXXX <mat-checkbox color="primary" [(ngModel)]="selectAll">';
              jobData.jobInfoData.forEach(val => {
                elem[val.title] = val.value;
              })

              this.cargoTableList.push(elem);
            });
            this.displayCargoJobList = this.cargoJobList.slice(this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize);

            console.log(this.cargoJobList);
            this.cargoTableListSource = new LocalDataSource(this.cargoTableList);

            console.log("parsing ready");
            console.log(this.cargoJobList);
            this.resortCargoJobList();

            if (totalJobCount > fetchedJobs) {
              this.doCargoSearchMoreResults(srid, fetchedJobs, totalJobCount);
            }
            if (this.cargoJobList.length == 1)
              this.message = this.cargoJobList.length.toString() + " " + this.translate.instant("main.number_of_hits_one");

            if (this.cargoJobList.length > 1)
              this.message = this.cargoJobList.length.toString() + " " + this.translate.instant("main.number_of_hits_many");
          }
        } else {
          this.message = this.translate.instant("main.no_jobs_found");
        }

        /*this.anchors = this.elementRef.nativeElement.querySelectorAll('#test');
      this.anchors.forEach((anchor: HTMLAnchorElement) => {
        anchor.addEventListener('click', this.handleAnchorClick)
      });
      this.anchors = this.elementRef.nativeElement.querySelectorAll('.sortorder');
      this.anchors.forEach((anchor: HTMLAnchorElement) => {
        anchor.addEventListener('click', this.handleAnchorClick)
      });
      */
        // console.log(searchResult);
      });
  }


  onItemSelect(item: any, control: any) {
    console.log(item);
  }


  onSelectAll(items: any, control: any) {
    console.log(items);
  }


  doClearFields(doReset: boolean) {

    this.searchControlList.forEach(control => {
      // console.log(control);

      if ((control.visibility && control.visibility == 'never') || (control.disabledState && control.disabledState == "true")) {

      } else {
        // console.log(control);
        if (control.type == "multiSelect") {
          control.selectedItems = JSON.parse(JSON.stringify(control.defaultItems));

        } else if (control.type == "dateInput") {
          // console.log(control);
          if (doReset) {
            control.model = null;
          } else {
            // console.log(control)
            if (control.defaultValue && control.defaultValue._text &&
              control.defaultValue._text.length >= 8) {
              // console.log("Parse date:" + control.defaultValue._text);
              control.model = this.parserService.parseDate2(control.defaultValue._text);
              // console.log(control.model);
            } else
              control.model = null;
          }
        } else {
          control.ngValue = control.defaultValue;
        }
      }
    });

    // var data = {};
    // this.showCargoFilesDialog(data);
  }


  parseData() {

    // console.log("click button");
    this.getConfiguration();
  }

  itemClicked(item: any): void {
    this.activeJob = item;
    // console.log(item);
    // console.log(item)
    // this.createOrderService.setActiveProduct(this.createOrderService.getActiveProduct(item.id));
    // this.createOrderService.activeProduct = this.createOrderService.activeProduct;
    // this.createOrderService.resetActiveCategory();
  }

  preformAction(action: any): void {


  }

  editCargoSearches() {

    this.showEditSearchView = true;
    this.selectedSavedSearch = this.userPreferences.savedCargoSearchList[0];
    this.searchNameToEdit = this.selectedSavedSearch.name;
  }


  addCargoSearch() {

    this.searchNameToEdit = "";
    this.showEditSearchView = true;
    this.selectedSavedSearch = null;
  }


  closeCargoSearchesView() {

    this.showEditSearchView = false;
    this.selectedSavedSearch = null;
  }


  /*
  getSuggestionValues($event, control) {
    
    var textStart = control.ngValue;
    if (textStart.length >= 3) {
      console.log($event);
      console.log(control);
      var metaId = control.parameter[3];

      this.connectionService.cargoGetTypeaheadValues(metaId, textStart).subscribe(
        configData => {
          var suggestedValueList = configData['cargoResponse']['cargoResponseBody']['suggestionList'];
          console.log(configData);
          control.suggestedValueList = suggestedValueList;
      });
    } else {
      control.suggestionList = [];  
    }
  }
  */

  updateCargoSearch(searchToUpdate: any) {

    var counter = 0;
    this.userPreferences.savedCargoSearchList.forEach(search => {
      if (searchToUpdate.search_uid == search.search_uid) {
        console.log("set search");
        this.userPreferences.savedCargoSearchList[counter] = searchToUpdate;
      }
      counter = counter + 1;
    });
  }

  saveCurrentSearch() {

    console.log(this.selectedSavedSearch);
    var savedSearch = this.getCargoSearchValues();
    console.log(savedSearch);
    // console.log(this.userPreferences.savedCargoSearchList);
    this.selectedSavedSearch.savedSearch = savedSearch.cargoSearch;
    this.updateCargoSearch(this.selectedSavedSearch);
    console.log(this.userPreferences.savedCargoSearchList);

    this.cargoSetUserProperies();
    /*this.connectionService.cargoSetUserProperies("Preferences", JSON.stringify(this.userPreferences)).subscribe(
      configData => {
        console.log(configData);
    });
    */
  }

  addCurrentSearch() {

    var savedSearch = this.getCargoSearchValues();

    savedSearch.cargoSearch.uid =
      this.updateCargoSearch(savedSearch.cargoSearch);
    // console.log(savedSearch);
    var elem = {
      search_uid: uuidv4(),
      name: this.searchNameToEdit,
      cargoSearch: savedSearch.cargoSearch
    }
    this.userPreferences.savedCargoSearchList.push(elem);
    this.cargoSetUserProperies();
    this.showEditSearchView = false;

    /*
    this.connectionService.cargoSetUserProperies("Preferences", JSON.stringify(this.userPreferences)).subscribe(
      configData => {
        
      });
      */

  }


  deleteCurrentSearch() {

    var counter = 0;
    this.userPreferences.savedCargoSearchList.forEach(s => {
      if (s.name == this.selectedSavedSearch.name) {
        this.userPreferences.savedCargoSearchList.splice(counter, 1);
        this.searchNameToEdit = "";
      }
      counter = counter + 1;
    });
    this.cargoSetUserProperies();
    /*
    this.connectionService.cargoSetUserProperies("Preferences", JSON.stringify(this.userPreferences)).subscribe(
      configData => {

    });
    */
  }

  onSearch(query: string = '') {
    this.cargoTableList([
      // fields we want to include in the search
      {
        field: 'id',
        search: query
      },
      {
        field: 'name',
        search: query
      },
      {
        field: 'username',
        search: query
      },
      {
        field: 'email',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }


  getConfiguration() {

    this.isLoadingData = true;
    this.detailControlList = [];
    // this.resultControlList = [];
    this.searchControlList = [];

    this.connectionService.getWebViewLayout().subscribe(
      configData => {
        this.isLoadingData = false;

        // console.log(configData['cargoResponse']['cargoResponseBody']);
        var webViewSetup: any = configData['cargoResponse']['cargoResponseBody']['webViewSetup'];
        if (webViewSetup) {
          // console.log(webViewSetup);
          if (webViewSetup['detailViewLayoutXML']) {
            var detailView: any = webViewSetup['detailViewLayoutXML'];
            if (detailView) {
              this.detailControlList = this.parserService.parseDetailViewLayout(detailView);
            }
          }
          // console.log(this.detailControlList);

          if (webViewSetup['resultItemLayoutXML']) {
            var resultItemView: any = webViewSetup['resultItemLayoutXML'];
            var jobData = this.parserService.parseResultItemLayout(resultItemView);
            this.columnList = jobData.headerList;

            /*this.settings.columns['select'] = {
              filter:false,
              title:this.translate.instant("main.job_selected_checkbox"),
              type: 'custom',
              renderComponent: SelectCheckboxViewComponent,
              onComponentInitFunction(this, instance) {

              }
            };
            */
            /*this.settings.columns['select'] = {
              title: 'Markerta alla',
              filter: {
                type: 'checkbox',
                config: {
                  true: 'Yes',
                  false: 'No',
                  resetText: 'clear',
                },
              },
            },
            */


            this.settings.columns['select'] = {

              title: 'Markerad',
              filter: false,
              type: 'custom',
              renderComponent: DispatchSelectCheckboxViewComponent,
              onComponentInitFunction(this, instance) {

                instance.toggleSelectJob.subscribe(row => {
                  // console.log("toggleSelectJob");
                  row.parent.toggleSelectionMode(row.job);
                });

                instance.toggleShowFiles.subscribe(row => {
                 // console.log("toggleShowFiles");
                  row.parent.toggleShowFilesRow(row);
                });
              }
              // prop:'<mat-checkbox color="primary" [(ngModel)]="selectAll">'
            }

            this.settings.columns['preview'] = {
              title: this.translate.instant("main.preview_column_title"),
              filter: false,
              width: '100px',
              type: 'html'
            };


            this.settings.columns['actions'] = {
              title: this.translate.instant("main.actions_column_title"),
              filter: false,
              width: "130px",
              type: 'custom',
              renderComponent: DispatchActionsButtonViewComponent,
              onComponentInitFunction(this, instance) {
                // var parent = this;
                instance.showCargoJobInfo.subscribe(row => {
                  // console.log(instance);
                  row.parent.showCargoDetailsDialog(row.job);
                  // alert(`${row.name} saved!`)
                });

                instance.downloadCargoJobFile.subscribe(row => {
                  // console.log(row);
                  row.parent.downLoadCargoFile(row.job);
                  // alert(`${row.name} saved!`)
                });

                instance.setCargoJob.subscribe(row => {
                  console.log(row);
                  row.parent.setCargoJob(row);
                  // alert(`${row.name} saved!`)
                });

                instance.setCargoJobForm.subscribe(row => {
                  console.log(row);
                  row.parent.setCargoJobForm(row);
                  // alert(`${row.name} saved!`)
                });

                instance.uploadCargoJobFile.subscribe(elem => {
                  // console.log(elem.row);
                  // console.log(row);
                  elem.row.parent.handleUploadCargoFile2(elem.files, elem.row.job);
                  // alert(`${row.name} saved!`)
                });

                instance.doCargoAction.subscribe(elem => {
                  // console.log(elem);
                  elem.row.parent.preformCargoAction(elem.row.job, elem.action)
                  // alert(`${row.name} saved!`)
                });

                instance.showCargoJobFiles.subscribe(row => {
                  // console.log(row);
                  row.parent.showCargoFilesDialog(row.job);
                  // alert(`${row.name} saved!`)
                });
              }
            }

            this.columnList.forEach(c => {
              this.settings.columns[c.ngValue] = {
                title: c.ngValue,
                type: 'html'
              }
              c.prop = c.ngValue;
            });
            // console.log("---------------------------------");
            // console.log(this.settings);
            this.newSettings = this.settings;
            this.resultData = this.parserService.parseCargoJobLayout(resultItemView);
          }
          // nconsole.log(this.columnList);
          // console.log(this.resultData);
          if (webViewSetup['searchFilterLayoutXML']) {
            var searchFilterLayoutXML: any = webViewSetup['searchFilterLayoutXML']
            this.searchControlList = this.parserService.parseSearchFilterLayout(searchFilterLayoutXML)
            // console.log(this.searchControlList);
            this.doClearFields(false);

            this.applyQueryParameters2();
          }

        }

      }, error => {
        this.isLoadingData = false;
        // console.log("error here");
        this.router.navigate(['/']);

      }
    );
  }


  openFile() {
    // console.log('hell')
    // console.log(document.getElementById("uploadFileButton"));
    // console.log(this.uploadFileButton);

    this.uploadFileControl.nativeElement.value = '';
    this.uploadFileButton.nativeElement.click();
    // document.getElementById("uploadFileButton").click();
  }

  handle(e) {
    // console.log('Change input file');
    // console.log(e);
    // console.log(this.clickedUploadJob);

    this.handleUploadCargoFile3(e.target.files);

  }

  setJobDetails(job: any) {

    this.clickedUploadJob = job;

    if (this.uploadFileControl1)
      this.uploadFileControl1.nativeElement.value = null;

    if (this.uploadFileControl2)
      this.uploadFileControl2.nativeElement.value = null;

    if (this.uploadFileControl)
      this.uploadFileControl.nativeElement.value = null;

    console.log(this.clickedUploadJob);
  }

  setJobDetails2(job: any) {

    this.clickedUploadJob = job;
    // this.uploadFileControl1.nativeElement.value = null;
    // this.uploadFileControl2.nativeElement.value = null;
    // this.uploadFileControl.nativeElement.value = null;
    this.openFile();
    // console.log(this.clickedUploadJob);
  }

  setCargoJob(item: any) {

    this.clickedUploadJob = item.job;
    console.log(item);
    this.openFile();
  }

  setCargoJobForm(item: any) {

    this.clickedUploadJob = item.job;
    // console.log(item);
    // this.openFile();
    this.openFormsModal(item, []);
  }


  showCargoFilesViewer(item: any) {

    console.log(item);
    if (item.showImages) {
      item.showImages = false;
      return
    }
    if (item.imageFiles && item.imageFiles.length > 0) {
      item.showImages = true;
    } else {
      var url = item.filesURL;
      this.getCargoJobImages3(item);
      item.showImages = true;
    }
  }


  showCargoFilesDialog(item: any) {

    // var layoutData = {};
    this.openFileModal(item, []);
    // var url = item.filesURL;
    // this.getCargoJobImages(item, url);
  }


  showCargoFormsDialog(item: any) {

    console.log(item);
    // var layoutData = {};
    this.openFormsModal(item, []);
    // var url = item.filesURL;
    // this.getCargoJobImages(item, url);
  }


  showCargoDetailsDialog(item: any) {

    // var layoutData = {};
    this.openDetailsModal(item);
    // this.openFileModal(item);
  }


  extractFileId(url: string): string {
    var res = "";
    var arr = url.split("fileID=");
    if (arr.length >= 2) {
      var str = arr[1];
      var i = 0;
      while (str.charAt(i) >= '0' && str.charAt(i) <= '9' && i < str.length) {
        i = i + 1;
      }
      res = arr[1].substring(0, i);
    }
    // console.log(res);
    return res;
  }


  getCargoJobImages(jobData: any, url: string): any[] {

    console.log(url);
    var images = [];

    this.connectionService.getCargoJobFilesUrl(url).subscribe(
      searchReply => {
        // console.log(searchReply);
        if (searchReply.files.file) {
          searchReply.files.file.forEach(f => {
            // console.log(f);
            var image = {
              id: this.extractFileId(f._attributes.url),
              downloadURI: f._attributes.downloadURI,
              index: images.length,
              name: f._attributes.name,
              url: f._attributes.url,
              thumbnailUrl: f._attributes.url,
              altText: '',
              tag: 'nature'
            }
            images.push(image);
          });
          this.openFileModal(jobData, images)
        }
      }, error => {
        console.log(error);
      });
    return images;
  }

  getCargoJobImages2(jobData: any, url: string): any[] {

    console.log(url);
    var images = [];

    this.connectionService.getCargoJobFilesUrl(url).subscribe(
      searchReply => {
        // console.log(searchReply);
        if (searchReply.files.file) {
          searchReply.files.file.forEach(f => {
            // console.log(f);
            var image = {
              path: f._attributes.url,
            }
            jobData.imageFiles.push(image);
          });
        }
      }, error => {
        console.log(error);
      });
    return images;
  }


  openFileModal(jobData: any, images: any[]) {
    
    console.log(jobData);
    const modalRef = this.modalService.open(CargoFilesModalDialogComponent,
      {
        scrollable: true,
        windowClass: 'fileDialogClass',
        size: 'lg'
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      title: this.translate.instant("files_dialog.title"),
      jobData: jobData,
      images: images,
    }
    
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
      // jobData.isUploadingFile = false;

      if (result.resultCode == "delete") {

        this.doCargoSearchSingle(jobData.id)

      } else {
        console.log("cancel action");
      }
    }, (reason) => {
      console.log(reason);
    });
  }


  openFormsModal(jobData: any, images: any[]) {

    console.log("jobData");
    console.log(jobData);

    const modalRef = this.modalService.open(CargoFormsModalDialogComponent,
      {
        scrollable: true,
        windowClass: 'cargo-form-class',
        size: 'lg',
        // keyboard: false,
        // backdrop: 'static'
      });

    var url = this.connectionService.url;
    url = url + "?cargoRequest=openProfile";
    url = url + "&PUID=" + jobData.openFormButton.PUID;
    url = url + "&suid=" + this.connectionService.suid;
    var jobIdListNew = "";
    this.displayCargoJobList.forEach(job => {
      if (job.select) {
        jobIdListNew = jobIdListNew + "&jobID=" + job.id;
      }
    });
    if (jobIdListNew == "") {
      jobIdListNew = "&jobID=" + jobData.id;
    }

    url = url + jobIdListNew;
    console.log(url);
    console.log(jobIdListNew);
    // url = "https://nt.se";
    let data = {
      title: this.translate.instant("forms_dialog.title"),
      jobData: jobData,
      images: images,
      iFrameUrl: url
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);

    }, (reason) => {
      console.log(reason);
    });
  }


  openDetailsModal(jobData: any) {
    const modalRef = this.modalService.open(CargoDetailsModalDialogComponent,
      {
        scrollable: true,
        windowClass: 'xlModal',
        size: 'xl'
      });

    let data = {
      title: this.translate.instant("main.cargo_details_dialog_title"),
      jobData: jobData,
    }
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);

      if (result.resultCode == "ok") {
        let form = new FormData();
        form.append("paid", result.paid);

      } else {
        console.log("cancel action");
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  preformCargoAction(item, action) {

    // console.log(action)
    this.connectionService.cargoPrepareAction(action._attributes.id, item.id).subscribe(
      configData => {
        console.log(configData);
        var prepareData = configData['cargoResponse']['cargoResponseBody']['actionPrepareSetup'];
        var paid = prepareData.action._attributes.paid;
        // this.currentPAID = paid;
        var notificationList = configData['cargoResponse'].notificationList;
        // console.log(notificationList);
        var messages = this.parserService.parseNotificationList(notificationList);
        if (messages.length > 0) {
          this.connectionService.cargoCancelAction(paid).subscribe(
            configData => {
            });
        } else {
          if (prepareData.userDialog) {
            var data = prepareData.userDialog.cargoActionsetinput.layout;
            var layoutData = this.parserService.parseActionLayout(data);
            // TODO parse action data
            // TODO show dialog
            this.openCargoActionModal(layoutData, action, paid);
            // open('defaultModal', "{ windowClass: 'modal-lg animate'}");

          } else {
            console.log(paid);
            let form = new FormData();
            // execute action
            console.log("execute action");
            this.connectionService.cargoExcuteAction(form, paid).subscribe(
              executeData => {
                // do something
                var notificationList = executeData['cargoResponse'].notificationList;
                var messages = this.parserService.parseNotificationList(notificationList);
              });
          }
        }
      });
  }

  openCargoActionModal(layoutData, action, paid) {
    const modalRef = this.modalService.open(CargoActionModalComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      title: "Cargo Action:" + action._attributes.name,
      layoutData: layoutData,
      paid: paid
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);

      if (result.resultCode == "ok") {
        let form = new FormData();
        form.append("paid", result.paid);
        result.result.forEach(r => {
          form.append(r.name, r.value);
        });

        // execute action
        console.log("execute action");
        this.connectionService.cargoExcuteAction(form, paid).subscribe(
          executeData => {
            // do something
            console.log(executeData);
            var notificationList = executeData['cargoResponse'].notificationList;
            var messages = this.parserService.parseNotificationList(notificationList);
          });
      } else {
        console.log("cancel action");
        this.connectionService.cargoCancelAction(result.paid).subscribe(
          cancelData => {
            console.log(cancelData);
            var notificationList = cancelData['cargoResponse'].notificationList;
            var messages = this.parserService.parseNotificationList(notificationList);
          });
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  downLoadCargoFile(job: any) {

    console.log("download file");
    console.log(job);
    var filename = job.downloadURI;
    var needle = "downloadFilename=";
    var pos = filename.indexOf(needle);
    if (pos != -1) {
      filename = filename.substring(pos + needle.length,)
    } else {
      filename = job.id + ".pdf";
    }

    this.connectionService.export(job.downloadURI).subscribe(
      data => saveAs(data, filename)
    );
  }

  showCargoInfoDialog(job: any) {

    console.log(job);
    this.openDetailsModal(job);
  }

  showCargoMoreDialog(job: any) {
    console.log(job);
  }

  showCargoFile(item: any) {

    window.open(item.downloadURI, "_blank");
  }


  deleteCargoJobFile(job: CargoJob, file: any) {

    this.connectionService.cargoDeleteJobFile(job, file).subscribe(res => {
      var notificationList = res['cargoResponse'].notificationList;
      var messages = this.parserService.parseNotificationList(notificationList);

      var fileUploadOk = this.translate.instant("main.file_upload_ok");
      if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {
        Swal.fire(
          'Cargo',
          fileUploadOk
        );
      }
    }
    );
  }


  // doesJobHavePUID()

  onMultiFileSelected($event) {
    console.log($event);
  }


  onMultiFileSelectedForm($event) {
    console.log($event);
  }


  handleUploadCargoFile3(files: FileList) {

    // this.filesToUpload = files;
    console.log("upload file");
    // console.log(files);
    console.log(this.clickedUploadJob);
    // console.log(id);

    // const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      let file2 = files.item(i);
      // formData.append('file', file2);

      var job = this.displayCargoJobList.find(x => x.id == this.clickedUploadJob['id']);
      if (job)
        job.isUploadingFile = true;

      console.log("Job to show spinner");
      console.log(job);

      this.connectionService.cargoAddJobFile(this.clickedUploadJob, file2).subscribe(res => {
        console.log(res);
        var notificationList = res['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);
        this.doCargoSearchSingle(this.clickedUploadJob['id']);

        job.isUploadingFile = false;

        var fileUploadOk = this.translate.instant("main.file_upload_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {
          Swal.fire(
            'Cargo',
            fileUploadOk
          ).then((result) => {
            if (this.uploadFileControl1.nativeElement)
              this.uploadFileControl1.nativeElement.value = '';
            if (this.uploadFileControl2.nativeElement)
              this.uploadFileControl2.nativeElement.value = '';
          })
        }
      }, (err) => {
        job.isUploadingFile = false;
        console.log(err);
      }
      );
    }
  }

  handleUploadCargoFile2(files: FileList, job: any) {

    // this.filesToUpload = files;
    console.log("upload file");
    console.log(files);
    console.log(job);
    // console.log(id);

    // const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      let file2 = files.item(i);
      // formData.append('file', file2);
      this.connectionService.cargoAddJobFile(job, file2).subscribe(res => {
        console.log(res);
        var notificationList = res['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);
        this.doCargoSearchSingle(job.id);

        var fileUploadOk = this.translate.instant("main.file_upload_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {
          Swal.fire(
            'Cargo',
            fileUploadOk
          ).then((result) => {

          })
          // this.doCargoSearchSingle(job.id)
        }
      }, (err) => {
        console.log(err);
      }
      );
    }
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/json/ngx-datatable-data.json`);

    req.onload = () => {
      const data = JSON.parse(req.response);
      cb(data);
    };

    req.send();
  }

  handleSortingTable() {

    alert("nisse");
  }

  testForSelectedJobs() {

    if (!this.hasSelectedJobs()) {
      console.log("test for selected jobs");
      var messages = this.translate.instant("main.no_job_selected");
      // var fileUploadOk = this.translate.instant("main.file_upload_ok");
      Swal.fire(
        'Cargo',
        messages,
        // html:message
      ).then((result) => {
      })
    }
  }


  hasSelectedJobs(): boolean {

    if (!this.displayCargoJobList)
      return false;
    if (this.displayCargoJobList.length == 0)
      return false;

    var c = 0;
    this.displayCargoJobList.forEach(job => {
      if (job.select)
        c = c + 1;
    })
    return c > 0;
  }

  toggleSelectGridView(job) {

    this.cargoTableList.forEach(x => {
      if (x.job.id == job.id) {
        x.select = !x.select;
      }
    })
    // console.log(this.cargoTableList);
    // console.log(this.displayCargoJobList);
  }

  toggleShowFilesRow(rowdata) {

    console.log("toggleShowFilesRow");
    console.log(rowdata);

    var index = this.cargoTableList.indexOf(rowdata);

    // alert(rowdata.showFileRows)
    if (! rowdata.showFileRows && index >= 0) {
      console.log("Found index:" + index);
      console.log(rowdata.job);
      this.getCargoJobImages4(rowdata.job, index, rowdata);
      /*setTimeout(() => {
        // this.getCargoJobImages4(rowdata.job, index, rowdata);
      }, 1000);*/
    } else {
      setTimeout(() => {
        for(var i = this.cargoTableList.length - 1; i >= 0; i --) {
        // this.cargoTableList.forEach(x => {
          var x = this.cargoTableList[i];
          if (x.job.id == rowdata.job.id && x.isFileRow) {
            this.cargoTableList.splice(i, 1);
          }
        }
        this.cargoTableListSource = new LocalDataSource(this.cargoTableList);
      }, 1000);
      
    }
  }


  toggleSelectionMode(rowdata) {

    console.log("toggleSelectionMode");
    console.log(rowdata);
    this.displayCargoJobList.forEach(x => {
      if (x.id == rowdata.id)
        x.select = ! x.select;
    })
    // console.log(this.cargoTableList);
    // console.log(this.displayCargoJobList);
  }


  toggleSelectAllJobs() {

    // console.log(this.cargoTableListSource);
    //n console.log(this.displayCargoJobList);

    if (this.hasSelectedJobs()) {
      this.displayCargoJobList.forEach(job => {
        job.select = false;
      })
      this.cargoTableList.forEach(x => {
        x.select = false;
      });
    } else {
      this.displayCargoJobList.forEach(job => {
        job.select = true;
      });
      /*let testfilter = this.cargoTableListSource.getFilteredAndSorted().then(data => {
        console.log(data);
        return data;
      });
      console.log(testfilter);
      */
      var data1:any[] = [];
      this.cargoTableListSource.getFilteredAndSorted().then(value => {
        value.forEach(element => {
          data1.push(element);
          this.cargoTableList.forEach(x => {
            if (element.job.id == x.job.id)
              x.select = true;
          });
        });
      });

      /*console.log(data1);
      console.log(this.cargoTableList);
      this.cargoTableList.forEach(x => {
        console.log("List item");
        console.log(x);
        data1.forEach(y => {
          console.log("dataitem");
          console.log(y);
          if (y.job.id == x.job.id) {
            x.select = true;
            console.log("select")
          }  
        });
      });
      */
    }
    this.cargoTableListSource.refresh();
    // console.log(this.displayCargoJobList);
    // console.log(this.cargoTableList);
  }

  uploadMultiFiles() {

  }



  updateFilter(event) {
    console.log(this.table);

    const val = event.target.value.toLowerCase();
    // filter our data
    console.log(event);

    const temp = this.temp.filter(d => {

      console.log(d);
      var res = false;
      d.jobInfoData.forEach(v => {
        if (v.value.indexOf(val) >= 0)
          res = true;
      })
      return res; // d.name.toLowerCase().indexOf(val) !== -1 || ! val;
    });
    // update the rows
    // this.cargoJobList = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  /*onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  */

  getCargoJobImages3(item: any) {

    console.log("cargoGetFilesForJobNew");
    item.imageFiles = [];
    this.connectionService.cargoGetFilesForJobNew(item).subscribe(
      searchReply => {
        console.log(searchReply);

        if (searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file) {
          var files = searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file;
          console.log(files);

          if (!Array.isArray(files))
            files = [files];
          console.log(files);

          files.forEach(f => {
            var name = f._attributes.name;

            var url = this.connectionService.url + f.thumbnailURI._cdata;

            if (name.toLowerCase().indexOf(".docx") >= 0 || name.toLowerCase().indexOf(".doc") >= 0)
              url = "./assets/img/icons/doc.png";

            if (name.toLowerCase().indexOf(".txt") >= 0)
              url = "./assets/img/icons/txt.png";

            if (name.toLowerCase().indexOf(".zip") >= 0)
              url = "./assets/img/icons/zip.png";

            if (name.toLowerCase().indexOf(".xlsx") >= 0 || name.toLowerCase().indexOf(".xls") >= 0)
              url = "./assets/img/icons/xls.png";

            if (name.toLowerCase().indexOf(".indd") >= 0)
              url = "./assets/img/icons/indd.jpg";

            if (name.toLowerCase().indexOf(".css") >= 0)
              url = "./assets/img/icons/css.png";

            if (name.toLowerCase().indexOf(".csv") >= 0)
              url = "./assets/img/icons/csv.png";

            if (name.toLowerCase().indexOf(".wav") >= 0)
              url = "./assets/img/icons/wav.png";

            if (name.toLowerCase().indexOf(".mov") >= 0)
              url = "./assets/img/icons/mov.png";

            if (name.toLowerCase().indexOf(".avi") >= 0)
              url = "./assets/img/icons/avi.png";

            if (name.toLowerCase().indexOf(".mp3") >= 0)
              url = "./assets/img/icons/mp3.png";

            if (name.toLowerCase().indexOf(".xml") >= 0)
              url = "./assets/img/icons/xml.png";

            if (name.toLowerCase().indexOf(".htm") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".html") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".bridgesort") >= 0)
              url = "./assets/img/icons/bridgesort.png";

            if (name.toLowerCase().indexOf(".vob") >= 0)
              url = "./assets/img/icons/vob.png";

            if (name.toLowerCase().indexOf(".raw") >= 0)
              url = "./assets/img/icons/raw.png";

            var image = {
              path: url,
            }
            item.imageFiles.push(image);
          })
        } else {

        }
        // console.log(searchReply);
      });
  }

  getCargoJobImages4(item: any, index:number, itemTocopy:any) {

    console.log("cargoGetFilesForJobNew");
    item.imageFiles = [];
    this.connectionService.cargoGetFilesForJobNew(item).subscribe(
      searchReply => {
        console.log(searchReply);

        if (searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file) {
          var files = searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file;
          console.log(files);

          if (!Array.isArray(files))
            files = [files];
          console.log(files);

          files.forEach(f => {
            var name = f._attributes.name;

            var url = this.connectionService.url + f.thumbnailURI._cdata;

            if (name.toLowerCase().indexOf(".docx") >= 0 || name.toLowerCase().indexOf(".doc") >= 0)
              url = "./assets/img/icons/doc.png";

            if (name.toLowerCase().indexOf(".txt") >= 0)
              url = "./assets/img/icons/txt.png";

            if (name.toLowerCase().indexOf(".zip") >= 0)
              url = "./assets/img/icons/zip.png";

            if (name.toLowerCase().indexOf(".xlsx") >= 0 || name.toLowerCase().indexOf(".xls") >= 0)
              url = "./assets/img/icons/xls.png";

            if (name.toLowerCase().indexOf(".indd") >= 0)
              url = "./assets/img/icons/indd.jpg";

            if (name.toLowerCase().indexOf(".css") >= 0)
              url = "./assets/img/icons/css.png";

            if (name.toLowerCase().indexOf(".csv") >= 0)
              url = "./assets/img/icons/csv.png";

            if (name.toLowerCase().indexOf(".wav") >= 0)
              url = "./assets/img/icons/wav.png";

            if (name.toLowerCase().indexOf(".mov") >= 0)
              url = "./assets/img/icons/mov.png";

            if (name.toLowerCase().indexOf(".avi") >= 0)
              url = "./assets/img/icons/avi.png";

            if (name.toLowerCase().indexOf(".mp3") >= 0)
              url = "./assets/img/icons/mp3.png";

            if (name.toLowerCase().indexOf(".xml") >= 0)
              url = "./assets/img/icons/xml.png";

            if (name.toLowerCase().indexOf(".htm") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".html") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".bridgesort") >= 0)
              url = "./assets/img/icons/bridgesort.png";

            if (name.toLowerCase().indexOf(".vob") >= 0)
              url = "./assets/img/icons/vob.png";

            if (name.toLowerCase().indexOf(".raw") >= 0)
              url = "./assets/img/icons/raw.png";

            var image = {
              path: url,
            }
            var elem = {
              preview: itemTocopy.preview,
              job: itemTocopy.job,
              parent: this,
              select: false,
              isFileRow:true
            };
            // copy.preview = url;
            elem.select = false;
            itemTocopy.job.jobInfoData.forEach(val => {
              elem[val.title] = val.value;
            })
            this.cargoTableList.splice(index+1, 0, elem);
          })
          this.cargoTableListSource = new LocalDataSource(this.cargoTableList);
        } else {

        }
        // console.log(searchReply);
      });
      console.log(this.cargoTableList);
  }


 

  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  onUserRowSelect(event): void {

    console.log("Row is ::: ", event);
    this.selectedListJob = event.data;

    var index = this.cargoTableList.indexOf(event.data);
    console.log(index);
    // this.getCargoJobImages4(event.data.job, index, event.data);
    // var copy = JSON.parse(JSON.stringify(event.data));
   //  copy.partent = null;

  }
}





import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Console } from "console";
import { ɵAnimationRendererFactory } from "@angular/platform-browser/animations";
import { collapseTextChangeRangesAcrossMultipleVersions, isNonNullExpression } from "typescript";
import Swal from "sweetalert2";
import { compileBaseDefFromMetadata } from "@angular/compiler";
import { isArray } from "util";

// import { TEMPLATES } from './mock-templates';

@Injectable({providedIn: 'root'})
export class LayoutParserService {

    // private placementsUrl = environment.apiUrl + 'api/placements';
    constructor(private _http: HttpClient) {
    }

    // htmlString:string = "";

    searchControlList:any[] = [];
    detailItemList:any[] = [];
    resultItemList:any[] = [];

    nofColumnInGrid:number = 1;
    nofRowsInGrid:number = 1;

    findRadioGroupComponent(group):any {
        var res = null;
        this.searchControlList.forEach(c => {
            if (c.type == "radioGroup" && c.group == group)
                res = c;
        });
        return res;
    }

    parseDate(datestr:string) : NgbDateStruct {
        
        var now = new Date(datestr);
        // console.log(now);
        var model: NgbDateStruct = {
            year: now.getFullYear(),
            month: now.getMonth()+1,
            day: now.getDate()
        };
        return model;
    }       
    
    parseDate2(datestr:string) : any {
        
        var now = new Date(datestr);
        // console.log(now);
        return now;
    }       

    parseChildList(comp:any, list, jobData) {

        // console.log("incomming");
        // console.log(comp);
        
        if (Array.isArray(comp)) {
            comp.forEach(child => {
                // console.log("Found child");
                // console.log(child)
                this.parseChildList(child, list, jobData);
            });
        } else {
            if('type' in comp['_attributes']) {
                comp.type = comp['_attributes'].type;
                comp.ngValue = "";
                comp.defaultValue = "";

                if ('name' in comp['_attributes']) {
                    // console.log(comp['_attributes']);
                    comp.placeholder = comp['_attributes'].name;
                    comp.name = comp['_attributes'].name;
                }

                if (comp['_attributes'].type == "grid") {
                    // console.log("grid");
                    // console.log(comp);
                    var gridData = comp.children.component;

                    /*this.htmlString = this.htmlString  + "<div class=\"card-body\">";
                    this.htmlString = this.htmlString + "<div class=\"row\">";
                    */
                    this.parseChildList(gridData, list, jobData);
                    // this.htmlString = this.htmlString +"</div></div>";

                } else if (comp['_attributes'].type == "gridData" || comp['_attributes'].type == "gridCell") {
                    // console.log("griddata");
                    // console.log(comp);

                    // this.htmlString = this.htmlString + "<div class=\"col-md-6 mb-3\">";

                    if (comp.children.component) {
                        var complist = comp.children.component;
                        this.parseChildList(complist, list, jobData);
                    }
                    // this.htmlString = this.htmlString + "</div>";

                } else if (comp['_attributes'].type == "gridCell") {
                    // console.log("gridcell");
                    // console.log(comp);
                    if (comp.children.component) {
                        var complist = comp.children.component;
                        this.parseChildList(complist, list, jobData);
                    }
                } else if (comp['_attributes'].type == "select") {
                    // console.log("select");
                    // console.log(comp);
                    // comp.visibility = "false"; 
                    // comp.visibility = "true"; 
                    // comp.enabledStateCondition = 'no';

                    comp.cargoOption.forEach(option => {
                        comp.defaultValue = "";

                        if (option.value._text) {
                            option.value = option.value._text;
                        }
                        if (option.text._text) {
                            option.text = option.text._text;
                        }
                        comp.defaultValue = "";
                        comp.ngValue = "";
                        if (option['_attributes'].selected == "true") {
                            if (option.value && option.value_text) {
                                comp.defaultValue = option.value._text;
                                comp.ngValue = option.value._text;
                            }
                        }
                    })
                    list.push(comp);

                } else if (comp['_attributes'].type == "text") {
                    console.log("text");
                    console.log(comp);

                    // console.log("parseJobAttributes2");
                    // console.log(comp);

                    this.parseJobAttributes(comp, jobData);
                    comp.ngValue = "";
                    list.push(comp);

                } else if (comp['_attributes'].type == "radio") {
                    // console.log("Radio");
                    // console.log(comp);

                    // this.parseJobAttributes(comp, jobData);
                    var group = comp.group;

                    var radioGroup = this.findRadioGroupComponent(group);
                    // default value of selected radiobutton
                    if (comp.selected == "true") {
                        comp.defaultValue = comp.value;
                        comp.ngValue = comp.value;
                    }
                    if (radioGroup != null) {
                        var r = {
                            text:comp.name = comp.text,
                            value:comp.value,
                            group:group,
                            default:comp.defaultValue
                        }
                        radioGroup.radioList.push(r);
                    } else {
                        comp.group = group;
                        comp.radioList = [];
                        comp.name = comp.text;
                        var r = {
                            text:comp.text,
                            value:comp.value,
                            group:group,
                            default:comp.defaultValue
                        }
                        comp.type = "radioGroup";
                        comp.radioList.push(r);
                        // console.log("add to radiolist");
                        list.push(comp);
                    }
                    // console.log(comp)
                } else if (comp['_attributes'].type == "checkbox") {
                    // var group = 
                    // console.log("checkbox");
                    // console.log(comp);
                    this.parseJobAttributes(comp, jobData);
                    // console.log("parseJobAttributes3");
                    // console.log(comp);

                    // should it be slected
                    if (comp.parameter[5] == "true") {
                        comp.defaultValue = true;
                        comp.ngValue = true;
                    } else {
                        comp.defaultValue = comp.ngUnselectedValue;
                        comp.ngValue = comp.ngUnselectedValue;
                        comp.defaultValue = false;
                        comp.ngValue = false;
                    }
                    list.push(comp);
                } else if (comp['_attributes'].type == "dateInput") {
                    // console.log("dateInput");
                    comp.name = comp['_attributes'].name;
                    // console.log(comp);

                    // console.log("parseJobAttributes4");
                    // console.log(comp);
                    this.parseJobAttributes(comp, jobData);

                    comp.model = this.parseDate(comp.parameter[8]);
                    /*const now = new Date();
                    var model: NgbDateStruct = {
                        year: now.getFullYear(),
                        month: now.getMonth(),
                        day: now.getDate()
                    };*/
                    // comp.model = model;
                    comp.defaultValue = comp.parameter[8];
                    comp.ngValue = "";
                    list.push(comp);
                } else if (comp['_attributes'].type == "multiSelect") {
                    comp.ngValue = "";
                    comp.dropdownList = [];
                    comp.selectedItems = [];
                    console.log("multiselect");
                    console.log(comp);

                    comp.cargoOption.forEach(option => {
                        var elem = {
                            id:option.value._text,
                            text:option.text._text,
                        }
                        if (option['_attributes'].selected == "true") {
                            comp.selectedItems.push(option.value._text); 
                        }
                        comp.dropdownList.push(elem);
                    });
                    comp.defaultItems = JSON.parse(JSON.stringify(comp.selectedItems));
                    list.push(comp);

                } else if (comp['_attributes'].type == "clearButton") {
                    // console.log(comp);
                    // console.log("parseJobAttributes5");
                    // console.log(comp);
                    this.parseJobAttributes(comp, jobData);
                    comp.name = comp.text;
                    list.push(comp);

                } else if (comp['_attributes'].type == "searchButton") {
                    // console.log(comp);
                    // console.log("parseJobAttributes6");
                    // console.log(comp);
                    this.parseJobAttributes(comp, jobData);
                    comp.name = comp.text;
                    list.push(comp);

                } else if (comp['_attributes'].type == "label" || comp['_attributes'].type == "labelPreformat") {

                    // nconsole.log("parseJobAttributes7");
                    // console.log(comp);

                    this.parseJobAttributes(comp, jobData);

                    if (comp.css_class == "result") {

                    }
                    if (comp.css_class) {
                        // console.log("label");
                        if (comp.css_class.indexOf("header") >= 0) {
                            jobData.headerList.push(comp);
                        }
                    }
                } else if (comp['_attributes'].type == "image" || comp['_attributes'].type == "imagePreformat") {
                    // console.log("image");
                    // console.log(comp);
                    list.push(comp);

                } else if (comp['_attributes'].type == "labelPreformat") {
                    // console.log("labelPreformat");
                    list.push(comp);

                } else if (comp['_attributes'].type == "moreButton") {
                    // console.log("moreButton");
                    list.push(comp);
                    
                } else if (comp['_attributes'].type == "downloadFileButton") {
                    // console.log("downloadFileButton");
                    list.push(comp);

                } else if (comp['_attributes'].type == "infoButton") {
                    // console.log("infoButton");
                    list.push(comp);

                } if (comp['_attributes'].type == "saleswatch.openFormButton") {
                    // console.log("saleswatch.openFormButton");
                    list.push(comp);
                }else {
                    // console.log(comp);
                    // console.log("type:" + comp['_attributes'].type);    
                }
            } else {
                console.log("found comp:");
                console.log(comp);
            }
        }
    }

    parseJobAttributes(comp, jobData:any) {

        // console.log("Parse attributes");
        // console.log(comp);
        if (! Array.isArray(comp.parameter))
            comp.parameter = [comp.parameter];

        comp.ngValue = "";
        comp.css_class = "";
        comp.requiredField = false;
        
        /*
            <parameter key="enabledState" xmltype="false">always</parameter>
            <parameter key="visibility" xmltype="false">always</parameter>
            <parameter key="PUID" xmltype="false"/>
            <parameter key="visibilityCondition" xmltype="false"/>
            <parameter key="enabledStateCondition" xmltype="false"/>
            <parameter key="text" xmltype="false">Upload to all selected...</parameter>
            <parameter key="styleVariant" xmltype="false">contained</parameter>
            <parameter key="colorVariant" xmltype="false">inherit</parameter>
        */

        if (comp.parameter) {
            // console.log(comp.parameter);
            comp.parameter.forEach(param => {
                if (param._attributes) {

                    
                    if (param._attributes.key == "required") {
                        comp.requiredField = param._text; 
                        // console.log(comp);   
                    }
                    if (param._attributes.key == "disabled") {
                        comp.disabledState = param._text; 
                        // console.log(comp);   
                    }
                    
                    if (param._attributes.key == "enabledState") {
                        comp.enabledState = param._text;    
                    }
                    if (param._attributes.key == "visibility") {
                        comp.visibility = param._text;    
                    }
                    if (param._attributes.key == "PUID") {
                        comp.PUID = param._text;    
                    }
                    if (param._attributes.key == "visibilityCondition") {
                        comp.visibilityCondition = param._text;    
                    }
                    if (param._attributes.key == "enabledStateCondition") {
                        comp.enabledStateCondition = param._text;    
                    }
                    if (param._attributes.key == "styleVariant") {
                        comp.styleVariant = param._text;    
                    }
                    if (param._attributes.key == "colorVariant") {
                        comp.colorVariant = param._text;    
                    }


                    if (param._attributes.key == "suggestionMetaDataID") {
                        comp.suggestionMetaDataID = param._text;    
                    }
                    if (param._attributes.key == "promptText") {
                        comp.placeholder = param._text;    
                    }
                     
                    if (param._attributes.key == "filesURL") {
                        jobData.filesURL = param._text;    
                    }
                    if (param._attributes.key == "nofFiles") {
                        jobData.nofFiles = param._text;    
                    }
                    
                    if (param._attributes.key == "selected") {
                        comp.selected = param._text;
                        comp.ngSelected = param._text;
                    }
                    if (param._attributes.key == "group") {
                        comp.group = param._text;
                    }
                    if (param._attributes.key == "valueIfSelected") {
                        comp.valueIfSelected = param._text;
                    }
                    if (param._attributes.key == "valueIfNotSelected") {
                        comp.valueIfNotSelected = param._text;
                    }

                    if (param._attributes.key == "css.classname") {
                        
                        if (param._text) {
                            comp.css_class = param._text;
                        }
                    }
                    if (param._attributes.key == "columnCount") {
                        comp.columns = param._text;
                    }
                    if (param._attributes.key == "columnIndex") {
                        comp.columnIndex = param._text;
                    }
                    if (param._attributes.key == "rowIndex") {
                        comp.rowIndex = param._text;
                    }
                    if (param._attributes.key == "rowCount") {
                        comp.rows = param._text;
                    }
                    if (param._attributes.key == "valign") {
                        comp.valign = param._text;
                    }
                    if (param._attributes.key == "halign") {
                        comp.halign = param._text;
                    }
                    if (param._attributes.key == "imageType") {
                        comp.image_type = param._text;
                    }
                    if (param._attributes.key == "text") {
                        comp.ngValue = param._text;
                        comp.text = param._text;
                    }
                    if (param._attributes.key == "value") {
                        comp.value = param._text;
                        // comp.ngValue = param._text;
                    }
                }

                if (param.a) {
                    // console.log(param);
                    jobData.filesURL = param.a._attributes.href; // arr[1];
                    jobData.nofFiles = param.a['_text'];
                    comp.ngValue = param.a._text;
                    var testHtml = '<a style="color:blue" onclick="Window.MyComponent.openCargoFilesDialog('
                     + jobData.id + ')">' + comp.ngValue + '</a>';

                    // var testHtml = '<a style="color:blue" onclick="Window.MyComponent.openCargoFilesDialog(' +
                    //       jobData.id + ')">' + comp.ngValue + '</a>';
                    comp.ngValue = testHtml; // "<a href=\"javascript:void(0)\" (click)=\"Window.MyComponent.openCargoFilesDialog('" + 
                        // jobData.id + "')\">" + comp.ngValue + "</a>";

                    // comp.ngValue = "<a href=\"javascript:void(0)\" (click)=\"context.openCargoFilesDialog('" + 
                    //     jobData.id + "')\">" + comp.ngValue + "</a>";
                    comp.text = comp.ngValue; // param.a._text;
                }

                if (param['@attributes']) {
                    if (param['@attributes'].key == "css.classname") {
                        comp.css_class = param._text;
                    }
                    if (param['@attributes'].key == "columnCount") {
                        comp.columns = param._text;
                    }
                    if (param['@attributes'].key == "columnIndex") {
                        comp.columnIndex = param._text;
                    }
                    if (param['@attributes'].key == "rowIndex") {
                        comp.rowIndex = param._text;
                    }
                    if (param['@attributes'].key == "rowCount") {
                        comp.rows = param._text;
                    }
                    if (param['@attributes'].key == "imageType") {
                        comp.image_type = param._text;
                    }
                    if (param['@attributes'].key == "text") {
                        comp.ngValue = param._text;
                    }
                }
            });
        }
        // console.log(comp);
    }


    parseCargoJobData(comp:any, jobData) { //list, headerList, dataList) {

        // console.log("incomming");
        // console.log(comp);
        
        if (Array.isArray(comp)) {
            let c = 0;
            // console.log("has children");
            // console.log(comp);

            comp.forEach(child => {
                if (comp['type'] == "gridData" || comp['type'] == "gridCell") {
                    // this.htmlString = this.htmlString + "<div class=\"col\">" + c;
                    // this.htmlString = this.htmlString  + "\r\n";
                }
                this.parseCargoJobData(child, jobData); //list, headerList, dataList);

                if (comp['type'] == "gridData" || comp['type'] == "gridCell") {
                    // this.htmlString = this.htmlString  + c + "</div>\r\n";
                    c = c + 1;
                }
            });
        } else {
            // console.log(comp);
            if ('type' in comp['_attributes']) {
                comp.type = comp['_attributes'].type;
                comp.ngValue = "";
                comp.defaultValue = "";

                if ('name' in comp['_attributes']) {
                    // console.log(comp['_attributes']);
                    comp.placeholder = comp['_attributes'].name;
                    comp.name = comp['_attributes'].name;
                }

                if (comp['_attributes'].type == "grid") {
                    console.log("grid");
                    
                    // KUKEN this.parseJobAttributes(comp, jobData);
                    // console.log(comp);

                    var gridData = comp.children.component;
                    console.log(gridData);
                    if (! Array.isArray(gridData)) {
                        gridData = [gridData];
                    }
                    console.log(gridData);
                    var count = 0;

                    // this.htmlString = this.htmlString + "<table>";
                    this.parseCargoJobData(gridData, jobData); 
                    // this.htmlString = this.htmlString + "</table>";
                    /*gridData.forEach(child => {
                        
                        this.htmlString = this.htmlString + "<div class=\"test col-2\">";
                        this.htmlString = this.htmlString  + count + "\r\n";
                        // this.htmlString = this.htmlString  + "<p>";
                        
                        this.htmlString = this.htmlString + count + "</div>";
                        // this.htmlString = this.htmlString  + "</p>";
                        this.htmlString = this.htmlString  + "\r\n";
                        count = count + 1;
                    });
                    */

                    // this.parseCargoJobData(gridData, jobData); //list, headerList, dataList);
                    // this.htmlString = this.htmlString + "</div>\r\n</div>";
                    // this.htmlString = this.htmlString  + "\r\n";

                } else if (comp['_attributes'].type == "gridData" || comp['_attributes'].type == "gridCell") {
                    
                    console.log("griddata");
                    // console.log(comp);
                    if (comp.children.component) {
                        // this.htmlString = this.htmlString + "<div class=\"col\">";
                        // this.htmlString = this.htmlString  + "\r\n";
                        var complist = comp.children.component;
                        this.parseCargoJobData(complist, jobData); //list, headerList, dataList);
                        // this.htmlString = this.htmlString + "<p>data</p>\r\n";

                        // this.htmlString = this.htmlString + "</div>";
                        // this.htmlString = this.htmlString  + "\r\n";
                    }
                } else if (comp['_attributes'].type == "gridCell") {
                    console.log("gridcell");
                    // console.log(comp);
                    if (comp.children.component) {

                        // this.htmlString = this.htmlString + "<div class=\"col\">";
                        // this.htmlString = this.htmlString  + "\r\n";
                        // this.htmlString = this.htmlString + "<p>gridcell</p>\r\n";
                        var complist = comp.children.component;
                        this.parseCargoJobData(complist, jobData); //list, headerList, dataList);

                        // this.htmlString = this.htmlString + "</div>";
                        // this.htmlString = this.htmlString  + "\r\n";
                    }
                } else if (comp['_attributes'].type == "select") {
                    console.log("select");
                    console.log(comp);
                    // comp.visibility = "true"; 
                    // comp.enabledStateCondition = 'no';

                    comp.cargoOption.forEach(option => {
                        if (option['_attributes'].selected == "true") {
                            comp.ngValue = option.value;
                            comp.defaultValue = option.value;
                        }
                    })
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "text") {
                    // console.log("text");
                    comp.ngValue = "";
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "radio") {
                    var group = comp.parameter[4];
                    console.log("group:" + group)
                    console.log(comp);

                    var radioGroup = this.findRadioGroupComponent(group);
                    // default value of selected radiobutton
                    if (comp.parameter[3] == "true") {
                        comp.defaultValue = comp.parameter[2];
                        comp.ngValue = comp.parameter[2];
                    }
                    if (radioGroup != null) {
                        // console.log("found group");
                        var r = {
                            text:comp.name = comp.parameter[1],
                            value:comp.parameter[2],
                            group:group,
                            default:comp.parameter[3]
                        }
                        radioGroup.radioList.push(r);
                    } else {
                        comp.group = group;
                        comp.radioList = [];
                        comp.name = comp.parameter[1];
                        var r = {
                            text:comp.name = comp.parameter[1],
                            value:comp.parameter[2],
                            group:group,
                            default:comp.parameter[3]
                        }
                        comp.type = "radioGroup";
                        jobData.controlList.push(comp);
                        comp.radioList.push(r);
                        
                    }
                    console.log(comp)

                } else if (comp['_attributes'].type == "checkbox") {
                    // var group = 
                    console.log(comp);
                    comp.ngSelectedValue = comp.parameter[4];
                    comp.ngUnselectedValue = comp.parameter[1];
                    comp.text = comp.parameter[3];
                    // should it be slected
                    if (comp.parameter[5] == "true") {
                        comp.defaultValue = true;
                        comp.ngValue = true;
                    } else {
                        comp.defaultValue = comp.ngUnselectedValue;
                        comp.ngValue = comp.ngUnselectedValue;
                        comp.defaultValue = false;
                        comp.ngValue = false;
                    }
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "dateInput") {
                    console.log("dateInput");
                    comp.name = comp['_attributes'].name;
                    console.log(comp);

                    comp.model = this.parseDate(comp.parameter[8]);
                    /*const now = new Date();
                    var model: NgbDateStruct = {
                        year: now.getFullYear(),
                        month: now.getMonth(),
                        day: now.getDate()
                    };*/
                    // comp.model = model;
                    comp.defaultValue = comp.parameter[8];
                    comp.ngValue = "";
                    jobData.controlList.push(comp);
                } else if (comp['_attributes'].type == "multiSelect") {
                    comp.ngValue = "";
                    comp.dropdownList = [];
                    comp.selectedItems = [];
                    console.log(comp);

                    comp.cargoOption.forEach(option => {
                        var elem = {
                            id:option.value,
                            text:option.text,
                        }
                        if (option['_attributes'].selected == "true") {
                            comp.selectedItems.push(option.value); 
                        }
                        comp.dropdownList.push(elem);
                    });
                    comp.defaultItems = JSON.parse(JSON.stringify(comp.selectedItems));
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "clearButton") {
                    // console.log(comp);
                    if (Array.isArray(comp.parameter)){
                        comp.name = comp.parameter[0];
                    } else {
                        comp.name = comp.parameter;
                    }
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "searchButton") {
                    console.log("searchbutton");
                    console.log(comp);
                    if (Array.isArray(comp.parameter)){
                        comp.name = comp.parameter[0];
                    } else {
                        comp.name = comp.parameter;
                    }
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "label" || comp['_attributes'].type == "labelPreformat") {
                    // headerlist, dataList
                    comp.parameter.forEach(p => {
                        if (p._attributes.key == "css.classname") {
                            console.log(p);

                            // console.log(p._text);
                            if (p._text && p._text.indexOf("header") >= 0) {
                                jobData.headerList.push(comp); 
                                console.log("add header");
                            } 
                            if (p._text && p._text.indexOf("result") >= 0) {
                                jobData.dataList.push(comp);
                                console.log("add data");
                            }   
                        }
                        if (p._attributes.key == "text") {
                            comp.value = p._text;
                        }
                       
                    })
                    // this.htmlString = this.htmlString + comp.ngValue;
                    // console.log(comp);
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "image" || comp['_attributes'].type == "imagePreformat") {
                    console.log("image");
                    console.log(comp);
                    if (comp.parameter[2]._text == "fileThumbnail") {
                        comp.fileThumbnail = true;

                        /*this.htmlString = this.htmlString + "<img class=\"card-img-top mb-3\"";
                        this.htmlString = this.htmlString + " src=\"" + jobData.thumbnailURI + "\" alt=\"" + jobData.thumbnailURI + "\">";
                        this.htmlString = this.htmlString  + "\r\n";
                        */
                    }
                    if (comp.parameter[2]._text == "publicURL") {
                        comp.fileThumbnail = false;
                        comp.url = comp.parameter[0]._text;
                        /*this.htmlString = this.htmlString + "<img class=\"mb-3\""; 
                        this.htmlString = this.htmlString + " src=\"" + comp.url + "\" alt=\"" + comp.url + "\">";
                        this.htmlString = this.htmlString  + "\r\n";
                        */
                    }
                    if (comp.parameter[2]._text == "serverPath") {
                        comp.fileThumbnail = false;
                        comp.url = comp.parameter[0]._text;
                        /*this.htmlString = this.htmlString + "<img class=\"mb-3\""; 
                        this.htmlString = this.htmlString + " src=\"" + comp.url + "\" alt=\"" + comp.url + "\">";
                        this.htmlString = this.htmlString  + "\r\n";
                        */
                    }

                    // console.log(comp);
                    // var url = comp.attributes[0];
                    // console.log(url);
                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "labelPreformat") {
                    // console.log("labelPreformat");

                    jobData.controlList.push(comp);

                } else if (comp['_attributes'].type == "moreButton") {
                    // console.log("moreButton");
                    jobData.controlList.push(comp);
                    jobData.moreButton = comp;
                    
                } else if (comp['_attributes'].type == "downloadFileButton") {
                    // console.log("downloadFileButton");
                    jobData.controlList.push(comp);
                    jobData.downloadButton = comp;

                } else if (comp['_attributes'].type == "infoButton") {
                    // console.log("infoButton");
                    jobData.controlList.push(comp);
                    jobData.infoButton = comp;
                } else {
                    console.log(comp);
                    console.log("type:" + comp['_attributes'].type);    
                }
            } else {
                console.log("found comp:");
                console.log(comp);
            }
        }
    }



    parseCargoJob(job:any) {
        
        // console.log(job);
        // console.log(job.previewURI._cdata);
        var preview = "";
        var download = "";
        var thumbnail = "";
        var actionList =[];

        // this.htmlString = "";

        if (job.actionList && job.actionList.actionItem) {
            if (! Array.isArray(job.actionList.actionItem)) {
                actionList = [job.actionList.actionItem];
                // console.log("not array");
            } else {
                actionList = job.actionList.actionItem;
            }
        }

        if (job.previewURI._cdata)
            preview = job.previewURI._cdata;

        if (job.thumbnailURI._cdata)
            thumbnail = job.thumbnailURI._cdata;

        if (job.downloadURI._cdata)
            download = job.downloadURI._cdata;

        var jobData = this.createJobData();
        jobData.id = job._attributes.id;
        jobData.connectId = job._attributes.connectID;
        jobData.previewURI = preview;
        jobData.thumbnailURI = thumbnail;
        jobData.downloadURI = download;
        jobData.actionList = actionList;

        var parent = job.resultViewLayout.layout.pages.page.componentList.component;
        // console.log(parent);

        /*this.htmlString = this.htmlString  + "<div class=\"container\">";
        this.htmlString = this.htmlString  + "\r\n";
        this.htmlString = this.htmlString + "<div class=\"row\">";
        this.htmlString = this.htmlString  + "\r\n";
        */
        var controlList = [];
        var headerList = [];
        var dataList = [];
        this.parseCargoJobData(parent, jobData); //controlList, headerList, dataList);
        // jobData.html = this.htmlString;
        console.log(jobData.html);

        /*this.htmlString = this.htmlString  + "</div>\r\n";
        this.htmlString = this.htmlString  + "</div>\r\n";
        */

        // jobData.controlList = controlList;
        // jobData.dataList = dataList;
        // jobData.headerList = headerList;
        // console.log(jobData);
        // console.log(job.resultViewLayout.layout.pages.page.componentList.component);
        return jobData;
    }


    parseSearchFilterLayout(layout:any) : any {
        
        this.searchControlList = [];
        var jobData = this.createJobData();

        var parent = layout.layout.pages.page.componentList.component;
        // console.log(parent);
        this.parseChildList(parent, this.searchControlList, jobData);
        return this.searchControlList;
    }

    parseResultItemLayout(layout:any) : any {
        
        this.resultItemList = [];
        var jobData = this.createJobData();

        var parent = layout.layout.pages.page.componentList.component;
        // console.log(parent);
        this.parseChildList(parent, this.resultItemList, jobData);
        // console.log(this.resultItemList);
        return jobData; // this.resultItemList;
    }

    parseDetailViewLayout(layout:any) : any {
        
        // var list = [];
        this.detailItemList = [];
        var jobData = this.createJobData();

        var parent = layout.layout.pages.page.componentList.component;
        // console.log(parent);
        this.parseChildList(parent, this.detailItemList, jobData);
        return this.detailItemList;
    }

    parseNotificationList(nofificationList:any): any[] {

        var messages = [];
        // console.log(nofificationList);
        if (! nofificationList.notification)
            return messages;

        var notifcations = nofificationList.notification;

        if (! Array.isArray(notifcations)) {
            notifcations = [notifcations]; 
        }
        // console.log(notifcations)
        var title = "";
        var message = "";

        notifcations.forEach( not => {   

            var mess = {
                title:not.title._cdata,
                message: not.message._cdata
            }
            if (title == "") {
                title:not.title._cdata;
            }
            if (message.length > 0) {
                message = message + ". ";
            }
            message = message + not.message._cdata;
            // console.log(message.message);
            messages.push(mess);
        });
        Swal.fire({
            title: title, 
            text: message,
            allowOutsideClick: false,
            type: 'warning'
            // footer: '<a href="javascript:void(0)">Why do I have this issue?</a>'
        });
          
        /*Swal.fire({
            title: title,
            text: message,
            type: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel plx!'
          }).then(function(result) {
            if (result.value) {
              Swal.fire('Deleted!', 'Your imaginary file has been deleted.', 'success');
            } else {
              Swal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
            }
          });
          */
        return messages;
    }


    parseActionLayoutResources(actionLayout:any, resources:any[]) {

        // console.log("Resources");
        // console.log(resources);

        resources.forEach( res => {

            res.posY = parseInt(res._attributes.y);
            // console.log(res);
            if (res['_attributes']['type'] == "label" || res['_attributes']['type'] == "labelPreformat") {
                // console.log(res);
                res.type = 'label';
                if (res.parameter) {
                    if (! isArray(res.parameter)) {
                        res.parameter = [res.parameter];
                    }
                    // console.log(res.parameter);
                    res.parameter.forEach( para => {
                        // console.log(para['_attributes']);
                        // console.log(para['_attributes'].key);
                        if (para['_attributes'].key == 'text') {
                            res.value = para['_cdata'];  
                            // console.log(para);
                        }
                        // console.log(para['_cdata']);
                    })
                }
                if (res.value == null)
                    res.value = "";

                /*res.value = res.value.replace("<html>", "");
                res.value = res.value.replace("<b>", "");
                res.value = res.value.replace("<B>", "");*/
                actionLayout.resources.push(res); 
                // console.log(res);

            } else if (res['_attributes']['type'] == "dialogCancelButton") {
                actionLayout.dialogCancelButton = res;

            } else if (res['_attributes']['type'] == "dialogOkButton") {
                actionLayout.dialogOkButton = res;

            } else {
                console.log("Resource type:" + res['_attributes']['type']);
            }
            // console.log(res);

        });
    }


    findClolestLabel(input:any, resources:any):any {

        var res = null;

        // var x = parseInt(input._attributes.x);
        var y = parseInt(input._attributes.y);
        // console.log("Find for position:" + y );

        resources.forEach( r => {
            // var posX = parseInt(r._attributes.x);
            var posY = parseInt(r._attributes.y);
            
            if (Math.abs(y - posY) < 10) {
                res = r;
                // console.log("found label"  + Math.abs(y - posY));
                // console.log("Found at position:" + posY );
                // console.log(r);
            }
        })
        return res;
    }


    parseActionLayoutInputs(actionLayout:any, inputs:any[]) {
        
        console.log("Inputs");
        console.log(inputs);

        inputs.forEach( input => {
            // console.log(input);
            input.type = input['_attributes']['type'];
            input.label = null;
            input.placeholder = "";
            input.ngValue = "";
            input.componentName = input.name._cdata;
            input.posY = parseInt(input._attributes.y);

            // console.log(inputs);
            if (input['_attributes']['type'] == "textArea") {
                input.label = this.findClolestLabel(input, actionLayout.resources);
                // console.log(input);

                input.parameter.forEach( p => {
                    if (p._attributes.key == "cols")
                        input.cols = parseInt(p._cdata);
                    if (p._attributes.key == "rows")
                        input.rows = parseInt(p._cdata);
                })
                actionLayout.inputs.push(input);    

            } else if (input['_attributes']['type'] == "text") {
                console.log(input);
                input.ngValue = ""; // input['_attributes']
                input.label = this.findClolestLabel(input, actionLayout.resources);
                actionLayout.inputs.push(input); 

                input.parameter.forEach( p => {
                    if (p._attributes.key == "hidden") {
                        if ( p._cdata) {
                            input.hidden = p._cdata;
                        } 
                    }
                    if (p._attributes.key == "required") {
                        if ( p._cdata) {
                            input.requiredField = p._cdata;
                        }
                    }
                    if (p._attributes.key == "disabled") {
                        if ( p._cdata) {
                            input.disabledState = p._cdata;
                        }
                    }
                    if (p._attributes.key == "text") {
                        if ( p._cdata) {
                            console.log(p);
                            input.ngValue = p._cdata;
                        }
                    }
                });

            } else if (input['_attributes']['type'] == "dateInput") {
                input.label = this.findClolestLabel(input, actionLayout.resources);
                actionLayout.inputs.push(input); 
                // console.log(input);

            } else if (input['_attributes']['type'] == "select") {

                input.label = this.findClolestLabel(input, actionLayout.resources);
                actionLayout.inputs.push(input); 

            } else if (input['_attributes']['type'] == "checkbox") {
                input.parameter.forEach( p => {
                    if (p._attributes.key == "valueIfNotSelected")
                        input.valueIfNotSelected = p._cdata;
                    if (p._attributes.key == "valueIfSelected")
                        input.valueIfSelected = p._cdata;
                    
                    if (p._attributes.key == "text")
                        input.text = p._cdata;
                });
                // input.label = this.findClolestLabel(input, actionLayout.resources);
                actionLayout.inputs.push(input); 
                // console.log(input);

            } else {
                console.log("Input type:" + input['_attributes']['type']);
            }
            // console.log(input);
        });
        console.log(actionLayout.inputs);
    }

    parseCargoActionLayoutControls(actionLayout, dataToParse:any) {

        var inputs = dataToParse.cargoInput;
        if (! Array.isArray(inputs)) {
            inputs = [inputs];   
        }
        var resources = dataToParse.cargoResource;
        if (! Array.isArray(resources)) {
            resources = [resources];   
        }
        this.parseActionLayoutResources(actionLayout, resources);
        this.parseActionLayoutInputs(actionLayout, inputs);
    }


    parseActionLayout(layout): any {

        // console.log(layout.layout);
        // console.log(layout.background.background);
        var backgroundColor = "#" + layout.background._attributes.r.toString(16);
        backgroundColor = backgroundColor + layout.background._attributes.g.toString(16);
        backgroundColor = backgroundColor + layout.background._attributes.b.toString(16);

        var actionLayout = {
            backgroundColor:backgroundColor,
            controlList:[],
            resources:[],
            inputs:[],
            dialogOkButton:null,
            dialogCancelButton:null

        }
        this.parseCargoActionLayoutControls(actionLayout, layout.page);

        actionLayout.inputs.sort((a, b) => {
            if (a.posY < b.posY) 
                return -1;
            else if (a.posY > b.posY) 
                return 1;
            else 
                return 0;
        });
        return actionLayout;
    }

    parseGrid(comp:any, jobData:any, first:boolean) {
        
        // var result = "";
        if (comp['_attributes'] && 'type' in comp['_attributes']) {
            comp.type = comp['_attributes'].type;
        }

        if (comp['@attributes'] && 'type' in comp['@attributes']) {
            comp.type = comp['@attributes'].type;
        }

        // this.parseJobAttributes(comp, jobData);

        //comp.children.component.forEach( child => {
        if (first) {
            // result = result + "<div class=\"col\">";
            // result = result +  + "\r\n";
        }
        // console.log("Parse grid");
        // console.log(comp);
        var gridData = comp.children.component;
        // console.log("Column count");
        // console.log(comp);
        this.nofColumnInGrid = parseInt(comp['columns']);
        this.nofRowsInGrid = parseInt(comp['rows']);
        // var comStr = 
        this.parseGridData(gridData, jobData, first);
        if (first) {
            // result = result + comStr + "</div>\r\n";
        }
        // result = result + comStr;
        // })
        // return result; //  + comStr;
    }

    parseCargoJobElem(comp:any, jobData:any, valign:string, halign:string, index:number) {
        
        // console.log(comp);
        if (comp['parameter']) {
            if (! Array.isArray(comp['parameter'])) {
                comp['parameter'] = [comp['parameter'] ]; 
            }
            // console.log("parseJobAttributes0");
            // console.log(comp);
            this.parseJobAttributes(comp, jobData);
        }

        // var result = ""; // "<p>" + comp.type + "</p>";
        // console.log(comp);
        if (comp['_attributes'] && 'type' in comp['_attributes']) {
            comp.type = comp['_attributes'].type;
        }
        // console.log(comp.type);

        if (comp.type == "grid") {
            // console.log("parse grid 2");
            // result = result + 
            this.parseGrid(comp, jobData, false);
        };
        if (comp.type == "saleswatch.openFormButton") {
            // console.log("saleswatch.openFormButton");
            // console.log(comp);
            this.parseJobAttributes(comp, jobData);
        }
        /*<component layoutUID="41c5fbce-380d-4fae-87a6-819861678b48" name="openCargoForm" type="saleswatch.openFormButton">
            <parameter key="enabledState" xmltype="false">always</parameter>
            <parameter key="visibility" xmltype="false">always</parameter>
            <parameter key="PUID" xmltype="false"/>
            <parameter key="visibilityCondition" xmltype="false"/>
            <parameter key="enabledStateCondition" xmltype="false"/>
            <parameter key="text" xmltype="false">Upload to all selected...</parameter>
            <parameter key="styleVariant" xmltype="false">contained</parameter>
            <parameter key="colorVariant" xmltype="false">inherit</parameter>
        </component>*/
        if (comp.type == "gridData" || comp.type == "gridCell") {
            var gridData = comp.children.component;
            // console.log("Griddata");
            // console.log(gridData);
            // console.log(comp);

            if (comp.rowIndex == 0 && this.nofColumnInGrid > 1) {
                // result = result + "<div class=\"add1 col\">";
                // result = result + "\r\n";

                if (comp.columnIndex == 0) {
                    // result = result + "<div class=\"add1 row\">";
                    // result = result + "\r\n";
                }
            }
            if (gridData) {
                var comstr = "";
                if (Array.isArray(gridData)) {
                    // console.log("parse array")
                    // comstr = 
                    this.parseGridData(gridData, jobData, false);
                } else {
                    // console.log("parse not array")
                    // comstr = 
                    // console.log("this.parseCargoJobElem 1")
                    // console.log(gridData)
                    this.parseCargoJobElem(gridData, jobData, valign, halign, 0 );
                }
                // result = result + comstr;
            }
            if (comp.rowIndex == 0 && this.nofColumnInGrid > 1) {
                
                /*if (comp.columnIndex == this.nofColumnInGrid - 1) {
                    result = result + "</div>";
                    result = result + "\r\n";
                }
                result = result + "</div>\r\n";
                */
            }

        }
        // console.log(comp);
        if (comp['_attributes'].type == "saleswatch.openFormButton") {
            // console.log(comp);
            // console.log("---- open form button -----")
            this.parseJobAttributes(comp, jobData);
            // console.log(comp);
            jobData.openFormButton = comp;
        }

        if (comp['_attributes'].type == "moreButton") {
            jobData.moreButton = comp;
        }
        if (comp['_attributes'].type == "downloadFileButton") {
            jobData.downloadFileButton = comp;
            jobData.downloadButton = comp;
        }
        if (comp['_attributes'].type == "infoButton") {
            jobData.infoButton = comp;
        }

        if (comp.type == "image" || comp.type == "imagePreformat") {
            // console.log("image");
            // console.log(comp);
            if (comp.image_type == "fileThumbnail") {
                comp.fileThumbnail = true;
                // result = result + "<img class=\"card-img-top mb-3\"";
                // result = result + " src=\"" + jobData.thumbnailURI + "\" alt=\"" + jobData.thumbnailURI + "\">";
                // result = result + "\r\n";
            }

            if (comp.image_type == "publicURL") {
                comp.fileThumbnail = false;
                comp.url = comp.parameter[0]._text;
                var r ="&nbsp;&nbsp;<img style=\margin-left:5px; margin-right:5px;\" class=\"\""; 
                r = r + " src=\"" + comp.url + "\" alt=\"url\">&nbsp;&nbsp;";

                // result = result + r + "\r\n";

                if (index == 0) {
                    jobData.dataList.push("<div>" + r + "</div>");
                    // console.log("1");
                } else {
                    jobData.dataList[jobData.dataList - 1] = jobData.dataList[jobData.dataList - 1] + r;
                    // console.log("1");
                }

                if (index == 0) {
                    jobData.jobInfoData[jobData.jobInfoData.length-1].value = r;
                    // console.log("3");
                } else {
                    // console.log("set 3");
                    // console.log(comp);
                    jobData.jobInfoData[jobData.jobInfoData.length-1].value = "<div>" +
                    jobData.jobInfoData[jobData.jobInfoData.length-1].value + r + "</div>";    
                    // console.log("4");
                }
            }
        }
        // console.log(comp.type);
        if (comp.type == "label" || comp.type == 'labelPreformat') {
            
            // result = result + "<p>" + comp.ngValue + "</p>";
            // console.log(comp);

            if (comp.css_class.indexOf("header") >= 0) {
                // console.log(comp)
                // console.log("push to headerlist");
                jobData.headerList.push(comp.ngValue);
                // console.log(comp);
                // jobData.jobInfoData.push(elem)
                /*result = result + "<div (click)=\"context.handleTextClick('" + comp.text + "')\" class=\"\">"; 
                var sortstr = '<span (click)="context.toggleSortDirection()" class="mr-1 ion-ios-arrow-dropdown"';
                sortstr = sortstr + ' *ngIf="context.userPreferences.sortOrder.decending && context.userPreferences.sortOrder.currentSortOrder == '
                sortstr = sortstr + "'" + comp.text + "'" + '"></span>';
                sortstr = sortstr + '<span (click)="context.toggleSortDirection()" class="mr-1 ion-ios-arrow-dropup" ';
                sortstr = sortstr + ' *ngIf="! context.userPreferences.sortOrder.decending && context.userPreferences.sortOrder.currentSortOrder == ';
                sortstr = sortstr + "'" + comp.text + "'" + '"></span>';
                result = result + "<div id=\"test\" class=\"sortorder text-muted small\">" + sortstr + comp.ngValue + "</div>";
                */

                var elem = {
                  title:comp.ngValue,
                  value:"" 
                };
                if (! elem.title)
                    elem.title = "";

                //console.log("add element 2");
                //console.log(elem);
                //console.log(comp);
                //if (elem.title != "" || elem.title != "")
                jobData.jobInfoData.push(elem);

            } else if (comp.css_class.indexOf("result") >= 0) {
                // console.log(comp);
                // result = result + comp.ngValue;
                // result = result + "</div>\r\n";
                if (! comp.ngValue)
                    comp.ngValue = "";

                // console.log("push to dataList");
                if (index == 0) {
                    var htmlStr = comp.ngValue;
                    if (comp.ngValue.indexOf("@") > 0) {
                        htmlStr = "<a href = \"mailto:" + comp.ngValue + "\">" + comp.ngValue + "</a>";
                    }
                    // console.log("set 5");
                    // console.log(comp);
                    jobData.jobInfoData[jobData.jobInfoData.length-1].value = htmlStr;
                } else {
                    var htmlStr = comp.ngValue;
                    if (comp.ngValue.indexOf("@") > 0) {
                        htmlStr = "<a href = \"mailto:" + comp.ngValue + "\">" + comp.ngValue + "</a>";
                    }
                    // console.log("set 1");
                    // console.log(comp);
                    jobData.jobInfoData[jobData.jobInfoData.length-1].value =
                    jobData.jobInfoData[jobData.jobInfoData.length-1].value + htmlStr;    
                }

                // console.log("push to dataList:" + index);
                if (index == 0) {
                    if (comp && comp.ngValue && comp.ngValue.indexOf("@") >= 0) {
                        // var html = "<a href = \"mailto: abc@example.com\">" + comp.ngValue + "</a>";
                        var html = "<a href = \"mailto:" + comp.ngValue + "\">" + comp.ngValue + "</a>";
                        jobData.dataList.push(html);
                    } else {
                        jobData.dataList.push(comp.ngValue);
                    }
                } else {
                    if (comp && comp.ngValue && comp.ngValue.indexOf("@")) {
                        var html = "<a href = \"mailto:" + comp.ngValue + "\">" + comp.ngValue + "</a>";
                        jobData.dataList[jobData.dataList.length - 1] = 
                            jobData.dataList[jobData.dataList.length - 1] + html;
                    } else {
                        jobData.dataList[jobData.dataList.length - 1] = 
                        jobData.dataList[jobData.dataList.length - 1] + comp.ngValue;
                    }
                }
            } else {
                // console.log(comp);
                // result = result + '<div class=\"'; // >";
                
                /*let str = "";
                if (halign == "left") 
                    str = str + 'text-left ';
                if (halign == "center") 
                    str = str + 'text-center ';
                if (halign == "right") 
                    str = str + 'text-right ';
                    
                if (valign == "top") 
                    str = str + 'align-top';
                if (valign == "center") 
                    str = str + 'align-middle';
                if (valign == "bottom") 
                    str = str + 'align-bottom';
                str = str + '">';
                result = result + str;

                if (comp.ngValue) {
                    result = result + comp.ngValue;
                } else if (comp.text) {
                    result = result + comp.text;    
                }
                */


                // console.log(comp);
                if (jobData.addTitle) {
                    var elem = {
                        title:comp.ngValue,
                        value:"" 
                    };
                    if (! elem.title)
                        elem.title = "";
                    //console.log("add element 1");
                    //console.log(comp);
                    //console.log(elem);
                    //if (elem.title != "" || elem.title != "")
                    jobData.jobInfoData.push(elem);

                    jobData.addTitle = false;
                    // console.log("jobData.addTitle 1 " + jobData.addTitle)
                } else {
                    var htmlString = "";
                    if (comp.ngValue) {
                        htmlString = comp.ngValue;
                        if (htmlString.indexOf("@") > 0) {
                            htmlString = "<a href = \"mailto:" + comp.ngValue + "\">" + comp.ngValue + "</a>";
                        }
                        // console.log("set 2");
                        // console.log(comp);
                    }
                    jobData.jobInfoData[jobData.jobInfoData.length - 1].value = htmlString;
                    jobData.addTitle = true;
                    //console.log("jobData.addTitle 2 " + jobData.addTitle)
                }
                // console.log(jobData.jobInfoData);
                // result = result + "</div>\r\n";
            }
        }
        // console.log("parseCargoJobElem:" + result);
        // console.log(comp);
        // return result;
    }


    getValueForColumn(child: any, valign:string, halign:string, jobData:any) {
        
        // var res = "";
        if (child) {
            // this.parseJobAttributes(child, jobData);
            // console.log(child);
            if (child) {
                // console.log("column index:" + child.columnIndex);
                // var jobData = this.createJobData();
                // res = res + 
                // console.log("this.parseCargoJobElem 2")
                // console.log(child)
                this.parseCargoJobElem(child, jobData, valign, halign, 0);
            }
            // var res = "column:" + child.columnIndex;
            // var res = res + " row:" + child.rowIndex;
        }
        //return res;
    }


    parseMultiColumnGrid(comp:any, jobData:any) {
        
        // var res = "<div class=\"row\">\r\n";
        // var cols:string[] = [];

        // console.log(comp);

        /*for(let cIndex = 0; cIndex < this.nofColumnInGrid; cIndex ++) {
            var colsStr = "<div class=\"col\">\r\n";   
            cols.push(colsStr);
        }
        */
        var count = this.nofColumnInGrid * this.nofRowsInGrid;
        for(let index = 0; index < count; index ++) {
            var child = comp[index];
            /*console.log("index:" + index);
            console.log("child");
            console.log(child);*/
            // this.parseJobAttributes(child, jobData);
            // KUKEN this.parseJobAttributes(child, jobData)
            // console.log(child);
            // var colIndex = index % this.nofColumnInGrid;
            var cmp = child.children.component;
            if (cmp) {
                //  KUKEN this.parseJobAttributes(cmp, jobData)
                // console.log(cmp);
                // var str = ''; //<span ';
                // horizontalk alignment
                // str = str + '"';
                // str = str + 
                /*console.log("this.getValueForColumn");
                console.log(comp);
                console.log(cmp);*/
                if (isArray(cmp)) {
                    cmp.forEach(cCmp => {
                        this.getValueForColumn(cCmp, child.valign, child.halign, jobData);
                    })
                } else {
                    this.getValueForColumn(cmp, child.valign, child.halign, jobData);   
                }
                // cols[colIndex] = cols[colIndex] + str;
            }
        }
        /*
        for(let cIndex = 0; cIndex < this.nofColumnInGrid; cIndex ++) {
           res = res + cols[cIndex] + "</div>\r\n";
        }
        res = res + "</div>\r\n";
        // console.log(res);
        return res;
        */
    }


    parseGridData(comp:any, jobData:any, first:boolean) {
        
        // var result = "";

        /*console.log("Parse grid data");
        console.log(comp);
        console.log(first);
        */
       
        if (Array.isArray(comp)) {
            // console.log(comp);
            if (this.nofColumnInGrid > 1) {
                // result = result + "<div class=\"container\">\r\n";
                // result = result + 
                this.parseMultiColumnGrid(comp, jobData);
                // result = result + "</div>\r\n";
            } else {
                if (! first && this.nofColumnInGrid > 1) {
                    // result = result + "<div class=\"container\">\r\n";
                }
                var count = 0;
                comp.forEach(child => {
                    // console.log("handle children");
                    if (first) {
                        // result = result + "<div class=\"test1 col\">";
                        // result = result +  + "\r\n";
                    } else {
    
                    }
                    // var comStr = 
                    // console.log("this.parseCargoJobElem 3")
                    // console.log(child)
                    this.parseCargoJobElem(child, jobData, "", "", count);
                    // result = result + comStr;
                    if (first) {
                        //result = result + "</div>\r\n";
                    } else {

                    }
                    count = count + 1;
                });
                if (! first && this.nofColumnInGrid > 1) {
                    //result = result + "</div>\r\n";
                }
            }
        } else {
            comp.children.component.forEach(child => {
                // console.log(child);
                // console.log(comp);

                // console.log("parseJobAttributes1");
                // console.log(child);
                
                this.parseJobAttributes(child,jobData);
                // console.log(child);
                // result = result + "<div class=\"col\">";
                /*var tagToAdd = "<div ";
                if (child.css_class.indexOf("col-") >= 0) {
                    tagToAdd = tagToAdd + 'class="' + child.css_class + '">\r\n';
                } else {
                    tagToAdd = tagToAdd + 'class="col "' + child.css_class + '">\r\n';
                }
                if (first) {
                    // result = result + "<div class=\"test2 col\">";
                    // result = result + "\r\n";
                } else {
                    if (comp.columnIndex == 0 && this.nofColumnInGrid > 1) {
                        result = result + "<div class=\"row\">";
                    }
                }
                */
                // result = result + "\r\n";
                // var comStr = 
                // console.log("this.parseCargoJobElem 4")
                // console.log(child)
                this.parseCargoJobElem(child, jobData, "", "", 0);
                // result = result + comStr; //  + "</div>\r\n";
                
                /*
                if (comStr == "") {
                    // console.log("do not add column");
                    // console.log(result);
                    // result = result.replace("<div class=\"test2 col\">", "");
                } else {
                    result = result + tagToAdd + comStr; 
                }
                if (first) {
                    if (comStr != "") {
                        result = result + "</div>\r\n";
                    }
                } else {
                    if (comp.columnIndex == 0 && this.nofColumnInGrid > 1) {
                        result = result + "</div>\r\n";
                    }
                }
                */
            });
        }
        // console.log(result);
        // return result;
    }


    parseCargoJobDataNew(comp:any, jobData:any) {

        // var result = "";
        comp.type = "";

        // console.log(comp);
        if (comp['_attributes'] && 'type' in comp['_attributes']) {
            comp.type = comp['_attributes'].type;
        }
        if (comp['@attributes'] && 'type' in comp['@attributes']) {
            comp.type = comp['@attributes'].type;
        }

        if (comp.type == "grid") {
            // result = result + 
            this.parseGrid(comp, jobData, true);
            // console.log("after grid:" + result);
        };

        if (comp.type == "gridData" || comp.type == "gridCell") {
            // result = result + 
            this.parseGridData(comp, jobData, true);
            // console.log("after grid data:" + result);
        };
        // console.log(result);
        // return result;
    }


    createJobData():any {

        var jobData = {
            controlList:[],
            dataList:[],
            headerList:[],
            actionList:[],
            downloadButton:null,
            moreButton: null,
            cargoFromButton:null,
            infoButton:null,
            // html:"",
            filesURL:"",
            nofFiles:0,
            jobInfoData:[],
            addTitle:true
        }
        return jobData;
    }
    parseCargoJobLayout(job:any) {

        var jobData = this.createJobData();
        var parent = job.layout.pages.page.componentList.component;
        // var result = result + 
        this.parseCargoJobDataNew(parent, jobData); 
        return jobData;
    }


    parseCargoJobNew(job:any, details:boolean) {
        
        // console.log(job);
        // console.log(job.previewURI._cdata);
        var preview = "";
        var download = "";
        var thumbnail = "";
        var actionList =[];

        // this.htmlString = "";

        if (job.actionList && job.actionList.actionItem) {
            if (! Array.isArray(job.actionList.actionItem)) {
                actionList = [job.actionList.actionItem];
                // console.log("not array");
            } else {
                actionList = job.actionList.actionItem;
            }
        }

        if (job.previewURI && job.previewURI._cdata)
            preview = job.previewURI._cdata;

        if (job.thumbnailURI && job.thumbnailURI._cdata)
            thumbnail = job.thumbnailURI._cdata;

        if (job.downloadURI && job.downloadURI._cdata)
            download = job.downloadURI._cdata;

        var jobData = this.createJobData();
        jobData.id = job._attributes.id;
        jobData.connectId = job._attributes.connectID;
        jobData.previewURI = preview;
        jobData.thumbnailURI = thumbnail;
        jobData.downloadURI = download;
        jobData.actionList = actionList;

        var parent = null;
        // console.log(job);

        if (details) {
            parent = job.detailViewLayout.layout.pages.page.componentList.component;
        } else {
            parent = job.resultViewLayout.layout.pages.page.componentList.component;
        }
        // console.log(parent);

        /*var result = "<div class=\"container\">";
        result = result + "\r\n";
        result = result + "<div class=\"row\">";
        result = result + "\r\n";
        */

        // var controlList = [];
        // var headerList = [];
        // var dataList = [];
        // result = result + 
        this.parseCargoJobDataNew(parent, jobData); //controlList, headerList, dataList);
        // result = result + "</div>\r\n";
        // result = result  + "</div>\r\n";

        // jobData.html = result;
        // console.log(result);
        // jobData.controlList = controlList;
        // jobData.dataList = dataList;
        // jobData.headerList = headerList;
        // console.log(jobData);
        // console.log(job.resultViewLayout.layout.pages.page.componentList.component);
        return jobData;
    }
}

// my-bootstrap-modal.component.ts
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'cargo-form-dialog-component',
  templateUrl: './cargo-form-dialog-component.html',
  styleUrls: ['./cargo-form-dialog-component.css']
})

export class CargoFormsModalDialogComponent implements OnInit {

  @Input() fromParent;

  public iFrameUrl:SafeResourceUrl;
  // @ViewChild('cargoiframe', { static: true }) cargoiframe: ElementRef;
  // @ViewChild('iframe', { static: true }) iframe: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private translate:TranslateService,
    private sinitizer:DomSanitizer,
  ) { 
    // console.log("constructor");
    // console.log(this.iframe);
    // console.log(this.cargoiframe);
 
    // let iframe = document.getElementById('iframe'); 
    // console.log(iframe);
    // console.log(cargoiframe);

    /*let doc2 = this.iframe.nativeElement.contentDocument; //  || this.iframe.nativeElement.contentWindow;
    console.log(doc2);
    if (typeof doc2.addEventListener !== undefined) {
      doc2.addEventListener("message",(event) => {
        if (event.data && event.data.message_id === "cargo_submit") {
            // här vet du att cargoformuläret har skickats, du kan nu stänga den modala dialogen med iframe i.
        }
      },false);
    } else if (typeof doc2.attachEvent !== undefined) {
      // doc.attachEvent("onclick", this.iframeClickHandler);
    }
    console.log(iframe);
    */
  }

  
  ngAfterViewInit() {
    
    console.log("ngAfterViewInit");
    // console.log(this.iframe);
    /*let doc2 = this.iframe.nativeElement.contentDocument; //  || ;
    if (typeof doc2.addEventListener !== undefined) {
      console.log(doc2);
      console.log("add listener contentDocument");
      doc2.addEventListener("message",(event) => {

        console.log("event1");
        console.log(event);
        if (event.data && event.data.message_id === "cargo_submit") {
            // här vet du att cargoformuläret har skickats, du kan nu stänga den modala dialogen med iframe i.
            console.log("form ok 1");
            this.activeModal.close(this.fromParent);
        }
      },false);
    } else if (typeof doc2.attachEvent !== undefined) {
      // doc.attachEvent("onclick", this.iframeClickHandler);
    }
    */

    /*var doc3 = this.iframe.nativeElement.contentWindow;
    if (typeof doc3.addEventListener !== undefined) {
      console.log(doc3);
      console.log("add listener contentWindow");
      doc3.addEventListener("message",(event) => {
        console.log("event2");
        console.log(event);

        if (event.data && event.data.message_id === "cargo_submit") {
            // här vet du att cargoformuläret har skickats, du kan nu stänga den modala dialogen med iframe i.
            console.log("form ok 2");
            this.activeModal.close(this.fromParent);
        }
      },false);
    }
    */
    /*const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement;
    const iframe = element.contentWindow;
    if (iframe !== null) {
      console.log(iframe);
      iframe.addEventListener("message",(event) => {
        console.log("event4");
        console.log(event);
  
        if (event.data && event.data.message_id === "cargo_submit") {
            // här vet du att cargoformuläret har skickats, du kan nu stänga den modala dialogen med iframe i.
            console.log("form ok 4");
            this.activeModal.close(this.fromParent);
        }
      },false);
    }
    */

   window.addEventListener("message",(event) => {
      console.log(event.data.message_id);
      console.log(event.data);
      if (event.data && event.data.message_id === "cargo_submit") {
        // här vet du att cargoformuläret har skickats, du kan nu stänga den modala dialogen med iframe i.
        console.log("Close modal");
        this.activeModal.close(this.fromParent);
      }
      /*if (event.data && event.data.message_id === "cargo_error_change") {
       
      }
      if (event.data && event.data.message_id === "cargo_value_change") {
       
      }
      */
    },false);
    
  }

  ngOnInit() {
    
    console.log(this.fromParent);
    var url = this.fromParent.iFrameUrl + "&keepSessionAlive=true";
    this.iFrameUrl = this.sinitizer.bypassSecurityTrustResourceUrl(url);
    /*window.addEventListener('message', function(event) {
      // console.log("Message received from the child: " + event.data); // Message received from child
      console.log(event.data.message_id + "=>" + event.data)
      if (event.data && event.data.message_id === "cargo_submit") {
        console.log("form ok 3");
        // this.activeModal.close(this.fromParent);
        // this.activeModal.close(this.fromParent);
      }
    });
    */
  }


  closeModal(sendData) {
    // this.fromParent.resultCode = sendData;
    this.activeModal.close(this.fromParent);
  }

}
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from "@angular/common/http";
// import { TypeAheadModule } from 'ng2-bootstrap-typeahead/src/typeahead.module';

// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';

import { LoginComponent } from './login/login.component';
import { ConnectionService } from './services/connection.service';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard.service';
import { LayoutNavbarComponent } from './layout/layout-navbar/layout-navbar.component';
import { CardViewComponent } from './subviews/card-view/card-view.component';
import { SmallCardViewComponent } from './subviews/small-card-view/small-card-view.component';
// import { FormsModule } from '@angular/forms';
//m  import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CargoActionModalComponent } from './subviews/ActionDialog/cargo-action-dialog-component';
import { CargoFormsModalDialogComponent } from './subviews/CargoFormDialog/cargo-form-dialog-component';

import { CargoDetailsModalDialogComponent } from './subviews/cargo-details-dialog/cargo-details-dialog-component';
import { CargoFilesModalDialogComponent } from './subviews/cargo-files-dialog/cargo-files-dialog-component';
// import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { ChartsModule as Ng2ChartsModule} from 'ng2-charts';
// import { NgbdTypeaheadBasic } from './typeahead-basic';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { Ng2SmartTableModule } from 'ng2-smart-table';

// *******************************************************************************
//
import { NgChartistComponent } from './charts/ng-chartist/ng-chartist.component';
//import { NgxChartsComponent } from '..ngx-charts/ngx-charts.component';
//import { NgChartistComponent } from './ng-chartist/ng-chartist.component';
import { Ng2ChartsComponent } from './charts//ng2-charts/ng2-charts.component';
// import { NgxTrendComponent } from './charts/ngx-trend/ngx-trend.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { SafeHTMLPipe } from './Pipes/safehtml';
// import { CompileModule} from "p3x-angular-compile"
import { Ng2FileDropModule }  from 'ng2-file-drop';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FileUploadModule } from 'ng2-file-upload';
import { MessageService } from './services/message.service';
import {MatSelectModule} from '@angular/material/select';
// import {ReactiveFormsModule} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { NgxChartsComponent } from './charts/ngx-charts/ngx-charts.component';
import { APP_BASE_HREF } from '@angular/common';
import { MyDateAdapter } from './services/MydateAdapter';
import { ActionsButtonViewComponent } from './home/actions-button-component';
import { SelectCheckboxViewComponent } from './home/select-checkbox-component';

import { ConditionalExpr } from '@angular/compiler';
import {MatPaginatorModule} from '@angular/material/paginator';
import { CookieService } from 'ngx-cookie-service';
import { NgxGalleryModule } from 'ngx-gallery';
import { GALLERY_CONFIG, GalleryConfig, GalleryModule } from 'ng-gallery';
import {IvyCarouselModule} from 'angular-responsive-carousel';
// import { APP_ROUTER_PROVIDERS } from './app.routes';
// import { HTTP_PROVIDERS } from '@angular/http';
import { Location, LocationStrategy, HashLocationStrategy} from '@angular/common';

import { DirectLinkComponent} from '../app/subviews/directlink/directlink.component';
import { CargoDetailsComponent } from './subviews/cargo-details-dialog/cargo-details-component';
import { DispatchHomeComponent } from './dispatch-home/dispatch-home.component';
import { DispatchActionsButtonViewComponent } from './dispatch-home/dispatch-actions-button-component';
import { DispatchSelectCheckboxViewComponent } from './dispatch-home/dispatch-select-checkbox-component';
import { AgGridModule } from 'ag-grid-angular';
import { AngularPaginatorModule } from 'angular-paginator';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
   url: 'https://httpbin.org/post',
   maxFilesize: 50,
   acceptedFiles: 'image/*'
 };

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    // Pages
    HomeComponent,
    Page2Component,
    LoginComponent,
    CardViewComponent,
    SmallCardViewComponent,
    CargoActionModalComponent,
    CargoDetailsModalDialogComponent,
    CargoFilesModalDialogComponent,
    // NgxImageGalleryComponent,
    NgChartistComponent,
    Ng2ChartsComponent,
    SafeHTMLPipe,
    // NgxTrendComponent,
    NgxChartsComponent,
    ActionsButtonViewComponent,
    SelectCheckboxViewComponent,
    DirectLinkComponent,
    CargoFormsModalDialogComponent,
    CargoDetailsComponent,
    DispatchHomeComponent,
    DispatchActionsButtonViewComponent,
    DispatchSelectCheckboxViewComponent,
  ],

  imports: [
    BrowserModule,
    NgbModule,

    // App
    AppRoutingModule,
    LayoutModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // TypeAheadModule,
    // AutocompleteLibModule,
    // ChartsModule,
    NgxChartsModule,
    Ng2SmartTableModule,
    ChartistModule,
    Ng2ChartsModule,
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxImageGalleryModule,
    DropzoneModule,
    FileUploadModule,
    // Ng2FileDropModule,
    // 
    // CompileModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    // NgxDropzoneModule,
    MatNativeDateModule,        // <----- import for date formating(optional)
    MatPaginatorModule,
    NgxGalleryModule,
    GalleryModule,
    IvyCarouselModule,
    AgGridModule,
    AngularPaginatorModule,
    // MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
  ],
  exports: [
    Ng2ChartsModule,
    TranslateModule,
    CargoActionModalComponent,
    CargoFormsModalDialogComponent,
    // NgxDropzoneModule,
    FileUploadModule,
    GalleryModule
    // Ng2FileDropModule
  ],

  entryComponents:[
    CargoActionModalComponent,
    CargoDetailsModalDialogComponent,
    CargoFilesModalDialogComponent,
    ActionsButtonViewComponent,
    DispatchActionsButtonViewComponent,
    SelectCheckboxViewComponent,
    DispatchSelectCheckboxViewComponent,
    CargoFormsModalDialogComponent
  ],

  providers: [
    Title,
    AppService,
    ConnectionService,
    AuthGuardService,
    MessageService,
    SafeHTMLPipe,
    CookieService,
    {
      provide: APP_BASE_HREF,
      useValue: window['base-href']
    },{
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: DateAdapter,
      useClass: MyDateAdapter
   },
   {  provide: LocationStrategy, 
      useClass: HashLocationStrategy
    }
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
/*export class AppModule { 
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('se-SE');
  }
}*/

export function HttpLoaderFactory(http: HttpClient) {

  // console.log(http);
  // console.log(window.location.pathname);
  // console.log("Base ref:" + window['base-href']);
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/');
}


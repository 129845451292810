import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

//import { OrderApiService}  from 'src/app/services/app.service
// import { StorageHelperService} from 'src/app/services/storage-helper.service';
// import {TranslationService } from 'src/app/services/translation.service';

// import { LoginApiService } from 'src/app/services/api/login.api.service';
// import { AppCacheService } from 'src/app/services/app-cache.service';
import Swal from 'sweetalert2';
import { isJsxAttributes } from 'typescript';
import { ConnectionService } from '../services/connection.service';
import { environment } from 'src/environments/environment';

// import { PortalType } from '..'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../../vendor/styles/pages/contacts.scss',
    '../../vendor/styles/pages/authentication.scss'
  ]
})
export class LoginComponent implements OnInit {
  // portalType: PortalType = PortalType.Private;
  public isLoadingData: boolean = false;
  public configData:any = {};
  // public configXMLData:any;

  private username = "";
  private password = "";
  private _canDeleteFiles:boolean = false;
  private loginData;
  public errorMessage:string;
  private placeHolder;
  // private portalType: number = 1;
  // public currentApplicationVersion:string = environment.appVersion;
  public majorAppVersion:string = environment.majorAppVersion;

  public model: any;

  /*search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    
  keyword = 'name';
  data = [
     {
       id: 1,
       name: 'Usa'
     },
     {
       id: 2,
       name: 'England'
     }
  ];
  */
  viewMode = 'col';

  selectEvent(item) {
    // do something with selected item
  }

  

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  

  reSortList() {
    console.log("resourt list");
  }

  
  onFocused(e){
    // do something when input is focused
  }

  constructor(
    // private loginApiService: LoginApiService,
    // public appCacheService: AppCacheService,
    // private storageHelperService: StorageHelperService,
      private connectionService: ConnectionService,
      private activatedRoute: ActivatedRoute,
    // private translate: TranslationService,
    // private orderApiService: OrderApiService,
      private router: Router
    ) {
    // this.portalType = 1; // bthis.userService.getPortalType();
  }

  credentials = {
    username:"",
    email: '',
    password: ''
  };

  CustomerInfo = {
    fullname: '',
    email: '',
    phonenumber: ''
  }
  queryParamsToSend = {};
  // public currentApplicationVersion:string;

  ngOnInit () {

    var url = window.location.href;
    this.connectionService.originalUrl = url;

    console.log("login");
    this.errorMessage = "";

    // this.currentApplicationVersion = environment.appVersion;
    var array = environment.appVersion.split(".");
    // this.currentApplicationVersion = array[0] + "." + array[1] + " build " + array[2]; 
    // var versionstr = array[0] + "." + array[1] + " build " + array[2]; 
    this.majorAppVersion = this.majorAppVersion + " build:" +  array[2];
    
    this.isLoadingData = true;
    this.getConfigDataXML(false);
  }


  checkForParameters() {

    this.queryParamsToSend = {};
    var url = window.location.href;
    const obj = this.activatedRoute.snapshot.queryParams;

    if (Object.keys(obj).length == 0) {
      var pos = url.indexOf("?");
      if (pos >= 0) {
        var end = url.indexOf("#");
        var querystr = url.substring(pos+1, end);
        var queryparams = querystr.split("&");

        var query = {};
        var paramsToForward = {};
        queryparams.forEach( pair => {
          var pp = pair.split("=");
          console.log(pp);
          if (pp[0] == "user") {
            this.credentials.username =  pp[1];
          } else if (pp[0]  == "pw") {
            this.credentials.password = pp[1];
          } else {
            this.queryParamsToSend[pp[0]] = pp[1];
          }
          query[pp[0]] = pp[1];
        })
        console.log(query);
      }
      if (this.credentials.username != "" && this.credentials.password != "") {
        this.cargoLogin();
      }
    } else {
      console.log(this.activatedRoute.snapshot.queryParams);
      console.log(obj);
      Object.keys(obj).forEach(key => {
        var value = obj[key];
        console.log(key + "=>" + obj[key]);

        if (key == "user") {
          this.credentials.username =  obj[key];
        } else if (key == "pw") {
          this.credentials.password = obj[key];
        } else {
          this.queryParamsToSend[key] = obj[key];;
        }
        console.log(key + " " + value);
      });
       if (this.credentials.username != "" && this.credentials.password != "") {
        this.cargoLogin();
      }
    }
  }


  getConfigDataXML(login:boolean): void {

    // console.log("get XML config data 1");
    var url = window.location.href;
    console.log("Current Url:" + url);
    if (url.indexOf("localhost:") >= 0) {
      url =  "http://test.laidbacksolutions.se:8080/cargo/cargoServlet";
    }
    /*if (url == "http://localhost:4200/#/") {
      this.configData.cargoURL = "https://cargo.demo.laidbacksolutions.se/cargo_mk/cargoServlet";
      this.connectionService.url = this.configData.cargoURL;
      this.configData.webViewId = 4;
      return;
    }
    */
    // this.configData.webViewId = 4;
    // this.connectionService.webViewId = "4";
    /*var len = url.indexOf("/sw/");
    this.configData.cargoURL = url.substring(0, len) + "/cargoServlet";
    console.log("Set cargo Url from path:" + this.configData.cargoURL);
    this.connectionService.url = this.configData.cargoURL;
    console.log(this.configData.cargoURL);
    */

    this.connectionService.getConfigXMLData().subscribe(
      configData => {
        console.log("get XML config data 2 --------------------------");
        console.log(configData);
        var url = window.location.href;
        console.log(url);
        this.isLoadingData = false;

        if (configData.salesWatchSetup.cargoURL) {
          console.log("Set cargo Url form file:");
          this.configData.cargoURL = configData.salesWatchSetup.cargoURL;
          this.connectionService.url = this.configData.cargoURL;
        } else {
          var len = url.indexOf("/sw/");
          this.configData.cargoURL = url.substring(0, len) + "/cargoServlet";
          console.log("Set cargo Url from path:" + this.configData.cargoURL);
          this.connectionService.url = this.configData.cargoURL;
          console.log(this.configData.cargoURL);
        }
        if (configData['salesWatchSetup'] && configData['salesWatchSetup']['@attributes'].id) {
          this.configData.webViewId = configData['salesWatchSetup']['@attributes'].id;
        }
        this.isLoadingData = false;
        // this.checkForParameters();
        // if (login)
        //    this.cargoLogin();
      }, error => {
          // var len = url.indexOf("/sw/");
          this.configData.cargoURL = environment.proxyCargoUrl; // "https://cargo.demo.laidbacksolutions.se/cargo/cargoServlet";
          console.log("Error Set cargo Url from path:" + this.configData.cargoURL);
          this.connectionService.url = environment.proxyCargoUrl; // "https://cargo.demo.laidbacksolutions.se/cargo_mk/cargoServlet";
          // console.log(this.configData.cargoURL);
          this.configData.webViewId = environment.proxyWebUserId;
          this.connectionService.webViewId = environment.proxyWebUserId;
          console.log("webViewId:" + this.connectionService.webViewId);
          this.isLoadingData = false;
         // this.checkForParameters();

          //if (login)
          //  this.cargoLogin();
      }
    );
    this.isLoadingData = false;

    /*this.connectionService.isLoggedIn().subscribe(
      result => {
        if (result) {
          // console.log("We are logged in _>");
          // this.router.navigate(['/saleswatch']);
        } else {
          // console.log("We are logged out");  
        }
    });
    */
    // console.log("get config data Done");
  }


  /*getConfigData(): void {

    // console.log("get config data");
    this.connectionService.getConfigData().subscribe(
      configData => {
          this.configData = configData
          // console.log(configData)
          this.getConfigDataXML();
      });
      /*this.connectionService.getConfigXMLData().subscribe(
        configData => {
          console.log(configData);
            // this.configXMLData = configData;
            this.configData.webViewId = configData.webViewId;
            console.log(configData)
      });
      */
      // console.log("get config data Done");
      // console.log(this.languageData);
  // }

  onSignIn() {
    /*this.loginApiService.login(this.credentials).subscribe(identity => {
      this.storageHelperService.addInSessionStorage("auth-user", identity);
      this.appCacheService.connectedCompanyList = JSON.parse(identity.customerNameOrNumber)
      if (this.appCacheService.connectedCompanyList.length === 1) {
        this.appCacheService.selectedConnectedCompany = this.appCacheService.connectedCompanyList[0];
        this.router.navigate(['/orders']);
      }
    })
    */
    this.cargoLogin();
  }


  validateEmail(mail) {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    var res = regexp.test(mail);
    console.log("validate email:" + res);
    return res;
  }



  /*
  onNextClick() {
    // let webuser: CreateWebUser = new CreateWebUser();

    webuser.userData = JSON.stringify(this.CustomerInfo);
    webuser.email = this.CustomerInfo.email;
    if (!this.validateEmail(this.CustomerInfo.email)) {
      var message = this.translate.get('step1.error.invalid_email');

      Swal.fire({
        icon: 'error',
        title: 'Cargo Selfservice',
        text: message,
      });
      return;
    }
    /*this.orderApiService.createWebUser(webuser).subscribe(reply => {
      this.storageHelperService.addInSessionStorage("auth-user", reply)
      this.appCacheService.email = reply.email
      if (reply != null) {
        console.log("Create webuser user reply");
      } else {
        console.log("reply == null");
      }
      this.router.navigate(['/new-order']);
    });
    
  }
  */

  cargoLogin() {

    this.isLoadingData = true;
    let form = new FormData();
    form.append("cargoRequest", "cargoLogin");
    form.append("user", this.credentials.username);
    form.append("pw", this.credentials.password);
    form.append("locale", "se");
    form.append("client", "external");
    this.errorMessage = "";
    // console.log(this.credentials);

    console.log("Cargo url");
    this.isLoadingData = true;
    console.log(this.configData);

    console.log(this.configData.cargoURL);
    this.connectionService.cargoLogin(this.configData.cargoURL, form).subscribe(
        loginData => {
            this.isLoadingData = false;
            console.log(loginData);
            this.loginData = loginData;
            // console.log(this.loginData.cargoResponse['cargoResponseHeader']['@attributes']);
            // console.log(this.loginData.cargoResponse['cargoResponseHeader']);
            // console.log(this.loginData.cargoResponse['cargoResponseBody']);
            // console.log(this.loginData.cargoResponse['cargoResponseHeader']['@attributes'].result);
            console.log(this.loginData.cargoResponse);
            if (this.loginData.cargoResponse['cargoResponseHeader']['_attributes'].result === "0") {

               let suid = this.loginData.cargoResponse['cargoResponseHeader']['suid']._text;
               // let userUuid = this.loginData.cargoResponse['cargoResponseHeader']['uuid'];
               // console.log(this.loginData.cargoResponse['cargoResponseHeader']['@attributes']);
                // console.log(this.loginData.cargoResponse['cargoResponseHeader']['suid']);
                
                // var suid = this.loginData.cargoResponse['cargoResponseBody'].session['@attributes'].suid;
                // console.log(suid);
                // this.placeHolder.cargoSUID = suid;
                // localStorage.setItem("cargoPlaceHolder", JSON.stringify(this.placeHolder));
                var realName = this.loginData.cargoResponse['cargoResponseBody'].UserInfo['_attributes'].name;
                var canDeleteFiles = this.loginData.cargoResponse['cargoResponseBody'].UserInfo['_attributes']['allow-delete-file'];
                // alert(canDeleteFiles);

                // this.connectionService.userUuid = userUuid;
                this.connectionService.suid = suid;
                this.connectionService.username = realName; // this.credentials.username;
                this.connectionService.canDeleteFiles = canDeleteFiles == "true";

                this.connectionService.setLoginCredentials(realName, suid);
                this.isLoadingData = false;

                this.router.navigate(['/saleswatch'], {queryParams:this.queryParamsToSend});

                // this.readDataFromDB();'
                // this.isLoadingData = false;
                // console.log(this.placeHolder);
            } else {
              console.log(this.loginData.cargoResponse.notificationList.notification.title._cdata);
              console.log(this.loginData.cargoResponse.notificationList.notification.message._cdata);

              if (this.loginData.cargoResponse.notificationList.notification.title._cdata)
                this.errorMessage = this.loginData.cargoResponse.notificationList.notification.title._cdata;
              if (this.loginData.cargoResponse.notificationList.notification.message._cdata)
                this.errorMessage = this.errorMessage + "<br>" +
                  this.loginData.cargoResponse.notificationList.notification.message._cdata;
              
                // "Fel användarnamn eller lösenord!";
            }
            // console.log(loginData);

        }, error => {
          console.log(error);
          this.isLoadingData = false;
          this.errorMessage = "Inloggningen misslyckades!" + "\r\n" +
            error.message;
        });
  }
  
  /*onOptionsSelected() {
    this.router.navigate(['/']);
  }

  forgotPassword() {
    if (this.appCacheService.userDetails.email) {
      this.loginApiService.forgotPassword(this.appCacheService.userDetails.email).subscribe(
        reply => {
          console.log("Forgot password replay");
        });
    }
  }
  */

}

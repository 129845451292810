import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomCard } from '../../models/custom-card';
// import { CreateOrderService } from 'src/app/services/create-order.service';

@Component({
  selector: 'app-small-card-view',
  templateUrl: './small-card-view.component.html'
})
export class SmallCardViewComponent implements OnInit {
  private _data: CustomCard[] = [];
  private _selected!: CustomCard;

  @Input()
  set selected(data: CustomCard) {
    this._selected = data;
  }
  get selected(): CustomCard { return this._selected; }

  @Input()
  set data(data: CustomCard[]) {
    this._data = data;
  }
  get data(): CustomCard[] { return this._data; }

  @Output() itemClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    // public createOrderService: CreateOrderService
    ) {  }

  ngOnInit(): void {
  }

  _itemClicked(data: CustomCard): void {
    if (data) {
      this.itemClicked.emit(data);
    }
  }
}

// my-bootstrap-modal.component.ts
import { Component, OnInit, Input, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
// import { GALLERY_CONF } from 'ngx-image-gallery';
// import { NgxImageGalleryComponent as otherModuleName, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";

import 'hammerjs';
import { NgxImageGalleryComponent, GALLERY_CONF } from 'ngx-image-gallery';
import { AppService } from 'src/app/app.service';
import { CargoJob } from 'src/app/models/CargoJob';
import { ConnectionService } from 'src/app/services/connection.service';
import { LayoutParserService } from 'src/app/services/layout-parser.service';
import Swal from 'sweetalert2';
import { createEmitAndSemanticDiagnosticsBuilderProgram } from 'typescript';
import { isArray } from 'util';
import { saveAs } from 'file-saver';

const Masonry = require('masonry-layout/dist/masonry.pkgd.js');

@Component({
  selector: 'cargo-files-dialog-component',
  templateUrl: './cargo-files-dialog-component.html',
  styleUrls: ['./cargo-files-dialog-component.css', 
  '../../../vendor/libs/ngx-image-gallery/ngx-image-gallery.scss']
})

export class CargoFilesModalDialogComponent implements OnInit,OnDestroy {

  // @ViewChild(NgxImageGalleryComponent, { static: false }) ngxImageGallery: any;
  @ViewChild('galleryContainer', { static: false }) galleryContainer: any;
  @ViewChild(NgxImageGalleryComponent, { static: false }) ngxImageGallery: NgxImageGalleryComponent;
  // @ViewChild(NgxImageGalleryComponent, { static: false }) ngxImageGallery: NgxImageGalleryComponent;

  @Input() fromParent;
  
  constructor(
    public activeModal: NgbActiveModal,
    private translate:TranslateService,
    private zone: NgZone,
    private appService: AppService,
    private connectionService:ConnectionService,
    private parserService:LayoutParserService,
    private modalService: NgbModal,
    // private ngxImageGallery: NgxImageGalleryComponent;
  ) { 
    this.isRTL = appService.isRTL;
    // this.filterByTag();
  }
  
  errorMessage:string = "";
  isRTL: boolean;
  curTag = 'all';
  messageString:string = "";
  fileToDelete: any;
  deletedFiles = false;

  imagesLoaded = 0;
  private masonry: any;
  private observer: any;
  images = []; /*
    { index:0, url: 'assets/img/bg/1.jpg', thumbnailUrl: 'assets/img/bg/1.jpg', altText: '', tag: 'nature' },
    { index:1, url: 'assets/img/bg/2.jpg', thumbnailUrl: 'assets/img/bg/2.jpg', altText: '', tag: 'car' },
    { index:2, url: 'assets/img/bg/3.jpg', thumbnailUrl: 'assets/img/bg/3.jpg', altText: '', tag: 'other' },
    { index:3, url: 'assets/img/bg/4.jpg', thumbnailUrl: 'assets/img/bg/4.jpg', altText: '', tag: 'other' },
    { index:4, url: 'assets/img/bg/5.jpg', thumbnailUrl: 'assets/img/bg/5.jpg', altText: '', tag: 'other' },
    { index:5, url: 'assets/img/bg/6.jpg', thumbnailUrl: 'assets/img/bg/6.jpg', altText: '', tag: 'nature' },
    { index:6, url: 'assets/img/bg/7.jpg', thumbnailUrl: 'assets/img/bg/7.jpg', altText: '', tag: 'nature' },
    { index:7, url: 'assets/img/bg/8.jpg', thumbnailUrl: 'assets/img/bg/8.jpg', altText: '', tag: 'car' },
    { index:8, url: 'assets/img/bg/9.jpg', thumbnailUrl: 'assets/img/bg/9.jpg', altText: '', tag: 'animal' },
    { index:9, url: 'assets/img/bg/10.jpg', thumbnailUrl: 'assets/img/bg/10.jpg', altText: '', tag: 'animal' },
    { index:10, url: 'assets/img/bg/11.jpg', thumbnailUrl: 'assets/img/bg/11.jpg', altText: '', tag: 'other' },
    { index:11, url: 'assets/img/bg/12.jpg', thumbnailUrl: 'assets/img/bg/12.jpg', altText: '', tag: 'other' },
    { index:12, url: 'assets/img/bg/13.jpg', thumbnailUrl: 'assets/img/bg/13.jpg', altText: '', tag: 'nature' },
    { index:13, url: 'assets/img/bg/14.jpg', thumbnailUrl: 'assets/img/bg/14.jpg', altText: '', tag: 'animal' },
    { index:14, url: 'assets/img/bg/15.jpg', thumbnailUrl: 'assets/img/bg/15.jpg', altText: '', tag: 'other' },
    { index:15, url: 'assets/img/bg/16.jpg', thumbnailUrl: 'assets/img/bg/16.jpg', altText: '', tag: 'other' }
  ];*/

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    imageBorderRadius: '0',
    showDeleteControl: false,
    showImageTitle: false,
    thumbnailSize:70
  };

  openGallery(index: number = 0) {
    // var gallery = new NgxImageGalleryComponent(NgxImageGalleryComponent, { static: false });
    // console.log(gallery);

    // console.log("open gallery");
    // console.log(this.ngxImageGallery);
    // console.log(index);
    this.ngxImageGallery.open(index);
  }

  /*deleteFileSelection() {
    this.deleteImage(this.fileToDelete);  
  }
  */

  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
      if (result == 'delete') {
        this.deleteImage(this.fileToDelete);
      }
      if (result == 'cancel') {
        
      }
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  
  ngOnInit() {
    // console.log(this.fromParent);
    var url = this.fromParent.jobData.filesURL;
    var job = this.fromParent.jobData;

    // console.log(url);
    this.getCargoJobImages(url, job);
    // this.cargoGetFilesForJob(this.fromParent.jobData);
    // this.images = this.fromParent.images;
    // this.images = this.fromParent.layoutData;
    /* Output:
     {prop1: "Some Data", prop2: "From Parent Component", prop3: "This Can be anything"}
    */
    
  }

  setData(file:any) {
    this.fileToDelete = file;
  }

  deleteImage(file:any) {

    var job = this.fromParent.jobData;
    this.connectionService.cargoDeleteJobFile(job, file).subscribe(res => 
    {
        // console.log(res);
        // this.isUploadingFile = false;
        var notificationList = res['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);
        var fileDeleteOk = this.translate.instant("main.file_delete_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {

          this.deletedFiles = true;

          // TODO find image
          this.images = this.images.filter(item => item.id !== file.id);
          // Update index in grid
          var count = 0;
          this.images.forEach( im => {
            im.index = count;
            count = count + 1;
          });

          // remove it
          var p1 = this.translate.instant("files_dialog.delete_file_part_1");
          var p2 = this.translate.instant("files_dialog.delete_file_part_2");
          this.messageString = p1 + file.name + p2;
          /*
          Swal.fire(
            'Cargo',
            p1 + file.name + p2
          );
          */

        } else {
          messages.forEach( m => {
            this.messageString = this.messageString +  m;
          }) 
        }
      },(err) => {  
        // this.isUploadingFile = false;
        console.log(err);
      }
    );
  }

  downLoadCargoFile(image:any) {

    /*console.log("download file");
    console.log(job);
    var filename = job.downloadURI;
    var needle = "downloadFilename=";
    var pos = filename.indexOf(needle);
    if (pos != -1) {
      filename = filename.substring(pos + needle.length,)
    } else {
      filename = job.id + ".pdf"; 
    }
    */
    // console.log("download file");
    this.connectionService.export(image.downloadURI).subscribe(
      data => saveAs(data, image.name));
  }


  showCargoFile(item: any){

    window.open(item.url, "_blank");
  }

  /*
  deleteCargoJobFile(job:CargoJob, file:any) {
    
    this.connectionService.cargoDeleteJobFile(job, file).subscribe(res => 
      {
        var notificationList = res['cargoResponse'].notificationList;
        var messages = this.parserService.parseNotificationList(notificationList);


        var fileUploadOk = this.translate.instant("main.file_upload_ok");
        if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {

          this.messageString = "Filen: " + file.name + " har tagit bort";
        }
      }
    );
  }
  */
  
 extractFileId(url:string):string {

    console.log(url);
    var res = "";
    var arr = url.split("fileID=");
    if (arr.length >= 2) {
      var str = arr[1];
      var i =0;
      while(str.charAt(i) >= '0' && str.charAt(i) <= '9' && i < str.length) {
        i = i + 1;
      }
      res = arr[1].substring(0, i);
    }
    // console.log(res);
    return res;
 }

 cargoGetFilesForJobNew(job:any) {

  this.connectionService.cargoGetFilesForJob(job).subscribe(
    searchReply => {
      console.log(searchReply); 

      var notificationList = searchReply['cargoResponse'].notificationList;
      var messages = this.parserService.parseNotificationList(notificationList);

      if (messages.length == 0) {
        var searchResult = searchReply['cargoResponse']['cargoResponseBody'];
        console.log(searchResult);
        if (! Array.isArray(searchReply.files.file)) {
          searchReply.files.file = [searchReply.files.file];
        }
        if (searchReply.files.file) {
          searchReply.files.file.forEach( f => {
            console.log(f);
            var image = {
              id:this.extractFileId(f._attributes.url),
              downloadURI:f._attributes.downloadURI,
              index:this.images.length, 
              name:f._attributes.name,
              url: f._attributes.url,
              thumbnailUrl: f._attributes.url, 
              altText: '', 
              tag: 'nature'
            }   
            this.images.push(image);
          }) 
        }
      }
    });
 }

 cargoGetFilesForJob(job:any) {

  this.connectionService.cargoGetFilesForJob(job).subscribe(
    searchReply => {
      console.log(searchReply); 
      var notificationList = searchReply['cargoResponse'].notificationList;
      var messages = this.parserService.parseNotificationList(notificationList);

      if (messages.length == 0) {
        var searchResult = searchReply['cargoResponse']['cargoResponseBody'];
        console.log(searchResult);
        if (! Array.isArray(searchReply.files.file)) {
          searchReply.files.file = [searchReply.files.file];
        }
        if (searchReply.files.file) {
          searchReply.files.file.forEach( f => {
            console.log(f);
            var image = {
              id:this.extractFileId(f._attributes.url),
              downloadURI:f._attributes.downloadURI,
              index:this.images.length, 
              name:f._attributes.name,
              url: f._attributes.url,
              thumbnailUrl: f._attributes.url, 
              altText: '', 
              tag: 'nature'
            }   
            this.images.push(image);
          }) 
        }
      }
    });
 }

  getCargoJobImages(url:string, job:CargoJob) {
    
    console.log("cargoGetFilesForJobNew");
    this.connectionService.cargoGetFilesForJobNew(job).subscribe(
      searchReply => {
        console.log(searchReply);

        if (searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file) {
          var files = searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file;
          console.log(files);
          if (! Array.isArray(files))
            files = [files];
          
          files.forEach(f => {
            var name = f._attributes.name;

            var url = this.connectionService.url + f.thumbnailURI._cdata;

            if (name.toLowerCase().indexOf(".docx") >= 0 || name.toLowerCase().indexOf(".doc") >= 0)
                url = "./assets/img/icons/doc.png";

              if (name.toLowerCase().indexOf(".txt") >= 0)
                url = "./assets/img/icons/txt.png";

              if (name.toLowerCase().indexOf(".zip") >= 0)
                url = "./assets/img/icons/zip.png";

              if (name.toLowerCase().indexOf(".xlsx") >= 0 || name.toLowerCase().indexOf(".xls") >= 0)
                url = "./assets/img/icons/xls.png";

              if (name.toLowerCase().indexOf(".indd") >= 0)
                url = "./assets/img/icons/indd.jpg";
              
              if (name.toLowerCase().indexOf(".css") >= 0)
                url = "./assets/img/icons/css.png";
                
              if (name.toLowerCase().indexOf(".csv") >= 0)
                url = "./assets/img/icons/csv.png";
              
              if (name.toLowerCase().indexOf(".wav") >= 0)
                url = "./assets/img/icons/wav.png";
                
              if (name.toLowerCase().indexOf(".mov") >= 0)
                url = "./assets/img/icons/mov.png";
              
              if (name.toLowerCase().indexOf(".avi") >= 0)
                url = "./assets/img/icons/avi.png";
                
              if (name.toLowerCase().indexOf(".mp3") >= 0)
                url = "./assets/img/icons/mp3.png";

              if (name.toLowerCase().indexOf(".xml") >= 0)
                url = "./assets/img/icons/xml.png";

              if (name.toLowerCase().indexOf(".htm") >= 0)
                url = "./assets/img/icons/html.png";

              if (name.toLowerCase().indexOf(".html") >= 0)
                url = "./assets/img/icons/html.png";
                
                if (name.toLowerCase().indexOf(".bridgesort") >= 0)
                url = "./assets/img/icons/bridgesort.png";
                
              if (name.toLowerCase().indexOf(".vob") >= 0)
                url = "./assets/img/icons/vob.png";
              
              if (name.toLowerCase().indexOf(".raw") >= 0)
                url = "./assets/img/icons/raw.png";
                

            var image = {
              id:this.extractFileId(url),
              downloadURI:url,
              index:this.images.length, 
              name:name,
              url: url,
              metadata:null,
              thumbnailUrl:url,
              altText: '', 
              tag: 'nature'
            }   
            this.images.push(image);
          })
        } else {
          this.errorMessage = this.translate.instant('files_dialog.no_files_found')
        }
        // console.log(searchReply);
    });


    // console.log(url);
    /*this.connectionService.getCargoJobFilesUrl(url).subscribe(
      searchReply => {
        console.log(searchReply);
        
        if (! Array.isArray(searchReply.files.file)) {
          searchReply.files.file = [searchReply.files.file];
        }
        if (searchReply.files.file) {
          searchReply.files.file.forEach( f => {
            console.log(f);
            var name = f._attributes.name;
            var url = f._attributes.url;

            if (name.toLowerCase().indexOf(".docx") >= 0 || name.toLowerCase().indexOf(".doc") >= 0)
              url = "./assets/img/icons/doc.png";

            if (name.toLowerCase().indexOf(".txt") >= 0)
              url = "./assets/img/icons/txt.png";

            if (name.toLowerCase().indexOf(".zip") >= 0)
              url = "./assets/img/icons/zip.png";

            if (name.toLowerCase().indexOf(".xlsx") >= 0 || name.toLowerCase().indexOf(".xls") >= 0)
              url = "./assets/img/icons/xls.png";

            if (name.toLowerCase().indexOf(".indd") >= 0)
              url = "./assets/img/icons/indd.jpg";
            
            if (name.toLowerCase().indexOf(".css") >= 0)
              url = "./assets/img/icons/css.png";
              
            if (name.toLowerCase().indexOf(".csv") >= 0)
              url = "./assets/img/icons/csv.png";
            
            if (name.toLowerCase().indexOf(".wav") >= 0)
              url = "./assets/img/icons/wav.png";
              
            if (name.toLowerCase().indexOf(".mov") >= 0)
              url = "./assets/img/icons/mov.png";
            
            if (name.toLowerCase().indexOf(".avi") >= 0)
              url = "./assets/img/icons/avi.png";
              
            if (name.toLowerCase().indexOf(".mp3") >= 0)
              url = "./assets/img/icons/mp3.png";

            if (name.toLowerCase().indexOf(".xml") >= 0)
              url = "./assets/img/icons/xml.png";

            if (name.toLowerCase().indexOf(".htm") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".html") >= 0)
              url = "./assets/img/icons/html.png";
              
              if (name.toLowerCase().indexOf(".bridgesort") >= 0)
              url = "./assets/img/icons/bridgesort.png";
              
            if (name.toLowerCase().indexOf(".vob") >= 0)
              url = "./assets/img/icons/vob.png";
            
              if (name.toLowerCase().indexOf(".raw") >= 0)
              url = "./assets/img/icons/raw.png";
              
            var image = {
              id:this.extractFileId(f._attributes.url),
              downloadURI:f._attributes.downloadURI,
              index:this.images.length, 
              name:name,
              url: url,
              thumbnailUrl:url,
              altText: '', 
              tag: 'nature'
            }   
            this.images.push(image);
          }) 
        }
      }, error => {
        console.log(error);
      });*/
  }



  imgLoaded() {
    if (++this.imagesLoaded === this.images.length) {
      this.initMasonry();
    }
  }
  

  initMasonry() {
    this.zone.runOutsideAngular(() => {
      this.masonry = new Masonry(this.galleryContainer.nativeElement, {
        originLeft: !this.isRTL,
        transitionDuration: '0.3s',
        itemSelector: '.gallery-thumbnail',
        columnWidth: '.gallery-sizer'
      });

      const MutationObserver = window['MutationObserver'] || window['WebKitMutationObserver'];

      if (MutationObserver) {
        /** Watch for any changes to subtree */
        this.observer = new MutationObserver(() => {
          setTimeout(() => {
            this.masonry.reloadItems();
            this.masonry.layout();
          }, 30);
        });

        this.observer.observe(this.galleryContainer.nativeElement, {
          subtree: true,
          childList: true
        });
      }
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.observer) { this.observer.disconnect(); }
      if (this.masonry) { this.masonry.destroy(); }
    });
  }


  galleryOpened(index) {
    console.info('Gallery opened at index ', index);
  }

  // callback on gallery closed
  galleryClosed() {
    console.info('Gallery closed.');
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
    console.info('Gallery image clicked with index ', index);
  }
  
  // callback on gallery image changed
  galleryImageChanged(index) {

    // index._cached = false;
    // console.info('Gallery image changed to index ', index);
  }

  closeModal() {

    var sendData = "";
    if (this.deletedFiles)
      sendData = "delete";
    this.fromParent.resultCode = sendData;
    this.activeModal.close(this.fromParent);
    /*
    if (sendData == "ok") {
      this.fromParent.result = [];
      this.fromParent.layoutData.inputs.forEach( c => {
        if (c.type == "checkbox") {
          if (c.ngValue) {
            this.fromParent.result.push({name:c.componentName, value:c.valueIfSelected});
          } else {
            this.fromParent.result.push({name:c.componentName, value:c.valueIfSelected});
          }
        } else {
          this.fromParent.result.push({name:c.componentName, value:c.ngValue});  
        }
      });
    }*/
  }

}

/*
function NgxImageGalleryComponent(NgxImageGalleryComponent: any, arg1: { static: false; }) {
  // console.log(any); // throw new Error('Function not implemented.');
}
*/

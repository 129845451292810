// my-bootstrap-modal.component.ts
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CargoJob } from 'src/app/models/CargoJob';
import { ConnectionService } from 'src/app/services/connection.service';
import { LayoutParserService } from 'src/app/services/layout-parser.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'cargo-details-component',
  templateUrl: './cargo-details-component.html',
  styleUrls: ['./cargo-details-component.css', './file-upload.scss']
})

export class CargoDetailsComponent implements OnInit {

  // @Input() fromParent;

  _jobdata:any = null;
  cargoJob:any = null;

  @Input()
  set jobdata(data: any) {
    // console.log(data);
    this._jobdata = data;
    if (this._jobdata) {
      this.getCargoDetails(this.jobdata.job);
    }
  }
  get jobdata(): any {
    return this._jobdata;
  }

  isLoadingData:boolean = false;
  hasBaseDropZoneOver:boolean = false;
  uploader = new FileUploader({});
  isUploadingFile:boolean = false;
  imageFile = undefined;
  originalFileData = undefined;
  uploadMessage:string = "";
  errorMessage:string = "";

  constructor(
    // public activeModal: NgbActiveModal,
    private translate:TranslateService,
    private connectionService:ConnectionService,
    private parserService:LayoutParserService,
  ) { 

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      
      var fileReader: FileReader = new FileReader();
      fileReader.onloadend = (e) => {
        this.imageFile = fileReader.result;
        this.originalFileData = fileReader.result;
      }
      fileReader.readAsDataURL(fileItem._file);
      var job = this.cargoJob;

      console.log(fileItem);
      this.isUploadingFile = true;
      this.connectionService.cargoAddJobFile(job, fileItem._file).subscribe(res => 
        {
          // console.log(res);
          this.isUploadingFile = false;
          var notificationList = res['cargoResponse'].notificationList;
          var messages = this.parserService.parseNotificationList(notificationList);
          var fileUploadOk = this.translate.instant("main.file_upload_ok");
          if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {

            this.uploadMessage = fileUploadOk;
            /*Swal.fire(
              'Cargo',
              fileUploadOk
            );
            */
          }
        },(err) => {  
          var fileUploadError = this.translate.instant("main.file_upload_error");
          this.uploadMessage = fileUploadError;

          this.isUploadingFile = false;
          console.log(err);
        }
      );
    };
  }

  ngOnInit() {
    // this.isLoadingData = true;
    // console.log(this.fromParent);
    if (this.jobdata) {
      this.getCargoDetails(this.jobdata.job);
    }
  }


  fileOver (e: any, job:CargoJob): void {
    this.hasBaseDropZoneOver = e;
    this.uploadMessage = "";
    
    // this.currentJob = job;
    // console.log(job);
  }

  getCargoDetails(job:any) {

    var form = new FormData();
    form.append("fileID", "-1");
    form.append("jobID", job.id);
    this.isLoadingData = true;
   
    this.connectionService.cargoWebViewSearch(form).subscribe(
      searchReply => {
        if (searchReply) {
          // console.log(searchReply);
          this.isLoadingData = false;
          var searchResult = searchReply['cargoResponse']['cargoResponseBody']['webViewSearchResult'];
          // console.log(searchResult);
          this.cargoJob = this.parserService.parseCargoJobNew(searchResult.job, true);
          // console.log(this.cargoJob);

          this.getCargoJobImages3(this.cargoJob);
          // console.log(this.cargoJob);
        } else {
          this.isLoadingData = false;
          this.errorMessage = this.translate.instant("cargo-details-dialog.parse_xml_error");
        }
      });
  }

  getCargoJobImages3(item: any) {

    // console.log("cargoGetFilesForJobNew");
    item.imageFiles = [];

    this.connectionService.cargoGetFilesForJobNew(item).subscribe(
      searchReply => {
        item.showImages = true;
        // console.log(searchReply);

        if (searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file) {
          var files = searchReply.cargoResponse.cargoResponseBody.jobFiles.job.files.file;
          // console.log(files);

          if (!Array.isArray(files))
            files = [files];
          // console.log(files);

          files.forEach(f => {
            var name = f._attributes.name;

            var url = this.connectionService.url + f.thumbnailURI._cdata;

            if (name.toLowerCase().indexOf(".docx") >= 0 || name.toLowerCase().indexOf(".doc") >= 0)
              url = "./assets/img/icons/doc.png";

            if (name.toLowerCase().indexOf(".txt") >= 0)
              url = "./assets/img/icons/txt.png";

            if (name.toLowerCase().indexOf(".zip") >= 0)
              url = "./assets/img/icons/zip.png";

            if (name.toLowerCase().indexOf(".xlsx") >= 0 || name.toLowerCase().indexOf(".xls") >= 0)
              url = "./assets/img/icons/xls.png";

            if (name.toLowerCase().indexOf(".indd") >= 0)
              url = "./assets/img/icons/indd.jpg";

            if (name.toLowerCase().indexOf(".css") >= 0)
              url = "./assets/img/icons/css.png";

            if (name.toLowerCase().indexOf(".csv") >= 0)
              url = "./assets/img/icons/csv.png";

            if (name.toLowerCase().indexOf(".wav") >= 0)
              url = "./assets/img/icons/wav.png";

            if (name.toLowerCase().indexOf(".mov") >= 0)
              url = "./assets/img/icons/mov.png";

            if (name.toLowerCase().indexOf(".avi") >= 0)
              url = "./assets/img/icons/avi.png";

            if (name.toLowerCase().indexOf(".mp3") >= 0)
              url = "./assets/img/icons/mp3.png";

            if (name.toLowerCase().indexOf(".xml") >= 0)
              url = "./assets/img/icons/xml.png";

            if (name.toLowerCase().indexOf(".htm") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".html") >= 0)
              url = "./assets/img/icons/html.png";

            if (name.toLowerCase().indexOf(".bridgesort") >= 0)
              url = "./assets/img/icons/bridgesort.png";

            if (name.toLowerCase().indexOf(".vob") >= 0)
              url = "./assets/img/icons/vob.png";

            if (name.toLowerCase().indexOf(".raw") >= 0)
              url = "./assets/img/icons/raw.png";

            var image = {
              path: url,
            }
            item.imageFiles.push(image);
          })
        } else {

        }
        // console.log(searchReply);
      });
  }

}
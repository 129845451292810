

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from 'src/app/services/connection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'direct-link-compnent',
  templateUrl: './directlink.component.html',
  styles: [':host { display: block; }']
})

export class DirectLinkComponent {

  public isLoadingData:boolean = false;
  public loginData:any;
  public errorMessage:string = "";
  public configData:any = {};
  // queryParamsToSend = {};

  credentials = {
    username:"",
    email: '',
    password: ''
  };

  constructor(
      private connectionService: ConnectionService,
      private activatedRoute: ActivatedRoute,
      private router: Router
    ) {
  }
  
  ngOnInit () {
    console.log("Direct link component");
    this.isLoadingData = true;
    this.getConfigDataXML();
  }


  checkForParameters() {

    var queryParamsToSend = {};
    var url = window.location.href;

    this.connectionService.originalUrl = url;
    const obj = this.activatedRoute.snapshot.queryParams;
    // console.log("Check for parameter");
    if (Object.keys(obj).length == 0) {
      var pos = url.indexOf("?");
      if (pos >= 0) {
        var end = url.indexOf("#");
        var querystr = url.substring(pos+1, end);
        var queryparams = querystr.split("&");

        var query = {};
        // var paramsToForward = {};
        queryparams.forEach( pair => {
          var pp = pair.split("=");
          console.log(pp);
          if (pp[0] == "user") {
            this.credentials.username =  pp[1];
          } else if (pp[0]  == "pw") {
            this.credentials.password = pp[1];
          } else {
            queryParamsToSend[pp[0]] = pp[1];
          }
          query[pp[0]] = pp[1];
        })
        // console.log(query);
        console.log("------------------------------ DirectLinkSerchParams 1")
        localStorage.setItem("DirectLinkSerchParams", JSON.stringify(query));
      }
      if (this.credentials.username != "" && this.credentials.password != "") {
        this.cargoLogin();
      }
    } else {
      // console.log(this.activatedRoute.snapshot.queryParams);
      // console.log(obj);
      Object.keys(obj).forEach(key => {
        // var value = obj[key];
        // console.log(key + "=>" + obj[key]);
        if (key == "user") {
          this.credentials.username =  obj[key];
        } else if (key == "pw") {
          this.credentials.password = obj[key];
        } else {
          queryParamsToSend[key] = obj[key];;
        }
        // console.log(key + " " + value);
      });

      if (Object.keys(queryParamsToSend).length > 0) {
        console.log("------------------------------ Set DirectLinkSerchParams 2");
        console.log(queryParamsToSend);
        localStorage.setItem("DirectLinkSerchParams", JSON.stringify(queryParamsToSend));
        if (this.credentials.username != "" && this.credentials.password != "") {
          this.cargoLogin();
        }
      }
    }
  }

  cargoLogin() {

    this.isLoadingData = true;
    let form = new FormData();
    form.append("cargoRequest", "cargoLogin");
    form.append("user", this.credentials.username);
    form.append("pw", this.credentials.password);
    form.append("locale", "se");
    form.append("client", "external");
    this.errorMessage = "";
    // console.log(this.credentials);

    // console.log("Cargo url");
    this.isLoadingData = true;
    // console.log(this.configData);
    // console.log(this.configData.cargoURL);
    this.connectionService.cargoLogin(this.configData.cargoURL, form).subscribe(
        loginData => {
            this.isLoadingData = false;
            // console.log(loginData);
            this.loginData = loginData;
            console.log(this.loginData.cargoResponse);
            if (this.loginData.cargoResponse['cargoResponseHeader']['_attributes'].result === "0") {
              console.log(this.loginData.cargoResponse);
                let suid = this.loginData.cargoResponse['cargoResponseHeader']['suid']['_text'];
                var realName = this.loginData.cargoResponse['cargoResponseBody'].UserInfo['_attributes'].name;

                this.connectionService.suid = suid;
                this.connectionService.username = realName; // this.credentials.username;
                this.connectionService.setLoginCredentials(realName, suid);
                this.isLoadingData = false;
                this.router.navigate(['/saleswatch']);
             } else {
                console.log(this.loginData.cargoResponse);
                this.errorMessage = "Fel användarnamn eller lösenord!";
            }
            // console.log(loginData);

        }, error => {
          console.log(error);
          this.isLoadingData = false;
          this.errorMessage = "Inloggningen misslyckades!" + "\r\n" +
            error.message;
        });
  }


  getConfigDataXML(): void {

    // console.log("get XML config data 1");
    var url = window.location.href;
    // console.log("Current Url:" + url);
    if (url.indexOf("localhost:") >= 0) {
      url =  "https://cargo.demo.laidbacksolutions.se/cargo/cargoServlet";
    }
    /*if (url == "http://localhost:4200/#/") {
      this.configData.cargoURL = "https://cargo.demo.laidbacksolutions.se/cargo_mk/cargoServlet";
      this.connectionService.url = this.configData.cargoURL;
      this.configData.webViewId = 4;
      return;
    }
    */
    // this.configData.webViewId = 4;
    // this.connectionService.webViewId = "4";
    /*var len = url.indexOf("/sw/");
    this.configData.cargoURL = url.substring(0, len) + "/cargoServlet";
    console.log("Set cargo Url from path:" + this.configData.cargoURL);
    this.connectionService.url = this.configData.cargoURL;
    console.log(this.configData.cargoURL);
    */

    this.connectionService.getConfigXMLData().subscribe(
      configData => {
        // console.log("get XML config data 2 --------------------------");
        // console.log(configData);
        var url = window.location.href;
        // console.log(url);
        this.isLoadingData = false;

        if (configData.salesWatchSetup.cargoURL) {
          // console.log("Set cargo Url form file:");
          this.configData.cargoURL = configData.salesWatchSetup.cargoURL;
          this.connectionService.url = this.configData.cargoURL;
        } else {
          var len = url.indexOf("/sw/");
          this.configData.cargoURL = url.substring(0, len) + "/cargoServlet";
          // console.log("Set cargo Url from path:" + this.configData.cargoURL);
          this.connectionService.url = this.configData.cargoURL;
          console.log(this.configData.cargoURL);
        }
        if (configData['salesWatchSetup'] && configData['salesWatchSetup']['@attributes'].id) {
          this.configData.webViewId = configData['salesWatchSetup']['@attributes'].id;
        }
        this.isLoadingData = false;
        this.checkForParameters();
        // if (login)
        //    this.cargoLogin();
      }, error => {
          // var len = url.indexOf("/sw/");
          this.configData.cargoURL = "https://cargo.demo.laidbacksolutions.se/cargo/cargoServlet";
          // console.log("Error Set cargo Url from path:" + this.configData.cargoURL);
          this.connectionService.url = environment.proxyCargoUrl; // "https://cargo.demo.laidbacksolutions.se/cargo_mk/cargoServlet";
          // console.log(this.configData.cargoURL);
          this.configData.webViewId = environment.proxyWebUserId;
          this.connectionService.webViewId = environment.proxyWebUserId;
          this.isLoadingData = false;
          this.checkForParameters();
          //if (login)
          //  this.cargoLogin();
      }
    );
    this.isLoadingData = false;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AppService } from '../app.service';

@Component({
  selector: 'actions-button-component',
  templateUrl: './actions-button-component.html',
  styleUrls:['./file-upload.scss',
            './home.component.css',
            './floating_labels.css']
})

/*
template: `action-
    <button (click)="onClick()">{{ renderValue }}</button>
  `,
*/

export class ActionsButtonViewComponent implements ViewCell, OnInit {
  
  renderValue: string;
  isRTL: boolean;

  @Input() value: string | number;
  @Input() rowData: any;
  // @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() downloadCargoJobFile: EventEmitter<any> = new EventEmitter();
  @Output() uploadCargoJobFile: EventEmitter<any> = new EventEmitter();
  @Output() showCargoJobInfo: EventEmitter<any> = new EventEmitter();
  @Output() showCargoJobFiles: EventEmitter<any> = new EventEmitter();
  @Output() doCargoAction: EventEmitter<any> = new EventEmitter();
  @Output() setCargoJob: EventEmitter<any> = new EventEmitter();

  @Output() setCargoJobForm: EventEmitter<any> = new EventEmitter();
  @Output() uploadCargoJobFileForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private appService: AppService
  ) {
    this.isRTL = appService.isRTL;
  }

  
  ngOnInit() {
    // alert("init button");
    this.renderValue = this.value.toString().toUpperCase();
    // this.rowData = this.value.toString().toUpperCase();
  }

  onInfoClick() {
    console.log(this.rowData);
    console.log(this.value);
    // this.save.emit(this.rowData);
    this.showCargoJobInfo.emit(this.rowData);
  }


  setCargoJobClick(rowData) {

    this.setCargoJob.emit(rowData);  
  }

  setCargoJobClickForm(rowData) {

    this.setCargoJobForm.emit(rowData);  
  }

  

  handleUploadCargoFile2(files, rowData) {

    // console.log(this.rowData);
    // console.log(rowData);
    
    var elem = {
        files:files,
        row:rowData
    }
    this.uploadCargoJobFile.emit(elem);
    // console.log(files, job)
  }


  handleUploadCargoFileForm(files, rowData) {

    // console.log(this.rowData);
    // console.log(rowData);
    
    var elem = {
        files:files,
        row:rowData
    }
    this.uploadCargoJobFileForm.emit(elem);
    // console.log(files, job)
  }


  onShowCargoFilesClick() {
      // alert("show files dialog");
      this.showCargoJobFiles.emit(this.rowData);   
  }


  onDownloadCargoFileClick() {
    // alert("download file"); 
    this.downloadCargoJobFile.emit(this.rowData);   
    // this.downloadCargoJobFile.emit(this.rowData);   
  }


  preformCargoAction(action) {

    var elem = {
        action:action,
        row:this.rowData
    }
    this.doCargoAction.emit(elem)
  }

}
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  defaultLanguage:"se",
  deployURL:"cargosaleswatch/",
  appVersion: require('../../package.json').version,
  majorAppVersion: require('../../package.json').majorAppVersion,
  proxyWebUserId:"13",
  proxyCargoUrlDemo:"https://cargo.demo.laidbacksolutions.se/cargo_it/cargoServlet",
  proxyCargoUrlTest:"http://test.laidbacksolutions.se:8080/cargo/cargoServlet",
  proxyCargoUrlBonnier:"https://annonsmaterial.bonniernews.se/cargo/cargoServlet",
  proxyCargoUrlBonnier2:"https://bn-p-cargosw302.ad.bonniernews.se/cargo/cargoServlet",
  proxyCargoUrlVK:"http://annonsmaterial.vk.se/cargo/cargoServlet",
  proxyCargoUrl:"http://10.48.21.15/cargo/cargoServlet",
  enableFileUpload:"true",
  fileUploadFormats:"",
  enableCargoFormUploads:"true",
  formUploadFormats:"",
  enableFileDownload:"true",
  enableFileDrop:"true"
};

// https://cargo.demo.laidbacksolutions.se/cargo/cargoServlet

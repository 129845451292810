// my-bootstrap-modal.component.ts
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CargoJob } from 'src/app/models/CargoJob';
import { ConnectionService } from 'src/app/services/connection.service';
import { LayoutParserService } from 'src/app/services/layout-parser.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'cargo-details-dialog-component',
  templateUrl: './cargo-details-dialog-component.html',
  styleUrls: ['./cargo-details-dialog-component.css', './file-upload.scss']
})

export class CargoDetailsModalDialogComponent implements OnInit {

  @Input() fromParent;

  cargoJob:any;
  isLoadingData:boolean = true;
  
  hasBaseDropZoneOver:boolean = false;
  uploader = new FileUploader({});
  isUploadingFile:boolean = false;
  imageFile = undefined;
  originalFileData = undefined;
  uploadMessage:string = "";
  errorMessage:string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private translate:TranslateService,
    private connectionService:ConnectionService,
    private parserService:LayoutParserService,
  ) { 
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      
      var fileReader: FileReader = new FileReader();
      fileReader.onloadend = (e) => {
        this.imageFile = fileReader.result;
        this.originalFileData = fileReader.result;
      }
      fileReader.readAsDataURL(fileItem._file);
      var job = this.cargoJob;

      console.log(fileItem);
      this.isUploadingFile = true;
      this.connectionService.cargoAddJobFile(job, fileItem._file).subscribe(res => 
        {
          // console.log(res);
          this.isUploadingFile = false;
          var notificationList = res['cargoResponse'].notificationList;
          var messages = this.parserService.parseNotificationList(notificationList);
          var fileUploadOk = this.translate.instant("main.file_upload_ok");
          if (res.cargoResponse.cargoResponseHeader['_attributes'].result == "0") {

            this.uploadMessage = fileUploadOk;
            /*Swal.fire(
              'Cargo',
              fileUploadOk
            );
            */
          }
        },(err) => {  
          var fileUploadError = this.translate.instant("main.file_upload_error");
          this.uploadMessage = fileUploadError;

          this.isUploadingFile = false;
          console.log(err);
        }
      );
    };
  }


  ngOnInit() {
    // this.isLoadingData = true;
    console.log(this.fromParent);
    this.getCargoDetails(this.fromParent.jobData);
  }


  fileOver (e: any, job:CargoJob): void {
    this.hasBaseDropZoneOver = e;
    this.uploadMessage = "";
    
    // this.currentJob = job;
    // console.log(job);
  }

  getCargoDetails(job:any) {

    var form = new FormData();
    form.append("fileID", "-1");
    form.append("jobID", job.id);
    this.isLoadingData = true;

    this.connectionService.cargoWebViewSearch(form).subscribe(
      searchReply => {
        if (searchReply) {
          // console.log(searchReply);
          this.isLoadingData = false;
          var searchResult = searchReply['cargoResponse']['cargoResponseBody']['webViewSearchResult'];
          // console.log(searchResult);
          this.cargoJob = this.parserService.parseCargoJobNew(searchResult.job, true);
          // console.log(this.cargoJob);
        } else {
          this.isLoadingData = false;
          this.errorMessage = this.translate.instant("cargo-details-dialog.parse_xml_error");
        }
      });
  }


  closeModal(sendData:any) {

    this.fromParent.resultCode = sendData;
    if (sendData == "ok") {
      /*this.fromParent.result = [];
      this.fromParent.layoutData.inputs.forEach( c => {
        if (c.type == "checkbox") {
          if (c.ngValue) {
            this.fromParent.result.push({name:c.componentName, value:c.valueIfSelected});
          } else {
            this.fromParent.result.push({name:c.componentName, value:c.valueIfSelected});
          }
        } else {
          this.fromParent.result.push({name:c.componentName, value:c.ngValue});  
        }
      });*/
    }
    this.activeModal.close(this.fromParent);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { NgxXml2jsonService } from 'ngx-xml2json';
import * as xml2js from 'xml2js';
// import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';
import { CargoSessionData } from '../models/CargoSessionData';
import { transpileModule } from 'typescript';
import { AppRoutingModule } from '../app-routing.module';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

// import 'rxjs/add/operator/map'
// import { of } from 'rxjs';

// import 'rxjs/add/operator/map'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

// import { OrderTemplate } from "./models/order-template";
// import { Order } from "./models/order";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private cargoLoginResponse:any;
  private parser; //  = new DOMParser();
  private configData:any;
  private sessionsData: CargoSessionData = new CargoSessionData();
  private _username: string;
  private _suid: string;
  private _userUuid: string;
  private _url: string = "http://test.laidbacksolutions.se:8080/cargo/cargoServlet";
  private _originalUrl = "";
  private _webViewId: string;
  private userPreferences:any;
  private _enableImageUpload:boolean = true;
  private isLoggedOut:boolean = true;
  private _canDeleteFiles
  constructor(
    private ngxXml2jsonService: NgxXml2jsonService,
    private _http: HttpClient,
    private cookieService:CookieService
  ) { 
    this.parser = new DOMParser();

    var str = localStorage.getItem("cargoSalesWatch");
    if (str) {
      var c = JSON.parse(str);
      // console.log("------ get local storage -------");
      // console.log(c);
      if (c) {
        this._suid = c.suid;
        this._webViewId = c.webViewId;
        this._url = c.url;
        this.username = c.username;
      }
    }

  }

  /*request(url:string, form:FormData): Observable<any> {
    this._http
      .get<any>('/assets/menu.xml', { responseType: 'text' as 'json' })
      .pipe(map(res => JSON.parse(res as string)))
      .subscribe(res => (this.cargoLoginResponse = res));
  }
  */
  get enableImageUpload(): boolean {
    return this._enableImageUpload
  }

  set enableImageUpload(userEmail: boolean) {
    this._enableImageUpload = userEmail
  }


  set canDeleteFiles(data:boolean) {
    this._canDeleteFiles = data;
  }


  get canDeleteFiles(): boolean{
    return this._canDeleteFiles;
  }

  get username(): string {
    return this._username
  }
  set username(userEmail: string) {
    this._username = userEmail
  }

  get suid(): string {
    return this._suid;
  }
  set suid(suid: string) {
    this._suid = suid;
  }
  
  get userUuid(): string {
    return this._userUuid;
  }
  set userUuid(suid: string) {
    this._userUuid = suid;
  }

  get url(): string {
    return this._url;
  }
  set url(url: string) {
    // console.log("ser url 1");
    this._url = url;
  }

  get originalUrl(): string {
    return this._originalUrl;
  }
  set originalUrl(url: string) {

    var pos = url.indexOf("/#");
    if (pos > 0)
      url = url.substring(0, pos);

    this._originalUrl = url;
  }

  get webViewId(): string {
    return this._webViewId;
  }
  set webViewId(webViewId: string) {
    this._webViewId = webViewId;
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    // FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

 cargoRequest(url:string, form:FormData): Observable<any> {
  return this._http.post<any>(url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => 
      this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));
 }

  getStoredConfigData(): any {
   return this.configData;
  }



  setLoginCredentials(username:string, suid) {

    this.sessionsData = new CargoSessionData();
    this.sessionsData.username = username;
    this.sessionsData.suid = suid; //
    this._suid = suid;
    this.sessionsData.url = this._url;
    this.sessionsData.webViewId = this._webViewId;
    
    localStorage.setItem("cargoSalesWatch", JSON.stringify(this.sessionsData));
    // console.log("------- setLoginCredentials ----------");
    // console.log(this.sessionsData);
    
  }


  loadIfNot( url, cssId){

    if (! document.getElementById(cssId)){
      // console.log("load css file:" + url);
       var head  = document.getElementsByTagName('head')[0];
       var link  = document.createElement('link');
       link.id   = cssId;
       link.rel  = 'stylesheet';
       link.type = 'text/css';
       link.href = url;
       link.media = 'all';
       head.appendChild(link);
   } else{
      console.log("css allready loaded");
   }
   console.log("home component loaded");
 }

  isLoggedIn2(): boolean {

    var res = false;
    var sessionStr = localStorage.getItem("cargoSalesWatch");
    // console.log("sessionStr:" +  sessionStr);
    if (sessionStr == "null")
      return res;
    if (sessionStr && sessionStr != null) {
      this.sessionsData = JSON.parse(sessionStr); 
      res = this.sessionsData.suid != "";
    }
    return res;
  }

  isLoggedIn(): Observable<boolean> {
    
    var res = false;
    var sessionStr = localStorage.getItem("cargoSalesWatch");
    // console.log("sessionStr:" +  sessionStr);
    if (sessionStr == "null")
      return of(res);

    if (sessionStr && sessionStr != null) {
      this.sessionsData = JSON.parse(sessionStr); 
      res = this.sessionsData.suid != "";
    }
    return of(res);
  }

  /*isLoggedIn() : Observable<boolean> {
    var res = false;
    var sessionStr = sessionStorage.getItem("cargoSalesWatch");
    if (sessionStr && sessionStr != null) {
      this.sessionsData = JSON.parse(sessionStr); 
    }
    return res;
  }
  */
  

  // var params = "cargoRequest=getSuggestedMetaValues&suid=" + sessionUID + "&metaID=" + metaID + "&textStart=" + textStart;
  
  cargoGetTypeaheadValues(metaId:string, textStart:string) {

    let form = new FormData();
    form.append("cargoRequest", "getSuggestedMetaValues");
    form.append("metaID", metaId);
    form.append("textStart", textStart);
    form.append("suid", this._suid);
    // console.log(form);
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      // console.log(res);
      // let list = res['cargoResponse']['cargoResponseBody']['suggestionList'];
      // console.log(list);
      const parser = new DOMParser();
      const xml = parser.parseFromString(res, 'text/xml');
      // console.log(xml);
      let floor = xml.getElementsByTagName("suggestionItem");
      var i = 0;
      var result = [];
      for (i = 0; i < floor.length; i++) {
        // console.log(floor[i].childNodes[0].nodeValue);
        result.push(floor[i].childNodes[0].nodeValue)
      }
      // console.log(floor);
      // const obj = this.ngxXml2jsonService.xmlToJson(xml);
      // console.log(obj);
      return result;
    }
        // console.log(res)
        // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));  
  }

  cargoGetFilesForJob(job:any) {

    //cargoRequest=productionGetJobFiles&jobID=<jobbets ID>
    var form = new FormData();
    form.append("cargoRequest", "productionGetJobFiles");
    form.append("suid", this._suid);
    form.append("jobID", job.id);

    // console.log(form);
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
        var convert = require('xml-js');
        console.log(res);
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
        return result1;
      }
    ));  
  }


  cargoGetFilesForJobNew(job:any) {

    //cargoRequest=productionGetJobFiles&jobID=<jobbets ID>
    var form = new FormData();
    form.append("cargoRequest", "getJobFiles");
    form.append("suid", this._suid);
    form.append("jobID", job.id);

    // console.log(form);
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
        var convert = require('xml-js');
        // console.log(res);
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        // console.log(result1);
        // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
        return result1;
      }
    ));  
  }
  
  cargoWebViewSearchMoreResults(form:FormData) {

    form.append("cargoRequest", "searchGetMoreResult");
    form.append("suid", this._suid);
    // console.log(form);
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
        var convert = require('xml-js');
        // console.log(res);
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
        return result1;
      }
    ));  
  }

  

  cargoSendSessionPing() {

    console.log("Session ping");
    console.log(this._url);
    console.log(this._suid);

    var str = localStorage.getItem("cargoSalesWatch");
    if (str) {
      var c = JSON.parse(str);
      if (c.suid) {
        this._suid = c.suid;
      }
    }
    console.log("Url:" + this._url + " suid: " + this._suid )
    if (! this.isLoggedOut) {
      let form = new FormData();
      form.append("cargoRequest", "sessionPing");
      form.append("suid", this._suid);
     return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
      .pipe(map(res =>
        this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
      ));  
    }
  }


  cargoGetUserProperies(propertyToGet:string) {

    /*if (this.userPreferences) {
      return this.userPreferences;
    }
    */
    // console.log("get user properties");
    let form = new FormData();
    form.append("cargoRequest", "getUserProperty");
    form.append("suid", this._suid);
    form.append('propertyKey', propertyToGet);
    // console.log(form);
    var userPrefs = null;
    
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      // console.log(res);
      var result = this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'));
      // console.log(result);
      if (result['cargoResponse'] && result['cargoResponse']['cargoResponseBody'] && 
      result['cargoResponse']['cargoResponseBody']['propertyList']) {
        var propertiesStr = result['cargoResponse']['cargoResponseBody']['propertyList']['property'];
        userPrefs = JSON.parse(propertiesStr);
        // console.log(userPrefs);
        if (userPrefs.savedCargoSearchList) {
          userPrefs.savedCargoSearchList.forEach( s => {
            if (! s.search_uid) {
              s.search_uid = uuidv4();
            }
          });
        }
      }
      return userPrefs;
    }
    ));  
  }

  cargoSetUserProperies(propertyToGet:string, propertyValue:string) {

    let form = new FormData();
    form.append("cargoRequest", "setUserProperty");
    form.append("suid", this._suid);
    form.append('propertyKey', propertyToGet);
    form.append('propertyValue', propertyValue); 

    this.userPreferences = JSON.parse(propertyValue);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res =>
      this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));  
  }

  

  cargoMultiAddJobFile(joblist:string, file:File) {
    
    let form = new FormData();
    form.append("cargoRequest", "addJobFile");
    form.append("suid", this._suid);
    form.append("file", file);
    form.append("jobID", joblist);
    form.append("waitForThumbnail", "true");
    form.append("file", file.name);
    form.append("fileTag", "");
    
    console.log("upload for jobids:" + joblist);
    console.log(this._url);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      // console.log(result1);
      return result1;
      }
    ));  
  }

  cargoAddJobFile(job:any, file:File) {
    
    console.log(job);
    let form = new FormData();
    form.append("cargoRequest", "addJobFile");
    form.append("suid", this._suid);
    form.append("file", file);
    form.append("jobID", job.id);
    form.append("waitForThumbnail", "true");
    form.append("file", file.name);
    form.append("fileTag", "");
    
    console.log("upload for jobid:" + job.id);
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      // console.log(result1);
      return result1;
      }
    ));  
  }


  cargoDeleteJobFile(job:any, file:any) {
    
    let form = new FormData();
    form.append("cargoRequest", "deleteFile");
    form.append("suid", this._suid);
    form.append("fileID", file.id);
    form.append("jobID", job.id);
    form.append("serverID", "1");
    
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      // console.log(result1);
      return result1;
      }
    ));  
  }

  cargoConnectSubsearch(jobID:string) {

    // console.log("prepare action");
    let form = new FormData();
    form.append("cargoRequest", "connectSubsearch");
    form.append("suid", this._suid);
    form.append("jobID", jobID);
    form.append("connectServer", "1");
    
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      console.log(result1);
      return result1;
      }
    ));  
  }

  cargoPrepareAction(actionID:string, jobID:string) {

    // console.log("prepare action");
    let form = new FormData();
    form.append("cargoRequest", "prepareAction");
    form.append("suid", this._suid);
    form.append("jobID", jobID);
    form.append("actionID", actionID);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      // console.log(result1);
      return result1;
      }
    ));  
  }

  cargoExcuteAction(form:FormData, paid:string) {

    form.append("cargoRequest", "executeAction");
    form.append("suid", this._suid);
    form.append("paid", paid);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      // console.log(result1);
      return result1;
      }
    ));  
  }




  export(url) {
    return this._http.get(url, 
        {responseType: 'blob'});
  }

  /*downloadFile(url:string): Observable<Blob> {
    let options = new RequestOptions({responseType: ResponseContentType.Blob });
    return this._http.get(url , options)
        .map(res => res.blob())
        .catch(this.handleError)
  }   
  */

  cargoCancelAction(paid:string) {

    let form = new FormData();
    form.append("cargoRequest", "cancelAction");
    form.append("suid", this._suid);
    form.append("paid", paid);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      // console.log(result1);
      return result1;
      }
    ));  
  }

  cargoWebViewSearch(form:FormData) {

    form.append("cargoRequest", "webViewSearch");
    form.append("suid", this._suid);
    // console.log(form);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
      var convert = require('xml-js');
      var result1 = null;
      try {
        result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
      } catch(err) {
        alert("Parse xml error:" + err.message + "\r\nThe xml has been writtewn to the console log");
        console.log(res);
      }
     
      // console.log(result1);
      // const parser = new DOMParser();
      // const xml = parser.parseFromString(res, 'text/xml');
      // console.log(xml);
      // let floor = xml.getElementsByTagName("suggestionItem");
      //this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
      return result1;
      }
    ));  
  }


  getCargoJobFilesUrl(url:string) {

    console.log(url);

    return this._http.get<any>( url, { responseType: 'text' as 'json' })
    .pipe(map(res => {
        var convert = require('xml-js');
        // console.log(res);
        var jsonData = convert.xml2json(res, {compact: true, spaces: 4})
        var result1 = JSON.parse(jsonData);
        return result1;
      }
      // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));
  }


  getCargoJobFiles(jobID:string) {

    let form = new FormData();
    form.append("cargoRequest", "executeAction801");
    form.append("suid", this._suid);
    form.append("jobId", jobID);

    // console.log(this._suid + " " + this.webViewId);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
        var convert = require('xml-js');
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        return result1;
      }
      // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));
  }

  getWebViewLayout() {

    let form = new FormData();
    form.append("cargoRequest", "getWebViewSetup");
    form.append("suid", this._suid);
    form.append("webViewID", this.webViewId);
    console.log("getWebViewLayout: " + this._url + " " + this.webViewId + " " + this._suid);
    console.log(this._suid + " " + this.webViewId);

    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
    .pipe(map(res => {
        var convert = require('xml-js');
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        return result1;
      }
      // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));
  }

  cargoLogout() {

    console.log("Cargo logout");
    let form = new FormData();
    form.append("cargoRequest", "cargoLogout");
    form.append("suid", this._suid);
    console.log(this._suid);
    console.log("this._url: " + this._url);
    
    this.isLoggedOut = true;
    return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
      .pipe(map(res => {
        // console.log(res);
        this.setLoginCredentials("", "");
        this._suid = "";
        this._username = "";

        console.log("set local storage to null");
        localStorage.setItem("cargoSalesWatch", null);

        var convert = require('xml-js');
        // console.log(res);
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
        return result1;
      }
    )); 

    /*return this._http.post<any>(this._url, form, { responseType: 'text' as 'json' })
      .pipe(map(res => {
        console.log("logout result");
        console.log(res);

        return this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
      }
    ));
    */
    // this.cargoLogout();
    console.log("cargo Logout");
  }


  cargoLogin(url:string, form:FormData): Observable<any> {

    this._url = url;
    // console.log("ser url 2");
    console.log("cargoLogin: " + url);
    
    return this._http.post<any>(url, form, { responseType: 'text' as 'json' })
      .pipe(map(res => {
        this.isLoggedOut = false;
        var convert = require('xml-js');
        var jsonData = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        // console.log(jsonData);
        return jsonData; // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
      }
    ));
    
    /*
    return this._http.post<any>(url, form, { responseType: 'text' as 'json' })
        .pipe(
            tap(languageData => {this.log(`fetched cargo login data`)
            const parser = new DOMParser();
            const xml = parser.parseFromString(languageData, 'text/xml');
            const obj = this.ngxXml2jsonService.xmlToJson(xml);
            // console.log(obj);
            languageData = obj;
          }),
          catchError(this.handleError('get languageData Error', []))
        );
      */
  }

  /*getConfigData(): Observable<any> {
    // console.log(this.templatesUrl);
    // console.log("get language data in service");

    console.log("------- getConfigData :" + window.location.href);
    console.log("load saleswatch css file");
    this.loadIfNot("/assets/config/saleswatch.css", "defaultcss"); 

    return this._http.get<any>("/assets/config/cargo.json")
        .pipe(
            tap(languageData => {
              // console.log(languageData);
              this.configData = languageData;
              this.webViewId = this.configData.webViewId;

              // console.log("------- getConfigData :" + this.webViewId);
              // this.log(`fetched cargo config data`);
            }
          ),catchError(this.handleError('get languageData Error', []))
        );
  }
  */

  setCookie(name:string, expireDays:number, info:any) {

    let expireDate = new Date();
    expireDate.setMinutes(expireDate.getMinutes() + (expireDays * 24 * 60));
    // console.log(expireDate);
    // console.log(info);
    this.cookieService.set( name, JSON.stringify(info), expireDate ); // To Set Cookie    
  }

  resetCookie(name:string) {
      
      let expireDate = new Date();
      this.cookieService.set( name, "", expireDate );  
  }

  hasCookie(name:string):any {

      var res = null;
      var cookiestr = this.cookieService.get(name);
      // console.log(cookiestr)
      if (cookiestr && cookiestr.length > 0) {
          res = JSON.parse(cookiestr);
      }
      return res;
      
  }

  getCookieString(name:string):any {

    var res = null;
    var cookiestr = this.cookieService.get(name);
    console.log(cookiestr)
    return cookiestr;
}

  getConfigXMLData(): Observable<any> {
    // console.log(this.templatesUrl);
    // console.log("load XML config dile");
    // console.log("load saleswatch css file");
    // this.loadIfNot("/assets/config/saleswatch.css", "defaultcss"); 
    console.log("getConfigXMLData:" + window.location.href);

    return this._http.get<any>("setup.xml", { responseType: 'text' as 'json' })
      .pipe(map(res => {
          res = this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
          console.log("getConfigXMLData result:");
          console.log(res);
          
          if (res['salesWatchSetup'] && res['salesWatchSetup']['@attributes'].id) {
            this.webViewId = res['salesWatchSetup']['@attributes'].id;
            
            if (res['salesWatchSetup']['@attributes'].enableUpload) {
              this.enableImageUpload = res['salesWatchSetup']['@attributes'].enableUpload;
            }
            console.log("Use WebView Setup: " + this.webViewId);
          }
          return res;
        }
      )); 

      /*.pipe(map(res => {
        var convert = require('xml-js');
        var result1 = JSON.parse(convert.xml2json(res, {compact: true, spaces: 4}));
        console.log(result1);
        return result1;
      }
      // this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'))
    ));
    */
    /*
        .pipe(
            tap(languageData => {
              console.log(languageData)
              // this.configData = languageData;
              // this.webViewId = this.configData.webViewId;
              this.log(`fetched xml config data`);
            }
            ),
            catchError(this.handleError('get languageData Error', []))
        );
        */
  }

  /*auth(fallback): Observable<boolean> {
    var url = "";
    return this._http.get(url)
      .map(() => true).catch(() => {
        fallback();
        return Observable.of(false);
      });}

      */

  /*auth(theURL:string, fallback): Observable<boolean> {
    return this._http.get(theURL + '/user/profile', { withCredentials: true })
      .pipe(
      .map(() => true).catch(() => {
        fallback();
        return Observable.of(false);
      }));}
  */

  /*auth(fallback): Observable<boolean> {
    return Observable.of(false);
  }
  */

  hasValidSession(suid): Observable<boolean> {
    
    // var suid = "xxxxxxxx";
    let form = new FormData();
    form.append("cargoRequest", "getSessionStatus");
    form.append("suidToCheck", suid);
    
    // console.log("cargoLogin: " + url)
    return this._http.post<string>("http://213.115.55.103/cargo/cargoServlet", form, { responseType: 'text' as 'json' })
        .pipe(
          map(res => {
            var json = this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'));
            if (json['cargoResponse']['cargoResponseHeader']['@attributes']['result'] == 0) {
              // res.cargoResponse['cargoResponseHeader'].
              console.log(res);
              console.log(json);
              return true;
            } else {
              return false;
            }
          }
        )
      );
    //let obs = new Observable<boolean>();
    // obs.pipe(map(() => true));
    // return obs;
  }

  public getTheBoolean(): Observable<boolean> {

    let token = JSON.parse(localStorage.getItem("cargoPlaceHolder"));
    console.log(token);

    let theBoolean = new BehaviorSubject<boolean>(false);
    if (token) {
      if (token.cargoSUID && token.cargoSUID != "") {
        theBoolean = new BehaviorSubject<boolean>(true);  
      }
    }
    return theBoolean.asObservable();
  }


  getChartURLData(theURL): Observable<any> {
    // console.log(this.templatesUrl);
    // console.log("get language data in service");
    return this._http.get<any[]>(theURL)
        .pipe(
            tap(chartdata => this.log(`fetched chart url data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }

  getChartURLDataWithForm(theURL, form): Observable<any[]> {
    // console.log(this.templatesUrl);
    // console.log("get language data in service");
    return this._http.post<any[]>(theURL, form)
        .pipe(
            tap(chartdata => this.log(`fetched chart url data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }

  getChartGroups():Observable<any[]> {

    let url = this.configData.cargoNumbersURL;
    url = url + "/cargonumbers/serverside/cargo_numbers_db/chart_groups/getchartgroups.jsp";
    console.log(url);

    return this._http.get<any[]>(url)
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }


  getChartViews():Observable<any[]> {
    
    let url = this.configData.cargoNumbersURL;
    url = url + "/cargonumbers/serverside/cargo_numbers_db/chart_views/getchartviews.jsp";
    
    return this._http.get<any[]>(url)
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }


  getFolders():Observable<any[]> {
    
    let url = this.configData.cargoNumbersURL;
    url = url + "/cargonumbers/serverside/cargo_numbers_db/folders/getfolders.jsp";

    return this._http.get<any[]>(url)
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }

  getCharts():Observable<any[]> {
    
    let url = this.configData.cargoNumbersURL;
    url = url + "/cargonumbers/serverside/cargo_numbers_db/charts/getcharts.jsp";

    return this._http.get<any[]>(url)
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }

  getChart(chart_id):Observable<any[]> {
    
    let url = this.configData.cargoNumbersURL;
    url = url + "/cargonumbers/serverside/cargo_numbers_db/charts/getcharts2.jsp?chart_id=" + chart_id;

    return this._http.get<any[]>(url)
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }


  getSeries():Observable<any[]> {
    
    let url = this.configData.cargoNumbersURL;
    url = url + "/cargonumbers/serverside/cargo_numbers_db/series/getseries.jsp";

    return this._http.get<any[]>(url)
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }

  getLanguageData(lang): Observable<any[]> {
    // console.log(this.templatesUrl);
    console.log("get language data in service");
    // environment.deployURL + 

    return this._http.get<any[]>("/assets/locale/"+ lang + ".json")
        .pipe(
            tap(languageData => this.log(`fetched language data`)),
            catchError(this.handleError('get languageData Error', []))
        );
  }
  
  createDateString(date:Date):string {

    var datestr = "" + date.getFullYear() + "-";
    if (date.getMonth() < 10) {
      datestr = datestr  + "0";
    }
    datestr = datestr + (date.getMonth()+1);
    datestr = datestr + "-";
    if (date.getDate() < 10) {
      datestr = datestr + "0";
    }
    datestr = datestr + date.getDate()
    return datestr;
  }

  replaceSimpleMacros = function(string, format) {
                
    var result = string;
    var add = false;
    if (string[0] == '+' || string[0] == '-') {
        if (string[0] == '+')
            add = true;
        
        var last = string[string.length - 1];
        var number = "";
        // alert(last);
        if (last == 'V' || last == 'W' || last == 'D' || last == 'M' || last == 'Y') {
            number = string.substring(1, string.length - 1);   
        } else {
            last = 'D';
            number = string.substring(1, string.length);    
        }
        // alert(number);
        var numberOfDaysToAdd = 0;
        var someDate = new Date();
        
        if (last == 'V' || last == 'W') {
            numberOfDaysToAdd = parseInt(number) * 7; 
            if (add)
                someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            else
                someDate.setDate(someDate.getDate() - numberOfDaysToAdd); 
            result = this.createDateString(someDate); // moment(someDate).format(format);
        }
        if (last == 'M') {
            if (add)
                someDate.setMonth(someDate.getMonth() + parseInt(number));  
            else
                someDate.setMonth(someDate.getMonth() - parseInt(number)); 
            result = this.createDateString(someDate);  //result = moment(someDate).format(format);
        }
        if (last == 'Y') {
            if (add)
                someDate.setFullYear(someDate.getFullYear() + parseInt(number));  
            else
                someDate.setFullYear(someDate.getFullYear() - parseInt(number)); 
            // result = moment(someDate).format(format);
            result = this.createDateString(someDate); 
        }
        if (last == 'D') {
            numberOfDaysToAdd = parseInt(number);
            if (add)
                someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
           else
                someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
            // result = moment(someDate).format(format);
            result = this.createDateString(someDate); 
        }
        
        if (last == 'd') {
            numberOfDaysToAdd = parseInt(number);
            if (add)
                someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
           else
                someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

            result = this.createDateString(someDate); 
            // result = moment(someDate).format(format);
        }
        
    }
    return result;
  }

  parseToDate(str):Date {

    // let res = "";
    let dstr = "";
    let resDate:Date; //  = new Date();

    if (str.indexOf("%D[") >= 0 && str.indexOf("]%")) {
      str = str.replace("%D[", ""); 
      str = str.replace("]%", "");
      var arr = str.split(",");
      let currDate = new Date();
      
      if (arr.length == 1) {
        dstr = arr[0];
      } else {
        dstr = arr[1];
      }
      dstr = dstr.replace("YYYY", currDate.getFullYear().toString());
      let month = "";
      if (currDate.getMonth() < 10)
        month = "0" + currDate.getMonth().toString();
      else
        month = currDate.getMonth().toString();

      dstr = dstr.replace("MM", month);
      let day = ""
      if (currDate.getUTCDate() < 10)
        day = "0" + currDate.getUTCDate().toString();
      else
        day = currDate.getUTCDate().toString();
      dstr = dstr.replace("DD", day);
      // console.log(dstr);
      resDate  = new Date(dstr);
      if (arr.length == 2) {
        resDate.setDate(resDate.getDate() + parseInt(arr[0]));
      }
    } else {
      dstr = str;
      resDate = new Date(dstr);
    }

    return resDate;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
}

private log(message: string) {
    // console.log('LanguageData: ' + message);
}
}

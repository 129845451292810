// my-bootstrap-modal.component.ts
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'cargo-action-dialog-component',
  templateUrl: './cargo-action-dialog-component.html',
  styleUrls: ['./cargo-action-dialog-component.css']
})

export class CargoActionModalComponent implements OnInit {

  @Input() fromParent;


  public layoutElements:any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private translate:TranslateService,
  ) { }

  ngOnInit() {
    console.log(this.fromParent);

    this.layoutElements = this.layoutElements.concat(this.fromParent.layoutData.inputs);
    this.layoutElements = this.layoutElements.concat(this.fromParent.layoutData.resources);
    

    this.layoutElements.sort((a, b) => {

      if (a.type == "label")
        a.posY = a.posY - 5;
      if (b.type == "label")
        b.posY = b.posY - 5;
      
      if (a.posY < b.posY)
        return -1;
      if (a.posY > b.posY)
        return 1;
      return 0;
    });
    console.log(this.layoutElements);
    /* Output:
     {prop1: "Some Data", prop2: "From Parent Component", prop3: "This Can be anything"}
    */
  }

  closeModal(sendData) {

    this.fromParent.resultCode = sendData;
    if (sendData == "ok") {
      this.fromParent.result = [];
      this.fromParent.layoutData.inputs.forEach( c => {
        if (c.type == "checkbox") {
          if (c.ngValue) {
            this.fromParent.result.push({name:c.componentName, value:c.valueIfSelected});
          } else {
            this.fromParent.result.push({name:c.componentName, value:c.valueIfSelected});
          }
        } else {
          this.fromParent.result.push({name:c.componentName, value:c.ngValue});  
        }
      });
    }
    this.activeModal.close(this.fromParent);
  }

}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AppService } from '../app.service';

@Component({
  selector: 'dispatch-select-checkbox-component',
  templateUrl: './dispatch-select-checkbox-component.html',
  styleUrls:['./dispatch-file-upload.scss',
            './dispatch-home.component.css',
            './dispatch-floating_labels.css']
})

/*
template: `action-
    <button (click)="onClick()">{{ renderValue }}</button>
  `,
*/

export class DispatchSelectCheckboxViewComponent implements ViewCell, OnInit {
  
  renderValue: string;
  isRTL: boolean;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() toggleSelectJob: EventEmitter<any> = new EventEmitter();
  @Output() toggleShowFiles: EventEmitter<any> = new EventEmitter();
  // @Output() save: EventEmitter<any> = new EventEmitter();
  // @Output() downloadCargoJobFile: EventEmitter<any> = new EventEmitter();
  // @Output() uploadCargoJobFile: EventEmitter<any> = new EventEmitter();
  // @Output() showCargoJobInfo: EventEmitter<any> = new EventEmitter();
  // @Output() showCargoJobFiles: EventEmitter<any> = new EventEmitter();
  // @Output() doCargoAction: EventEmitter<any> = new EventEmitter();

  constructor(
    private appService: AppService
  ) {
    this.isRTL = appService.isRTL;
  }

  
  ngOnInit() {
    // alert("init button");
    this.renderValue = this.value.toString().toUpperCase();
  }

  HandleClick() {
    // console.log("HandleClick");
    this.toggleSelectJob.emit(this.rowData);
  }
  
  HandleShowFilesClick() {
    // console.log("this.rowData");
    // console.log("HandleShowFilesClick");
    // console.log(this.rowData);
    // this.rowData.showFileRows = ! this.rowData.showFileRows;
    this.toggleShowFiles.emit(this.rowData);
  }


  onInfoClick() {
    // console.log(this.rowData);
    // console.log(this.value);
    // this.save.emit(this.rowData);
    // this.showCargoJobInfo.emit(this.rowData);
  }

  handleUploadCargoFile2(files) {

    var elem = {
        files:files,
        row:this.rowData
    }
    // this.uploadCargoJobFile.emit(elem);
    // console.log(files, job)
  }

  onShowCargoFilesClick() {
      // alert("show files dialog");
      // this.showCargoJobFiles.emit(this.rowData);   
  }

  onDownloadCargoFileClick() {
    // alert("download file"); 
    // this.downloadCargoJobFile.emit(this.rowData);   
  }

  preformCargoAction(action) {

    var elem = {
        action:action,
        row:this.rowData
    }
    // this.doCargoAction.emit(elem)
  }

}
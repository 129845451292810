import { Component, Input, AfterViewInit, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ConnectionService } from 'src/app/services/connection.service';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { v4 as uuidv4 } from 'uuid';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [':host { display: block; }']
})
export class LayoutSidenavComponent implements AfterViewInit, OnDestroy {

  subscribeTimer: any;
  timeLeft: number = 60;
  source:any;
  abc:any;
  subscription: Subscription;
  message: any = {};
  
  public userPreferences = {
    savedCargoSearchList:[],
    sortOrder: {
      column:0,
      decending:false,
      currentSortOrder:"",
      icon:"fa fa-chevron-circle-up"
    }
  }

  @Input() orientation = 'vertical';

  @HostBinding('class.layout-sidenav') hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') hostClassFlex = false;

  constructor(
    private router: Router, 
    private messageService: MessageService,
    private appService: AppService, 
    private connectionService:ConnectionService,
    private layoutService: LayoutService) {
      // Set host classes
      this.hostClassVertical = this.orientation !== 'horizontal';
      this.hostClassHorizontal = !this.hostClassVertical;
      this.hostClassFlex = this.hostClassHorizontal;
      this.subscription = this.messageService.getMessage().subscribe(
        message => { 
          this.message = message; 
          // console.log(message);
          this.cargoGetUserProperies();
        }
      );
  }
  
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
    this.observableTimer();
    this.cargoGetUserProperies();
  }


  applySearchParams(item) {
    // console.log(item);
    this.router.navigate(['saleswatch'], { queryParams: { id: item.search_uid} });
  }

  cargoGetUserProperies() {

    // this.isLoadingData = true;
    this.connectionService.cargoGetUserProperies("Preferences").subscribe(
      userPropertiesReply => {
        if (userPropertiesReply != null) {
          this.userPreferences = userPropertiesReply; 
        }
        // console.log(userPropertiesReply);
        // this.isLoadingData = false;
        // var propertiesStr = userPropertiesReply['cargoResponse']['cargoResponseBody']['propertyList']['property'];
        // JSON.parse(propertiesStr);
        /*console.log(this.userPreferences.savedCargoSearchList);
        if (this.userPreferences.sortOrder) {
          this.userPreferences.sortOrder = this.userPreferences.sortOrder;
        }
        console.log(this.userPreferences);
        */
      }
    );
  }


  observableTimer() {

    this.source = timer(1000, 1000 * 59);
    this.abc = this.source.subscribe(val => {
      this.connectionService.isLoggedIn().subscribe(
        result => {
          if (result) {
            console.log("send server ping 1");
            this.subscribeTimer = this.timeLeft - val;
            this.sendServerPing();
          } else {
            this.abc.unsubscribe();  
            console.log("is logged out 1");
          }
        }
      );

      /*if (this.connectionService.isLoggedIn()) {
        console.log("send server ping 2");
        this.subscribeTimer = this.timeLeft - val;
        this.sendServerPing();
      } else {
        console.log("is logged out 2");
      }
      */
    });
  }

  
  sendServerPing() {
    
    /*if (this.connectionService) {
      this.connectionService.cargoSendSessionPing().subscribe(
       searchReply => {
          console.log(searchReply);
        });
    }
    */
  }


  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url) {
    return this.router.isActive(url, true);
  }

  isMenuActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuOpen(url) {
    return this.router.isActive(url, false) && this.orientation !== 'horizontal';
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}

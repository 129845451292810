import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { LayoutService } from './layout/layout.service';
import { ConnectionService } from './services/connection.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block; }']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router, 
    private appService: AppService, 
    private translate: TranslateService,
    private titleService:Title,
    private layoutService: LayoutService,
    private connectService:ConnectionService,
    ) {
    // Subscribe to router events to handle page transition
    this.router.events.subscribe(this.navigationInterceptor.bind(this));

    // Disable animations and transitions in IE10 to increase performance
    if (typeof document['documentMode'] === 'number' && document['documentMode'] < 11) {
      const style = document.createElement('style');
      style.textContent = `
        * {
          -ms-animation: none !important;
          animation: none !important;
          -ms-transition: none !important;
          transition: none !important;
        }`;
      document.head.appendChild(style);
    }
    console.log("setDefaultLanguage:" + environment.defaultLanguage);

    var lang = this.connectService.hasCookie("SaleswatchLanguage");
    if (lang)
      translate.setDefaultLang(lang.language);
    else
      translate.setDefaultLang(environment.defaultLanguage);


    

    //var title = this.translate.instant("main.application_title")
    // this.titleService.setTitle(title);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // var title = this.translate.instant("main.application_title")
      // this.titleService.setTitle(title);
      // console.log(title);
    });
  }

  ngOnInit() {
    // var title = this.translate.instant("main.application_title")
    // this.titleService.setTitle(title);
    // console.log(title);
  }

  private navigationInterceptor(e: RouterEvent) {
    if (e instanceof NavigationStart) {
      // Set loading state
      document.body.classList.add('app-loading');
    }

    if (e instanceof NavigationEnd) {
      // Scroll to top of the page
      this.appService.scrollTop(0, 0);
    }

    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      // On small screens collapse sidenav
      if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
        setTimeout(() => this.layoutService.setCollapsed(true, true), 10);
      }

      // Remove loading state
      document.body.classList.remove('app-loading');
    }
  }
}

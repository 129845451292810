import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { TranslateModule } from '@ngx-translate/core';
import { DirectLinkComponent } from './subviews/directlink/directlink.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { DispatchHomeComponent } from './dispatch-home/dispatch-home.component';

// *******************************************************************************
// Routes

const routes: Routes = [

  {
    path: 'login', 
    component: LoginComponent, 
    pathMatch: 'full', 
    children: [{ path: '', component: HomeComponent },]
  },{ 
    path: '', 
    component: LoginComponent, 
    pathMatch: 'full', 
    children: [{ path: '', component: HomeComponent },]
  },{ 
    canActivate: [AuthGuardService],
    path: 'saleswatch', 
    component: Layout1Component, 
    pathMatch: 'full', children: [{ path: '', component: HomeComponent },]
  },{ 
    canActivate: [AuthGuardService],
    path: 'dispatch', 
    component: Layout1Component, 
    pathMatch: 'full', children: [{ path: '', component: DispatchHomeComponent },]
  },{ 
    canActivate: [AuthGuardService],
    path: 'saleswatch/:id', 
    component: Layout1Component, 
    pathMatch: 'full', children: [{ path: '', component: HomeComponent },]
  },{ 
    path: 'page-2', component: Layout1Component, 
    children: [{ path: '', component: Page2Component },]
  },
  { path: 'directlink', component: LayoutBlankComponent, children: [
    { path: '', component: DirectLinkComponent },]
  }, 
  // 404 Not Found page
  { path: '**', component: NotFoundComponent }

];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

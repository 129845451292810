

export class CargoSessionData {
    
    username:string;
    suid:string;
    webViewId:string;
    url:string;
    
    constructor() {
        this.username = "";
        this.suid = "";
        this.webViewId = "";
        this.url = "";
    }
}

import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
// import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { ConnectionService } from '../../services/connection.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }']
})
export class LayoutNavbarComponent implements OnInit {
  isExpanded = false;
  isRTL: boolean;
  // public currentApplicationVersion:string = environment.appVersion;
  public majorAppVersion:string = environment.appVersion;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') hostClassMain = true;

  constructor(
    private appService: AppService, 
    public connectService:ConnectionService,
    private translateService:TranslateService,
    private router: Router,
    private layoutService: LayoutService) 
  {
    this.isRTL = appService.isRTL;
  }

  ngOnInit(): void {

    var array = environment.appVersion.split(".");
    this.majorAppVersion = environment.majorAppVersion;

    // this.currentApplicationVersion = array[0] + "." + array[1] + " build " + array[2]; 
    // var versionstr = array[0] + "." + array[1] + " build " + array[2]; 
    this.majorAppVersion = this.majorAppVersion + " build:" +  array[2];
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  changeLanguage(lang:string) {

    this.translateService.setDefaultLang(lang);
    var cookie = {language:lang};
    this.connectService.setCookie("SaleswatchLanguage", 365, cookie);
  }

  cargoLogout() {

    console.log("Call Logout");
    this.connectService.cargoLogout().subscribe(
      logoutData => {
        console.log("logout data:" + this.connectService.originalUrl)
        console.log(logoutData);
        this.router.navigate(['/']);
        // window.location.href = this.connectService.originalUrl + "/login"
      }, error => {
        console.log("error")
        this.router.navigate(['/']);
        // window.location.href = this.connectService.originalUrl + "/login"
      });
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}

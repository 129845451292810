import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionService } from './connection.service';

@Injectable()

export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router, 
        private connectService: ConnectionService
    ) {}

    /*canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        var res = this.connectService.isLoggedIn();
        if (! res)
            this.router.navigate(['/']);
        
    }
    */
   
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // console.log("Guard are we logged in?");
        /*this.connectService.isLoggedIn().subscribe(
            result => {
              if (result) {
                console.log("We are logged in");
                // this.router.navigate(['/saleswatch']);
                return of(true);
              } else {
                console.log("We are logged out");  
                this.router.navigate(['/']);
                return of(false);
              }
        });
        return of(true);
        */
        // return this.connectService.isLoggedIn2();
        console.log(route);
        return this.connectService.isLoggedIn()
            .pipe(
                map(result => {
                    // console.log("are we logged in:" + result);
                    if (! result) {
                        // console.log("go to loginpage");
                        this.router.navigate(['/login']);
                    }
                    return result;
                })
        );
    }
}
